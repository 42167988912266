import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Tooltip from "@/elements/Tooltip";

interface SpoilerType {
  title: string | React.ReactElement
  style?: React.CSSProperties
  className?: string
  withHighlight?: boolean
  titleClassName?: string
  contentClassName?: string
  lazy?: boolean
  defaultState?: boolean
  children?: any,
  required?: boolean | string,
  description?: string,
}

const Spoiler = ({
  title,
  style,
  className,
  withHighlight = false,
  titleClassName,
  contentClassName,
  lazy = false,
  defaultState = false,
  children,
  required = false,
  description = undefined
}: SpoilerType) => {
  const [isOpen, setOpen] = useState(defaultState);
  const toggle = () => setOpen(prev => !prev);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState])

  const getDescTextColor = () => required === 'red' ? 'red' : 'rgba(16, 24, 40, 0.50)';
  const getDescText = () => {
    if (description) {
      return description + (required ? '. Обязательно к заполнению' : '');
    }
    return required ? 'Обязательно к заполнению' : '';
  }

  return (
    <div
      className={cx(
        'accordion-item-felix',
        className,
        {
          'accordion-item-felix-open': isOpen,
          'accordion-item-felix-highlight': withHighlight
        }
      )}
      style={style}
    >
      <div className={cx("tab-title-felix", titleClassName)} onClick={toggle}>
        {title}
        {(required || description) && <div className={'input-title__description'}
                          style={{color: getDescTextColor(), padding: '0px'}}>
          {getDescText()}
        </div>}
      </div>
      <div className={cx('tab-data-felix', contentClassName)}>
        {!lazy || isOpen ? children : null}
      </div>
    </div>
  )
}

export default Spoiler;
