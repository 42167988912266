import cx from 'classnames';
import i18n from "i18next";
import { compact } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import ItemEdit from '@/components/Helper/ItemEdit';
import ListItem from '@/components/Helper/ListItem';
import Spoiler from '@/components/Spoiler';
import { FORMAT_DATE } from '@/config/const';
import Checkbox from '@/elements/Checkbox';
import { MAX_WORK_DURATION } from '@/pages/CreateProject/Blocks/EditBlock';
import { WorkWithChecked, checkGroup, getWorkNameWithProject, getWorkUrl } from "@/pages/CreateProject/Blocks/utils";
import { formatDate, formatDays, getDict, removeUndefinedObj } from '@/utils';

export type SpoilerResultWorksProps = {
  data: WorkWithChecked[];
  [key: string]: any;
};

const SpoilerResultWorks = ({
  data,
  projectId,
  dict,
  projectData,
  isStatusActive,
  isShowEdit,
  editButton,
  getJiraUrl,
  onToggleWork,
  isShowRemove
}: SpoilerResultWorksProps) => {

  const getDataTab = (work: Work) => checkGroup(work) !== true ? ({
    [i18n.t('workDataRequest.responsible')]: work.responsible?.displayName,
    [i18n.t('workDataRequest.statusId')]: getDict(dict.workStatus, work.statusId),
    [i18n.t('workDataRequest.results')]: work.results,
    [i18n.t('workDataRequest.dateStart')]: formatDate(work.dateStart),
    [i18n.t('workDataRequest.dateEnd')]: formatDate(work.dateEnd),
    [i18n.t('workDataRequest.dateEndFact')]: formatDate(work.dateEndFact),
    [i18n.t('workDataRequest.dateUpdateStatus')]: formatDate(work.dateUpdateStatus),
    [i18n.t('workDataRequest.duration')]: formatDays(work.duration),
    [i18n.t('workDataRequest.calendarDuration')]: formatDays(work.calendarDuration),
    [i18n.t('workDataRequest.comment')]: work.comment,
    [i18n.t('workDataRequest.weight')]: work.weight,
  }) : ({
    [i18n.t('workDataRequest.statusId')]: getDict(dict.workStatus, work.statusId),
    [i18n.t('workDataRequest.dateStart')]: formatDate(work.dateStart),
    [i18n.t('workDataRequest.dateEnd')]: formatDate(work.dateEnd),
    [i18n.t('workDataRequest.dateEndFact')]: formatDate(work.dateEndFact),
    [i18n.t('workDataRequest.duration')]: formatDays(work.duration),
    [i18n.t('workDataRequest.calendarDuration')]: formatDays(work.calendarDuration),
    [i18n.t('workDataRequest.isShowDashboard')]: work.isShowDashboard ? 'Да' : 'Нет',
  });

  if (!data.length) {
    return <div className="workList_content__empty">Нет данных</div>;
  }

  return (
    <div className="marginBottom-20 spoiler-list__container">
      {data.map(work => {
        const name = compact([
          work.rowNum + '. ' + work.name,
          getDict(dict.workStatus, work.statusId),
          !work.dateStart && work.dateEnd
            ? `${formatDate(work.dateStart, FORMAT_DATE)} - ${formatDate(work.dateEnd, FORMAT_DATE)}`
            : null
        ]).join(' / ');
        const dataTab = removeUndefinedObj(getDataTab(work));

        return (
          <Spoiler key={work.id} withHighlight title={(
            <span className={cx('flex-center-vertical', {
              'work-group__item': checkGroup(work),
            })}>
              {isShowRemove && !work.isExtreme && (
                <Checkbox
                  checked={work.isChecked || false}
                  onChange={() => onToggleWork(work.id)}
                />
              )}
              {isShowRemove && work.isExtreme && <div style={{width: '25px'}} />}
              {name}
            </span>
          )}>
            <ListItem data={dataTab}>
              <React.Fragment key={i18n.t('workDataRequest.duration') as string}>
                <div className={cx('duration', { 'warn': work.duration >= MAX_WORK_DURATION })}>
                  {dataTab[i18n.t('workDataRequest.duration')]}
                </div>
              </React.Fragment>
            </ListItem>
            {(isStatusActive() || projectData.edit) && (
              <ItemEdit
                item={work}
                editButton={editButton}
                isHideDelete={true}
                editTitle="Открыть"
                isHideEdit={!isShowEdit()}>
                {work.jiraId && (
                  <div className="edit-button-felix">
                    <a target="blank" href={getJiraUrl(work)}>
                      Перейти в Jira
                    </a>
                  </div>
                )}
              </ItemEdit>
            )}
          </Spoiler>
        );
      })}
    </div>
  );
};

export default SpoilerResultWorks;
