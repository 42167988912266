import React from 'react';
import { BarSeries } from '@devexpress/dx-react-chart-bootstrap4';

const BarSeriesPoint = (props) => {
  return React.createElement(BarSeries.Point, {
    ...props,
    maxBarWidth: 20,
  })
}

export default BarSeriesPoint;
