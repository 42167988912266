import i18n from "i18next";
import { compact, isObject, uniq } from 'lodash';
import qs from 'query-string';

import { isEmptyValues } from "@/utils";

import { store } from '@/index';
import { decLoad, incLoad, modalHide, modalShow } from './modal';

const getFieldName = (field) => {
  const normalizeName = field.replace(/\[\d+\]/g, '');

  let result = i18n.t(normalizeName, {
    returnObjects: true
  });

  if (result === normalizeName) {
    result = i18n.t(normalizeName.replace('Ext.', '.'), {
      returnObjects: true
    });
  }

  if (isObject(result)) {
    return (result as any).__name;
  }

  return result;
};

const getNumberLine = (field) => {
  const info = field.match(/\[(\d+)\]/);

  if (!info) {
    return '';
  }

  return `в строке ${+info[1] + 1}`;
};

const getDefaultMessage = (message) => {
  let newMessage = message.replace(/(не должно равняться null)|(не должно быть пустым)|(must not be null)|(must not be empty)/,
    'должно быть заполнено');

  return newMessage.replace(/длина должна составлять от (\d+) до (\d+)/, 'должно содержать от $1 до $2 символов');
};

const getSectionName = (section) => {
  const key = `${section}.__sectionName`;
  const name = i18n.t(key);

  if (name === key) {
    return;
  }

  return `в разделе "${name}"`;
};

const getMessage400 = (body) => {
  try {
    return uniq(body.errors.map(err => {
      const key = `${err.objectName}.${err.field}`;
      const keySection = key.split('.').slice(0, -1).join('.');

      const result = compact([
        'Поле',
        `"${getFieldName(key) || key}"`,
        getSectionName(keySection.substring(0, keySection.indexOf('['))),
        getNumberLine(key),
        getDefaultMessage(err.defaultMessage),
      ]);

      return result.join(' ').trim();
    })).join('\n');
  } catch (e) {
    return body.message;
  }
};

export const incLoadCount = () => {
  store.dispatch(incLoad());
};

export const decLoadCount = () => {
  store.dispatch(decLoad());
};

export const getMessage = (body) => {
  const message = body?.message;

  if (isEmptyValues(message)) {
    return undefined;
  }

  return message.replaceAll(/\${([^}]+)}/g, (item, key) => {
    return getDefaultMessage(getFieldName(key));
  });
};

export const checkError = (error, body, settings: any = {}) => {
  if (!error) {
    return;
  }

  if (error.status) {
    const { status } = error;
    const isAuthPage = window.location.pathname === '/auth';
    const modalOptions = { className: isAuthPage ? 'authErrorModal' : '', ...(settings.modalOptions || {}) };
    const modalMessage = status === 400 ? getMessage400(body) : getMessage(body);

    switch (status) {
      case 403:
        if (isAuthPage) {
          store.dispatch(modalShow(modalMessage || "Неверный логин или пароль", modalOptions));
          return;
        }

        // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Location'... Remove this comment to see the full error message
        window.location = `/auth?${qs.stringify({
          back: window.location.pathname
        })}`;

        localStorage.removeItem('isAuthenticated');
        break;
      case 404:
        // @ts-ignore ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Location'... Remove this comment to see the full error message
        window.location = `/`;
        break;
      case 400:
        store.dispatch(modalShow(modalMessage, modalOptions));
        break;
      case 502:
        store.dispatch(modalShow("Сервер недоступен, подождите, и обновите страницу"));
        break;
      default:
        if (modalMessage) {
          store.dispatch(modalShow(modalMessage, modalOptions));
        } else {
          store.dispatch(modalHide());
        }
        break;
    }
  }
};
