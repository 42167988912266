import { getDict, formatDate } from '@/utils'

import i18n from "i18next";

const AgreementKpiBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.kpi) {
        return []
      }

      return data.kpi.map(item => ({
        title: item.name,
        data: [
          {
            title: i18n.t('projectKpiWeb.kpiBase'),
            data: item.kpiBase
          },
          {
            title: i18n.t('projectKpiWeb.plan'),
            data: item.plan
          },
          {
            title: i18n.t('projectKpiWeb.typeId'),
            data: getDict(self.props.dict.kpiType, item.typeId)
          },
          {
            title: i18n.t('projectKpiWeb.statusId'),
            data: getDict(self.props.dict.kpiStatus, item.statusId)
          },
          {
            title: i18n.t('projectKpiWeb.methodCalc'),
            data: item.methodCalc
          },
          {
            title: i18n.t('projectKpiWeb.unitType'),
            data: item.unitType
          },
          {
            title: i18n.t('projectKpiWeb.fact'),
            data: item.fact
          },
          {
            title: i18n.t('projectKpiWeb.result'),
            data: item.result
          },
          {
            title: i18n.t('projectKpiWeb.resultComment'),
            data: item.resultComment
          },
          {
            title: i18n.t('projectKpiWeb.dateResult'),
            data: formatDate(item.dateResult)
          },
          {
            title: i18n.t('projectKpiWeb.dateEndFact'),
            data: formatDate(item.dateEndFact)
          },
          {
            title: i18n.t('projectKpiWeb.weight'),
            data: item.weight
          },
          // {
          //   title: 'Связанный блок работ',
          //   data: !data.work || !item.parentWorkId ? null : data.work.find(work => work.id == item.parentWorkId).name
          // },
        ]
      }))
    }
  },
])

export default AgreementKpiBlock;
