import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import service from "@/services";
import { useAppDispatch, useAppSelector } from "@/utils/typedHooks";
import { addUserValue } from "@/actions/addUserValue";
import MilestoneGantt from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGantt";
import FilterMilestone from "@/pages/CreateProject/Blocks/Milestone/FilterMilestone";

const getMilestoneList = (projectVersionId, filter) =>
  service.post(`/milestone/project/${projectVersionId}`, filter);
const getMilestoneXls = (projectVersionId, filter) =>
  service.postDownload(`/milestone/project/xls/${projectVersionId}`, filter);

export const milestoneDefaultFilter = {
  name: null,
  responsible: null,
  datePlan: { from: null, to: null },
  dateFact: { from: null, to: null },
  datePrediction: { from: null, to: null }
}

const Milestone = ({ projectVersionId, filterState, setMilestoneList }
                 : { projectVersionId: number, filterState: any, setMilestoneList?: any }) => {
  const [data, setData] = useState([]);
  const projectData = useAppSelector(state => state.NewProject.newProjectData);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = () => {
    if (projectVersionId) {
      getMilestoneList(projectVersionId, filterState)
        .then(data => {
          setData(data);
          setMilestoneList && setMilestoneList(data);
        })
        .then(() => setIsLoading(false));
    }
  }

  useEffect(() => {
    loadData();
  }, [projectVersionId, filterState]);

  return <Fragment>
    <div className="row work-list">
      <Fragment>
        <MilestoneGantt projectData={projectData}
                        milestone={data}
                        isLoading={false}
                        initCalendarType={undefined}
                        readonly
                        projectVersionId={projectVersionId}
                        loadData={loadData}
        />
      </Fragment>
    </div>
  </Fragment>
}

export default Milestone;