import cx from 'classnames';
import React from "react";

import Tooltip from "@/elements/Tooltip";

const ButtonWithLoader = ({
  className,
  disabled = false,
  onClick,
  label,
  tooltip = undefined,
  children = undefined,
  style = undefined
}) => {
  return (
    <React.Fragment>
      <button
        className={cx(className, "rt-felix-button__container margin-left-multiple", { disabled })}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        <span className="rt-felix-button__title">{label}</span>
        <span className="rt-felix-button loading">
          <span className="rt-felix__background-img"></span>
        </span>
        <span className="rt-felix-button finished">
          <span className="rt-felix__background-img"></span>
        </span>
        <span className="rt-felix-button error">
          <span className="rt-felix__background-img"></span>
        </span>
        <Tooltip text={tooltip} placement="top" />
        {children}
      </button>
    </React.Fragment>
  );
};

export default ButtonWithLoader;