import i18n from "i18next";
import { cloneDeep, set } from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GanttWithLoad from "@/pages/CreateProject/Blocks/GanttWithLoad";
import ButtonBack from '@/components/Helper/ButtonBack';
import ButtonRt from '@/components/Helper/ButtonRt';
import SaveCancel from '@/components/Helper/SaveCancel';
import ProjectHeader from '@/components/ProjectHeader';
import { FORMAT_DATE_HUMAN, ProjectStatus } from '@/config/const';
import service from '@/services';
import { getDictCodeById, isNotEmptyValues } from '@/utils';
import { useCheckRight, useUpdateProjectData } from '@/utils/hooks';
import StatusView from './EditView';
import ProjectEndProcent from './ProjectEndProcent';
import { checkMilestone } from "@/pages/CreateProject/Blocks/utils";
import { useAppSelector } from "@/utils/typedHooks";
import ReportStatusBti from "@/pages/CreateProject/Status/ReportStatusBti";

const publishStatus = (statusId) => service.get(`/status-report/publish/${statusId}`);
const returnStatus = (statusId) => service.get(`/status-report/return/${statusId}`);
export const getStatus = (statusId, isShowLoad = false) => service.get(`/status-report/${statusId}`, null, { isShowLoad });
const saveStatus = (statusId, data) => service.post(`/status-report/save/${statusId}`, data);
export const reportWorkLoader = (id, isShowLoad = false) => service.get(`/work/status-report/${id}`, null, { isShowLoad });
const sendToAgree = (statusId) => service.put(`/status-report/send-to-agree/${statusId}`);

const StatusEdit = ({ projectVersionId }) => {
  const projectData = useAppSelector(state => state.NewProject.newProjectData);
  const projectStatusDict = useAppSelector(state => state.dict.status);
  const dictSegment = useAppSelector(state => state.dict.segment);
  const dictReportAgreeStatus = useAppSelector(state => state.dict.reportAgreeStatus);
  const isProjectRelease = getDictCodeById(projectStatusDict, projectData.statusId) === ProjectStatus.RELEASE;
  const docRef = React.useRef<any>();
  const updateProjectData = useUpdateProjectData();

  const history = useNavigate();
  const { statusId } = useParams();

  const checkRight = useCheckRight();
  const [works, setWorks] = useState([]);
  const [status, setStatus] = useState({
    escalation: false,
    timings: [],
    resources: [],
    risks: [],
    published: false,
    dateCreate: undefined,
    completePercent: undefined,
    id: undefined,
    projectVersionId: undefined,
    riskComments: [],
    agreeStatusId: undefined
  });
  const [accentRequiredOnPublish, setAccentRequiredOnPublish] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const pullStatus = statusId => getStatus(statusId).then(setStatus);

  const isBti = projectData?.isBti;

  const agreeStatusCode = getDictCodeById(dictReportAgreeStatus, status.agreeStatusId);

  useEffect(() => {
    pullStatus(statusId);
  }, [statusId]);

  useEffect(() => {
    if (status.id) {
      reportWorkLoader(status.id).then(data => setWorks(data.filter(w => w.dateStart && (checkMilestone(w) ||  w.dateEnd))));
    }
  }, [status.id]);

  const onChangeInput = (key, value, updateDirty = true) => {
    if (updateDirty) {
      setIsDirty(true);
    }
    setStatus(set(cloneDeep(status), key, value));
  };

  const onChangeRiskComment = (riskComment) => {
    setIsDirty(true);
    setStatus({...status, riskComments: riskComment.filter(comment => !!comment.comment)})
  }
  const goBack = () => {
    history(`/${i18n.t('base')}/${projectVersionId}/status`, {state: {disableBlock: true}});
  };

  const onPublishStatus = async () => {
    try {
      await saveWithDocs(statusId, status);
      await publishStatus(statusId);
      await pullStatus(statusId);
      goBack();
    } catch (e) {
      setAccentRequiredOnPublish(true);
      await pullStatus(statusId);
    }
  };

  const onSendToAgreeStatus = async () => {
    try {
      await saveWithDocs(statusId, status);
      await sendToAgree(statusId);
      await pullStatus(statusId);
      goBack();
    } catch (e) {
      setAccentRequiredOnPublish(true);
      await pullStatus(statusId);
    }
  };

  const onReturnStatus = async () => {
    try {
      await returnStatus(statusId);
      goBack();
    } catch (e) {
      await pullStatus(statusId);
    }
  };

  const save = async () => {
    return saveWithDocs(statusId, status).then(goBack).catch(e => setAccentRequiredOnPublish(true));
  };

  const saveWithDocs = (statusId, status) => {
    return saveStatus(statusId, status)
      .then(() => docRef.current.sendFiles(projectVersionId, statusId))
      .then(() => updateProjectData(projectVersionId));
  };

  const cancel = () => {
    goBack();
  };

  const isReadonly = () => {
    const parsed = qs.parse(window.location.search);

    return parsed.readonly === "true" || !isProjectRelease || agreeStatusCode === 'COORDINATION';
  };

  const isShowPublish = () => {
    return !isReadonly() && !status.published && checkRight("REPORT_UPDATE");
  };

  const isShowSave = () => {
    return !isReadonly()
      && ((!status.published && checkRight("REPORT_UPDATE"))
        || checkRight('STATUS_REPORT_PROJECT_OFFICE_COMMENT_EDIT'));
  };

  const publishButton = () => {
    if (isBti) {
      return (agreeStatusCode === 'DRAFT' && <ButtonRt onClick={onSendToAgreeStatus}>Опубликовать</ButtonRt>)
    } else {
      return <ButtonRt onClick={onPublishStatus}>Опубликовать</ButtonRt>
    }
  }

  return (
    <React.Fragment>
      <ButtonBack to={`/${i18n.t('base')}/${projectVersionId}/status`} />
      <div className="report-status__container">
        <div className="wrapper-option report__container">
          <ProjectHeader title={`Статус-отчет ${moment(status.dateCreate).format(FORMAT_DATE_HUMAN)}`} />
          {isBti && <ReportStatusBti pullStatus={pullStatus}
                                     statusReport={status}
                                     sendToAgree={onSendToAgreeStatus}
                                     goBack={goBack}
                                     isEdit/>}

          {isShowPublish() && publishButton()}

          <StatusView
            accentRequired={accentRequiredOnPublish}
            status={status}
            isReadonly={isReadonly}
            onChangeInput={onChangeInput}
            onChangeRiskComment={onChangeRiskComment}
            docRef={docRef}
          />

          <SaveCancel
            className="report-buttons__container"
            save={isShowSave()
              ? save
              : null}
            cancel={cancel}
            saveFromModal={isShowSave()
              ? async () => {
                await save();
              }
              : null}
            childrenMiddle={
              <>
                {isShowPublish() && publishButton()}
              </>
            }
            setIsDirty={(isDirty) => setIsDirty(isDirty)}
            isDirty={isDirty}
          >
            {status.published
              && isProjectRelease
              && checkRight("REPORT_RETURN")
              && (<ButtonRt onClick={onReturnStatus}>Вернуть на доработку</ButtonRt>)}
          </SaveCancel>
        </div>

        <ProjectEndProcent value={status.completePercent} />
      </div>

      {status.projectVersionId && isNotEmptyValues(works) && (
        <GanttWithLoad works={works} projectData={projectData} readonly isHideLink />
      )}
    </React.Fragment>
  );
};

export default StatusEdit;
