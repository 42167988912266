import { useState } from 'react';
import { isArray } from 'lodash'
import TreeSelect from 'rc-tree-select'
import InputTitle from '@/components/InputTitle'
import cx from 'classnames';
import React from 'react';
import './styleTree.scss';

const InputIcon = ({ className }) => {
  return (
    <div className={className}>
      <span className="select-rt__indicator-separator"></span>
      <div aria-hidden="true" className="select-rt__indicator select-rt__dropdown-indicator">
    		<svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
    			<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    		</svg>
    	</div>
    </div>
  )

}
const ClearIcon = () => (
  <div aria-hidden="true" className="select-rt__indicator select-rt__clear-indicator">
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  </div>
)

const RemoveIcon = () => (
  <div className="select-rt__multi-value__remove">
    <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  </div>
)

const getValueNormalize = (value) => {
  if (isArray(value)) {
    return value.map(v => v === null ? undefined : v);
  }

  return value === null ? undefined : value;
}

const InputTree = ({
  isHide = false,
  options,
  label,
  value,
  onChange,
  isClearable = false,
  disabled = false,
  isMulti = false,
  noSelectParent = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (isHide) {
    return null;
  }

  const valueNormalize = getValueNormalize(value);

  const onFocus = () => {
    setIsOpen(true);
  }

  const onBlur = () => {
    setIsOpen(false);
  }

  const onChangeInput = () => {
    setIsOpen(false);
  }

  const onClick = (e, ref) => {
    ref.focus();

    if (!isOpen) {
      setIsOpen(true)
    }
  }

  const onSearch = (e) => {
    if (!isOpen) {
      setIsOpen(true);
    }
  }

  const {tooltip, ...treeSelectProps} = props;
  return (
    <InputTitle
      required={props.required}
      tooltip={props.tooltip}
      isClearable={isClearable && !disabled}
      label={label} 
      value={value}
      info={<InputIcon className="rt-tree-select select-tree-fix"/>}
      onFocus={onFocus} 
      onBlur={onBlur} 
      onChange={onChangeInput} 
      onClick={onClick}
      className="input-select-tree"
      description={props.description}
    >
      <TreeSelect
        treeData={options}
        value={valueNormalize}
        onChange={onChange}
        allowClear={isClearable}
        onSearch={onSearch}
        showSearch
        treeNodeFilterProp="label"
        className="rt-tree-select rc-tree-select-customize-input"
        dropdownClassName={cx("rt-tree-select", { noSelectParent })}
        notFoundContent="Нет данных"
        inputIcon={InputIcon}
        clearIcon={ClearIcon}
        removeIcon={RemoveIcon}
        switcherIcon={InputIcon}
        virtual={false}
        treeIcon
        disabled={disabled}
        multiple={isMulti}
        open={isOpen}
        {...treeSelectProps}
      />
    </InputTitle>
  );
}

export default InputTree;
