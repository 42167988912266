import React, { CSSProperties } from "react";
import cx from 'classnames';

const Message = ({
  text,
  className
}: {
  text: string
  className?: string
}) => (
  <div className={cx(className, "text-muted")}>
    {text}
  </div>
)

export default Message;
