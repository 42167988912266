import React from 'react';
import cx from 'classnames'

import { getDictObj } from '@/utils';
import { useAppSelector } from '@/utils/typedHooks';
import { DictDataItem } from '@/reducers/Dictionaries';
import Tooltip from "@/elements/Tooltip";

export const Indicator = ({
  text,
  withText = false,
  className = undefined,
  textPosition = "left",
  color = undefined,
}: {
  text?: string;
  withText?: boolean;
  className?: string;
  textPosition?: 'left' | 'right';
  color?: string;
}) => {
  const Text = <div className="color-indicator-text">{text}</div>

  return (
    <React.Fragment>
      {withText && textPosition === 'left' && Text}
      <div className={cx('color-indicator', className)} style={{ backgroundColor: color }}></div>
      {withText && textPosition === 'right' && Text}
    </React.Fragment>
  )
}

interface IStatusIndicatorProps {
  value: number;
  isWithText?: boolean;
  textGenerator?: (item: DictDataItem) => string;
}

const StatusIndicator = ({
  value,
  isWithText = false,
  textGenerator = status => status.name,
} : IStatusIndicatorProps) => {
  const dictReportStatus = useAppSelector(state => state.dict.reportStatus);
  const status = getDictObj(dictReportStatus, value);

  if (!status) {
    return null;
  }

  return (
    <Indicator
      withText={isWithText}
      className={cx("status-report", status.code)}
      text={textGenerator(status)}
    />
  )
}

export default StatusIndicator;
