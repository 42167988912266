import React from 'react';
import cx from 'classnames';

interface ILoaderProps {
  isMini?: boolean;
}

const Loader = ({ isMini }: ILoaderProps) => {
  return (
    <div className={cx({ content: !isMini })}>
      <div className="loadingio-spinner-spinner-utmq1230pj">
        <div className="ldio-duexmvz2gq4">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Loader;
