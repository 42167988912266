import React from 'react';
import { prevent } from "@/utils";
import cx from "classnames";

const SelectLink = ({link, label, disabled, width}) => {

  return <div onClick={prevent(() => window.open(link))}
              style={{width: width ? `${width - (width/4)}px` : '430px'}}
              className={cx('input-select-link', {'disabled': disabled})}>
    {label}
  </div>
}

export default SelectLink
