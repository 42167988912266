import moment from "moment";

import { FORMAT_DATE_SERVER } from "@/config/const";
import { getDictCodeById } from "@/utils";
import { useAppSelector } from "@/utils/typedHooks";
import { RootState } from '@/index';

export type CheckHolidayType = (item: moment.Moment) => boolean;

export const checkIsHoliday = (holidayDict: any[]): CheckHolidayType => {
  const holidays = holidayDict.filter(item => item.isHoliday).map(item => item.date);
  const holidaysExclude = holidayDict.filter(item => !item.isHoliday).map(item => item.date);

  return (day: moment.Moment) => {
    const dayStr = day.format(FORMAT_DATE_SERVER);
    if (holidaysExclude.includes(dayStr)) {
      return false;
    }

    if ([0, 6].includes(day.day())) {
      return true;
    }

    return holidays.includes(dayStr);
  };
};

export const getDateEndWithWeekend = (holidayDict: any[], isRevert: boolean = false) => {
  const checkHoliday = checkIsHoliday(holidayDict);

  return (start: moment.Moment, duration: number, withHolidayLock: boolean = true) => {
    const startClone = start.clone();
    const isFirstWeekend = checkHoliday(startClone);
    let holidayLock = withHolidayLock && isFirstWeekend;

    while (duration > (isFirstWeekend ? 0 : 1)) {
      startClone.add(isRevert ? -1 : 1, 'day');

      const isHoliday = checkHoliday(startClone);

      if (!isHoliday || holidayLock) {
        duration--;
      }

      if (!isHoliday) {
        holidayLock = false;
      }
    }

    return startClone;
  };
};

export const getWorkDaysAfterWithWeekend = (holidayDict: any[]) => {
  const checkHoliday = checkIsHoliday(holidayDict);

  return (start: moment.Moment, days: number) => {
    const startClone = start.clone();

    while (days > 0) {
      startClone.add(1, 'day');

      if (!checkHoliday(startClone)) {
        days--;
      }
    }

    return startClone;
  };
};

export const getDurationWithWeekend = (holidayDict: any[]) => {
  const checkHoliday = checkIsHoliday(holidayDict);

  return (start: moment.Moment, end: moment.Moment) => {
    if (!start || !end) {
      return 0;
    }

    const isRevert = start.isAfter(end);
    const currentStart = isRevert ? end.clone() : start.clone();
    const currentEnd = isRevert ? start.clone() : end.clone();

    let duration = 0;
    while (currentStart.isSameOrBefore(currentEnd)) {
      const isHoliday = checkHoliday(currentStart);

      if (!isHoliday) {
        duration++;
      }

      currentStart.add(1, 'day');
    }

    return duration * (isRevert ? -1 : 1);
  };
};

export const getBetweenWithWeekend = (holidayDict: any[]) => {
  const checkHoliday = checkIsHoliday(holidayDict);

  return (start: moment.Moment, end: moment.Moment) => {
    if (!start || !end) {
      return 0;
    }

    const isRevert = start.isAfter(end);
    const currentStart = isRevert ? end.clone() : start.clone();
    const currentEnd = isRevert ? start.clone() : end.clone();

    let duration = 0;
    while (currentStart.isBefore(currentEnd)) {
      currentStart.add(1, 'day');

      const isHoliday = checkHoliday(currentStart);

      if (!isHoliday) {
        duration++;
      }
    }

    return duration * (isRevert ? -1 : 1);
  };
};

export const padWeekend = (holidayDict: any[]) => {
  const checkHoliday = checkIsHoliday(holidayDict);

  return (date: moment.Moment) => {
    const startDate = date.clone();

    while (checkHoliday(startDate)) {
      startDate.add(1, 'day');
    }

    return startDate;
  };
};

const linkTypeCodeById = (workLinkTypeDict) => {
  return (id: number) => getDictCodeById(workLinkTypeDict, id) as LinkTypes;
};

export type WeekendUtilType = {
  pad: (date: moment.Moment) => moment.Moment,
  getDuration: (start: moment.Moment, end: moment.Moment) => number,
  getDateStart: (end: moment.Moment, duration: number, withHolidayLock?: boolean) => moment.Moment;
  getDateEnd: (start: moment.Moment, duration: number, withHolidayLock?: boolean) => moment.Moment;
  getBetween: (start: moment.Moment, end: moment.Moment) => number;
  getWorkDaysAfter: (start: moment.Moment, days: number) => moment.Moment;
  isHoliday: (day: moment.Moment) => boolean;
  getLinkTypeCodeById: (id: number) => LinkTypes;
};

export const createWeekendUtil = (state: RootState): WeekendUtilType => {
  const holidayDictData = state.dict.holidays.data;
  const workLinkTypeDict = state.dict.workLinkType;

  const pad = padWeekend(holidayDictData);
  const getDuration = getDurationWithWeekend(holidayDictData);
  const getDateStart = getDateEndWithWeekend(holidayDictData, true);
  const getDateEnd = getDateEndWithWeekend(holidayDictData);
  const getBetween = getBetweenWithWeekend(holidayDictData);
  const getWorkDaysAfter = getWorkDaysAfterWithWeekend(holidayDictData);
  const isHoliday = checkIsHoliday(holidayDictData);
  const getLinkTypeCodeById = linkTypeCodeById(workLinkTypeDict);

  return {
    pad,
    getDuration,
    getDateStart,
    getDateEnd,
    getBetween,
    getWorkDaysAfter,
    isHoliday,
    getLinkTypeCodeById,
  };
}

export const useWeekendUtil = (): WeekendUtilType => {
  return useAppSelector(createWeekendUtil);
};