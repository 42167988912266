import { DictItem } from '@/reducers/Dictionaries';
import { getDictObj } from '@/utils/index';

export const isUserActive = (userStatusDict: DictItem, user) => {
  const status = getDictObj(userStatusDict, user.statusId);

  if (!status) {
    return null;
  }

  return status.code === 'USER'
    && user.rolesId.length > 0
}