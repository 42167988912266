import { ProjectSection, ProjectStatus, SHOW_PROJECT_ALL_SECTIONS, UserRight } from '@/config/const';
import i18n from 'i18next';
import Icon from '@/pages/CreateProject/BasicNav/Icon';
import { getDictObj, importExt, isEmptyValues, isNotEmptyValues } from '@/utils';
import { intersection, isEmpty } from 'lodash';

export const prepareNavItems = async (projectData, dict, checkRight) => {
  const extModule = await importExt('pages/CreateProject/BasicNav/navItemsExt');

  const [
    projectInfo,
    dictStatus,
    dictStage,
    projectUserRightCodes,
  ] = [
    projectData,
    dict.status,
    dict.stage,
    projectData?.userRightCodes || [],
  ];

  const {
    id,
    statusId,
    currentAgreeId,
    version,
    stageId,
    isClosed,
    agreeProcessTypeCode
  } = projectInfo;
  const statusCode = getDictObj(dictStatus, statusId)?.code;
  const projectStageCode = getDictObj(dictStage, stageId)?.code;

  const showFullProject = !isEmpty(intersection(SHOW_PROJECT_ALL_SECTIONS, projectUserRightCodes));

  const isAgreeCommittee = agreeProcessTypeCode === 'COMMITTEE';

  const items: NavItemType[] = [
    {
      key: 'agree',
      isShow: !!currentAgreeId
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE)),
      title: "Согласование",
      url: `/${i18n.t('base')}/${id}/agreement/${currentAgreeId}`,
      icon: Icon.ProjectAgreement,
    },
    {
      key: 'common',
      title: "Общее",
      url: `/${i18n.t('base')}/${id}`,
      icon: Icon.ProjectCommon,
      checker: '^/(project|program|portfolio)/\\d+/?$',
      section: ProjectSection.COMMON,
    },
    {
      key: 'businessCase',
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_BUSINESS_CASE),
      title: i18n.t('menu.goal'),
      url: `/${i18n.t('base')}/${id}/businessCase`,
      icon: Icon.ProjectGoal,
      section: ProjectSection.BUSINESS_CASE,
    },
    {
      key: 'team',
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_EMPLOYEES),
      title: "Команда",
      url: `/${i18n.t('base')}/${id}/team`,
      icon: Icon.ProjectTeam,
      section: ProjectSection.EMPLOYEES,
    },
    {
      key: 'risk',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_RISKS)),
      title: "Риски",
      url: `/${i18n.t('base')}/${id}/risk`,
      icon: Icon.ProjectRisk,
      section: ProjectSection.RISKS,
    },
    {
      key: 'budget',
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_BUDGET),
      title: "Бюджет",
      url: `/${i18n.t('base')}/${id}/budget`,
      icon: Icon.ProjectBudget,
      section: ProjectSection.BUDGET,
    },
    {
      key: 'children',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_PROJECTS)),
      title: "Компоненты Портфеля/Программы",
      url: `/${i18n.t('base')}/${id}/project`,
      icon: Icon.ProjectChildren,
    },
    {
      key: 'work',
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_WORKS),
      title: "График работ",
      url: `/${i18n.t('base')}/${id}/work`,
      icon: Icon.ProjectWork,
      section: ProjectSection.WORKS,
    },
    {
      key: 'kpi',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_KPI)),
      title: i18n.t('menu.kpi'),
      url: `/${i18n.t('base')}/${id}/kpi`,
      icon: Icon.ProjectKpi,
      section: ProjectSection.KPI,
    },
    {
      key: 'mbo',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_MBO)),
      title: "МВО",
      url: `/${i18n.t('base')}/${id}/mbo`,
      icon: Icon.ProjectMbo,
      section: ProjectSection.MBO,
    },
    {
      key: 'lesson',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_LESSONS)),
      title: "Извлеченные уроки",
      url: `/${i18n.t('base')}/${id}/lesson`,
      icon: Icon.ProjectLesson,
      section: ProjectSection.LESSONS,
    },
    {
      key: 'assignment',
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_ASSIGNMENT)),
      title: "Поручения",
      url: `/${i18n.t('base')}/${id}/assignment`,
      icon: Icon.CommitteeIcon,
    },
    {
      key: 'documents',
      isShow: (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_DOCUMENTS)),
      title: "Документы",
      url: `/${i18n.t('base')}/${id}/documents`,
      icon: Icon.ProjectDocument,
    },
    {
      key: 'version',
      isShow: (version > 1 || [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode))
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_VERSIONS)),
      title: "Версии",
      url: `/${i18n.t('base')}/${id}/version`,
      icon: Icon.ProjectVersion,
    },
    {
      key: 'status',
      isShow: [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE, ProjectStatus.COORDINATION].map(ps => ps.toString()).includes(statusCode)
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_STATUS_REPORT)),
      title: "Статус-отчет",
      url: `/${i18n.t('base')}/${id}/status`,
      icon: Icon.ProjectStatus,
    },
    {
      key: 'initReport',
      isShow: !isClosed
        && !['CLOSE', 'ASSESSMENT_BENEFITS'].includes(projectStageCode)
        && statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_PROJECT_REPORT)),
      title: i18n.t('menu.initReport'),
      url: `/${i18n.t('base')}/${id}/init-report`,
      target: '_blank',
      icon: Icon.CloseReport,
    },
    {
      key: 'closeReport',
      isShow: [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode)
        && ['CLOSE', 'ASSESSMENT_BENEFITS'].includes(projectStageCode)
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_CLOSE_REPORT)),
      title: "Отчет о закрытии",
      url: `/${i18n.t('base')}/${id}/close-report`,
      target: '_blank',
      icon: Icon.CloseReport,
    },
    {
      key: 'committee',
      isShow: isAgreeCommittee
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_COMMITTE)),
      title: "Данные на ПК",
      url: `/${i18n.t('base')}/${id}/toCommittee?object=PROJECT`,
      icon: Icon.ProjectAgreement,
    },
    {
      key: 'meeting',
      isShow: (isNotEmptyValues(projectInfo.meetingPlanId)
          || isNotEmptyValues(projectInfo.meetingUnplannedId))
        && projectInfo.isKeyProject
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_UK_MEETING)),
      title: "Согласование проведения УК",
      url: `/${i18n.t('base')}/${id}/meeting/${projectInfo.meetingPlanId || projectInfo.meetingUnplannedId}`,
      icon: Icon.ProjectAgreement,
    },
    {
      key: 'meetingUnscheduled',
      isShow: isEmptyValues(projectInfo.meetingUnplannedId)
        && projectInfo.isKeyProject
        && checkRight('MEMBER_MEETING_UK', 'ROLE_PROJECT_OFFICE_PRESIDENT')
        && showFullProject,
      title: "Инициировать внеплановое заседание УК",
      url: `/${i18n.t('base')}/${id}/meeting/unscheduled`,
      icon: Icon.ProjectAgreement,
    },
    {
      key: 'meetingReport',
      isShow: projectInfo.isKeyProject
        && (version > 1 || [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode))
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_STATUS_REPORT_UK)),
      title: "Статус-отчет УК",
      url: `/${i18n.t('base')}/${id}/meeting/report`,
      icon: Icon.ProjectStatus,
    },
  ];

  return extModule?.updateNavItems?.(items, projectData, dict, checkRight) || items;
}