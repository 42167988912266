import moment from 'moment';
import { useAppSelector } from '@/utils/typedHooks';
import { RootState } from '@/index';
import { floor } from 'lodash';

const MIN_IN_HOURS = 60;

export const UTC_TIME_ZONE = 'UTC';
export const MOSCOW_TIME_ZONE = 'Europe/Moscow';
export const SERVER_TIME_ZONE = process.env.REACT_APP_SERVER_TIME_ZONE || UTC_TIME_ZONE;

export const FORMAT_DATE_TIME_IN = "DD.MM.YYYY в HH:mm";
export const FORMAT_DATE_TIME_HUMAN = 'D MMMM YYYY в HH:mm';
export const FORMAT_DATE_TIME_SECOND = "DD.MM.YYYY HH:mm:ss";
export const FORMAT_DATE_TIME_SECOND_IN = "DD.MM.YYYY в HH:mm:ss";
export const FORMAT_DATE_TIME_FIRST = 'HH:mm DD.MM.YYYY';
export const FORMAT_DATE = 'DD.MM.YYYY';

export interface ClockManager {
  convertServerDateToUser: (date: any) => moment.Moment;
  formatDateToCalendar: (date: any) => string;
  formatDateTime: (date: any, format?: string) => string;
}

export const createClockManagerInstance = (state: RootState): ClockManager => {
  const isUseLocalTimeZone = state.Login.currentUser.isUseLocalTimeZone;

  const convertServerDateToUser = convertServerDateToUserLocal(isUseLocalTimeZone);
  const formatDateToCalendar = formatDateToCalendarLocal(isUseLocalTimeZone);
  const formatDateTime = formatDateTimeLocal(isUseLocalTimeZone);

  return {
    convertServerDateToUser,
    formatDateToCalendar,
    formatDateTime,
  }
}

export const useClockManager = (): ClockManager => {
  return useAppSelector(createClockManagerInstance);
}

const getOffsetWithMoscow = () => {
  const moscowOffset = moment.tz(MOSCOW_TIME_ZONE).utcOffset();
  const localOffset = moment().utcOffset();
  return (localOffset - moscowOffset) / MIN_IN_HOURS;
}

const formattedOffsetWithMoscow = (isUseLocalTimeZone: boolean) => {
  if (!isUseLocalTimeZone) {
    return '(МСК)';
  }

  const offset = getOffsetWithMoscow();
  const hr = floor(offset);
  const mm = offset % 1 * MIN_IN_HOURS;
  return !offset ? '(МСК)' : `(МСК${hr > 0 ? '+' : ''}${hr}${mm ? `:${mm}` : ''})`;
}

const convertServerDateToUserLocal = (isUseLocalTimeZone: boolean) => (date: any) => {
  if (!date) {
    return null;
  }

  const serverDate = moment.tz(date, SERVER_TIME_ZONE);
  return serverDate.tz(isUseLocalTimeZone ? moment.tz.guess() : MOSCOW_TIME_ZONE);
}

const formatDateTimeLocal = (isUseLocalTimeZone: boolean) => (date: any, format: string = FORMAT_DATE_TIME_SECOND_IN) => {
  if (!date) {
    return '';
  }

  const convertedDate = convertServerDateToUserLocal(isUseLocalTimeZone)(date);
  return `${convertedDate.format(format)} ${format === FORMAT_DATE 
    ? '' 
    : formattedOffsetWithMoscow(isUseLocalTimeZone)}`
}

const formatDateToCalendarLocal = (isUseLocalTimeZone: boolean) => (date: any) => {
  if (!date) {
    return '';
  }

  const convertedDate = convertServerDateToUserLocal(isUseLocalTimeZone)(date);
  return convertedDate.calendar()
    + (Math.abs(convertedDate.diff(moment.now(), 'days')) < 7
      ? ` ${formattedOffsetWithMoscow(isUseLocalTimeZone)}` : '');
}