import service from '@/services';
import { SET_PPM_REQUEST_DATA } from '@/reducers/PpmRequest';

export const getPpmRequest = (ppmRequestId) => service.get(`/ppm/request/${ppmRequestId}`);
export const getPpmRequestFiles = (ppmRequestId) => service.get(`/file/ppmRequest/${ppmRequestId}`);

export const getPpmRequestData = (id, callback = undefined) => {
  return async (dispatch) => {
    const ppmRequestData = await getPpmRequest(id);
    ppmRequestData.files = await getPpmRequestFiles(id);

    dispatch({
      type: SET_PPM_REQUEST_DATA,
      payload: ppmRequestData,
    });

    if (callback) {
      callback(ppmRequestData);
    }

    return ppmRequestData;
  };
};
