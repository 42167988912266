import cx from 'classnames';
import React from 'react';

import SwitchSlider from '@/elements/SwitchSlider';
import { isNotEmptyValues } from '@/utils';

const SwitchSliderTitle = ({
  isHide = false,
  id,
  checked = false,
  onChange,
  disabled = false,
  title = null,
  className = null,
  mini = false,
  desc = undefined,
}: {
  isHide?: boolean;
  id?: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  title: string;
  disabled?: boolean;
  className?: string;
  mini?: boolean;
  desc?: string | JSX.Element;
}) => {
  if (isHide) {
    return null;
  }

  const onClick = () => {
    if (disabled) {
      return;
    }

    onChange(!checked);
  };

  const checkDesc = () => {
    if (desc instanceof String) {
      return isNotEmptyValues(desc);
    }
    return !!desc;
  }

  return (
    <React.Fragment>
      <div className={cx("input-title__container dashboard__switch gantt-navbar-item", className, { mini })}>
        <label className="dashboard__switch-label" title={title}>
          <SwitchSlider id={id} checked={checked} onChange={onChange} disabled={disabled} />
          <div className="dashboard__switch-title" onClick={onClick}>{title}</div>
        </label>
        {checkDesc() && (
          <div className="input-title__description">{desc}</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SwitchSliderTitle;
