import i18n from "i18next";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import TableBeta from "@/components/TableBeta";
import UserSearch from "@/components/UserSearch";
import InputNum from "@/elements/Input/InputNum";
import SelectDict from "@/elements/Select/SelectDict";
import {
  createEditable,
  createRemove, DictValue
} from "@/components/TableBeta/utils";
import { isEmptyValues, isNotEmptyValues, isRoleHidden } from "@/utils";
import SwitchSliderTitle from "@/elements/SwitchSliderTitle";
import WorkTeamManageModal, { getWorkTeamColumns } from "@/pages/CreateProject/Blocks/Components/WorkTeamManageModal";
import ButtonSimple from "@/elements/ButtonSimple";
import services from "@/services";
import { WorkTeamCardInner } from "@/pages/CreateProject/Blocks/Components/WorkTeamCard";
import { useCheckRight } from "@/utils/hooks";

export const getEmptyResponsible = (): WorkResponsible => ({
  user: null,
  roleId: null,
  workPlan: null,
});

interface WorkResponsibleRemovable extends WorkResponsible {
  isRemove: boolean
}

export const isEmptyNotResponsible = (item: WorkResponsibleRemovable): boolean => {
  return !item.isRemove || (isNotEmptyValues(item.user) && isNotEmptyValues(item.roleId));
};

export type ResponsibleFormProps = {
  data: (WorkResponsible & { isRemove?: boolean })[];
  setData: any;
  isFromGantt: boolean;
  readonly: boolean;
  isEdit: boolean;
  linkDate: string;
  required: boolean | 'red' | 'default';
  isWithLoad: boolean;
  setIsWithLoad: UpdateFunc<boolean>;
  projectId: number;
  dataMainTable: (WorkResponsible & { isRemove?: boolean })[];
  setDataMainTable: UpdateFunc<WorkResponsible[]>;
  shadowSave?: any;
};

const getWorkTeams = (projectId) => services.get(`/work/getWorkTeam/${projectId}`);
const updateWorkTeam = (teamId, data) => services.put(`/work/updateWorkTeam/${teamId}`, data);

export const NO_TEAM_KEY = 'Не входят в группы';
export const NO_TEAM_KEY_SINGULAR = 'Не входит в группы';

const ResponsibleForm = ({
  data = [], setData, isFromGantt, readonly, isEdit, linkDate, shadowSave,
  required, isWithLoad, setIsWithLoad, projectId, dataMainTable, setDataMainTable
}: ResponsibleFormProps) => {

  const getColumns = ({isEdit, isWithLoad, mainTable}) => {
    const result: any[] = [
      {
        Header: i18n.t('workDataRequest.responsibleList.user'),
        accessor: 'user',
        required: 'true',
        headerStyle: {
          width: '40%'
        },
        Cell: mainTable ? createEditable(UserSearch, {
          titleClassName: "in-table",
          placeholder: "Выберите исполнителя",
          fastApplyData: true
        }) : ({value}) => value?.label
      },
      {
        Header: i18n.t('workDataRequest.responsibleList.roleId'),
        accessor: 'roleId',
        required: 'true',
        headerStyle: {
          width: '30%'
        },
        Cell: mainTable ? createEditable(SelectDict, {
          dictName: 'roles',
          titleClassName: "in-table",
          placeholder: "Выберите роль",
          filterFunc: (item) => item.typeCode === 'PROJECT' && !isRoleHidden(item),
          fastApplyData: true,
        }) : ({value}) => DictValue('roles', value)
      },
    ];

    if (isWithLoad) {
      result.push({
        Header: i18n.t('workDataRequest.responsibleList.workPlan'),
        accessor: 'workPlan',
        required: 'true',
        headerStyle: {
          width: isEdit ? '15%' : '30%'
        },
        Cell: createEditable(InputNum, {
          min: 0,
          max: 10_000,
          suffix: " час.",
          placeholder: "В часах",
          titleClassName: "in-table",
          decimalScale: 2,
        })
      });

      result.push({
        Header: i18n.t('workDataRequest.responsibleList.workFact'),
        accessor: 'workFact',
        headerStyle: {
          width: '15%'
        },
        Cell: ({value}) => isNotEmptyValues(value) ? `${value} час.` : '-'
      });
    }

    if (mainTable) {
      result.push({
        id: 'remove',
        Header: '',
        headerStyle: {
          width: '1%'
        },
        Cell: (args) => isEmptyValues(args.row.original.workFact)
          ? createRemove({style: {paddingLeft: '0.3rem'}})(args)
          : null
      });
    }

    return result;
  };

  const columns = useMemo(() => getColumns({isEdit, isWithLoad, mainTable: true}),
    [isEdit, isWithLoad, data]);

  const columnsWorkTeam = useMemo(() => getColumns({isEdit, isWithLoad, mainTable: false}),
    [isEdit, isWithLoad, data]);

  const modalManageRef = useRef<any>();
  const modalAppendRef = useRef<any>();

  const [workTeams, setWorkTeams] = useState([]);
  const [deleteTeamsList, setDeleteTeamsList] = useState([]);
  const [editTeam, setEditTeam] = useState(null);
  const checkRight = useCheckRight();

  const updateTeam = () => {
    if (projectId) {
      getWorkTeams(projectId).then(setWorkTeams);
    }
  };

  useEffect(() => {
    updateTeam();
  }, [projectId]);

  const setDataWrapper = (key, value) => {
    setData(key, value);
    setTimeout(() => shadowSave && shadowSave(), 350);
  }

  return (
    <div className="mb-3">
      <h3 style={{margin: 0}} className="h3-felix workList__contentTitle">Рабочая группа блока работ</h3>
      <div className="mb-3 display-flex gap-3">
        {checkRight('EDIT_PROJECT') && <div>
          <ButtonSimple className={isFromGantt ? 'font-size-14' : 'font-size-16'}
                        children={i18n.t('workTeamWeb.buttonManage')}
                        onClick={() => modalManageRef?.current?.open()}/>
        </div>}
        {isWithLoad && <Link
          to={`/timesheets?week=${linkDate}`}
          className='rt-link'
          target='_blank'
        >
          Учет рабочего времени
        </Link>}
      </div>

      <div className='marginBottom-30'>
        <SwitchSliderTitle
          title={i18n.t('workDataRequest.responsibleList.isWithLoad')}
          checked={isWithLoad}
          onChange={(val) => setIsWithLoad(val)}
          mini
        />
      </div>

      {data && Object.keys(data).filter(key => key !== NO_TEAM_KEY && !deleteTeamsList.includes(key))
        .map(key => `${key}`)
        .map(key => {
          const workTeamId = data[key][0]?.workTeamId;
          const team = workTeams.find(team => team.id === workTeamId);
          const isEditTeam = editTeam?.data && editTeam.data?.id === team?.id;
          const saveEditData = () => {
            if (editTeam?.data?.id) {
              return updateWorkTeam(editTeam.data.id, {id: editTeam?.data?.id,
                name: editTeam.localName,
                memberList: editTeam.localList?.filter(isEmptyNotResponsible)})
                .then((updatedTeam) => {
                  setDataWrapper(`${updatedTeam.id}`, updatedTeam.memberList
                    .map(member => ({...member, workTeamId: team?.id, workTeamName: team?.name})));
                  setEditTeam(null);
                })
                .then(() => updateTeam());
            }
          }

          return <div key={key} className="work-responsible__container">
            <h6>{`РГ: ${team?.name ?? ''}`}</h6>
            {!isEditTeam && <TableBeta
              required={isWithLoad ? required : null}
              requiredNoEmptyRows
              data={data[key]}
              columns={columnsWorkTeam}
              setData={(value) => setDataWrapper(key, value)}
              className="input-color"
              props={{
                noHideEmpty: true,
                isAcceptEdit: !readonly,
                withFilterRemoved: true,
                additionalButtons: [
                  {
                    title: 'Удалить группу',
                    alwaysShow: true,
                    func: () => {
                      setDataWrapper(key, prevState => prevState
                        .map(item => ({...item, isRemove: true})));
                      setDeleteTeamsList(prevState => [...prevState, key]);
                    },
                  },
                  {
                    title: 'Редактировать',
                    alwaysShow: true,
                    func: () => {
                      setEditTeam({data: team, localList: team?.memberList ?? [], localName: team?.name})
                    },
                  }
                ]
              }}
              withMobile
              getEmptyRow={getEmptyResponsible}
              alwaysMobile={isFromGantt}
            />}

            {team && isEditTeam && <Fragment>
              <WorkTeamCardInner data={team}
                                 withTableButtons
                                 getColumns={getWorkTeamColumns}
                                 isEdit={isEditTeam}
                                 setEditData={setEditTeam}
                                 saveEditData={saveEditData}/>
              <div className='marginBottom-20'/>
            </Fragment>}
          </div>
        })}

      {<div className="work-responsible__container">
        <h6>{`${NO_TEAM_KEY}`}</h6>
        <TableBeta
          required={required}
          requiredNoEmptyRows
          data={dataMainTable}
          columns={columns}
          setData={setDataMainTable}
          className="input-color"
          props={{
            noHideEmpty: true,
            isAcceptEdit: !readonly,
            withFilterRemoved: true,
            withAddButton: true,
            addButtonTitle: 'Добавить исполнителя',
            additionalButtons: checkRight('EDIT_PROJECT')
              ? [{title: 'Сформировать группу', func: () => modalAppendRef?.current?.open()}]
              : undefined
          }}
          withMobile
          getEmptyRow={getEmptyResponsible}
          alwaysMobile={isFromGantt}
        />
      </div>}

      <div className='marginBottom-30'/>

      <WorkTeamManageModal addByKey
                           ref={modalManageRef}
                           setData={(key, value) => {
                             setDataWrapper(key, value);
                             setDeleteTeamsList(prevState => prevState
                               .filter(deletedTeamKey => deletedTeamKey !== key));
                           }}
                           projectId={projectId}
                           updateTeam={updateTeam} />

      <WorkTeamManageModal addByKey
                           appendType
                           initialData={dataMainTable}
                           setInitialData={setDataMainTable}
                           ref={modalAppendRef}
                           setData={setDataWrapper}
                           projectId={projectId}
                           updateTeam={updateTeam} />
    </div>
  );
};

export default ResponsibleForm;