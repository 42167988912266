import cx from 'classnames';
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { ItemTypes, SAUSAGE_TOP } from '@/components/Gantt/const';
import GanttItemConnect from "@/components/Gantt/elements/GanttItemConnect";
import { checkGroup, checkMilestone } from '@/pages/CreateProject/Blocks/utils';

export interface GanttItemPureProps {
  item: GanttTableItem;
  top?: number;
  left?: number;
  width?: number;
  opacity?: number;
  setType?: any;
  refs?: {
    dragRef?: any;
    dragConnectLeftRef?: any,
    dragConnectRightRef?: any,
  };
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly?: boolean;
  isInitial?: boolean;
}

export const GanttItemPure = ({
  item, top, left, width, opacity, refs = {}, setType, onClick, readonly = false, isInitial = false
}: GanttItemPureProps) => {
  const onDragStart = (e) => {
    setType((document.elementFromPoint(e.clientX, e.clientY) as any).dataset.dragType || ItemTypes.SAUSAGE);
  };
  const isGroup = checkGroup(item);
  const isMilestone = checkMilestone(item);

  return (
    <div
      ref={isGroup || readonly ? undefined : refs.dragRef}
      className={cx('gantt-item__container', item.customClass, {
        milestone: isMilestone,
        group: isGroup,
        readonly
      })}
      style={{
        top,
        width,
        left,
        opacity,
      }}
      onDragStart={onDragStart}
      onClick={onClick}
      title={item.name}
    >
      {!item?.isExtreme && <GanttItemConnect ref={refs.dragConnectLeftRef} type="left"/>}
      <div className="gantt-item__progress" style={{ width: `${item.progress}%` }}></div>
      <div data-drag-type={ItemTypes.RESIZE_LEFT} className="gantt-item__resize left"></div>
      <div className='gantt-item__title'>{item.name}</div>
      <div data-drag-type={ItemTypes.RESIZE_RIGHT} className="gantt-item__resize right"></div>
      {!item?.isExtreme && <GanttItemConnect ref={refs.dragConnectRightRef} type="right"/>}
    </div>
  );
};

export interface GanttItemProps {
  data: GanttTableItem;
  position: PositionGanttItemType;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  readonly?: boolean;
}

const GanttItem = ({ data, position, onClick, readonly = false }: GanttItemProps) => {
  const [type, setType] = useState(ItemTypes.SAUSAGE);
  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type: ItemTypes.SAUSAGE,
    item: {
      data,
      type
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }), [type, data]);

  const [, dragConnectLeftRef, previewConnectLeft] = useDrag(() => ({
    type: ItemTypes.CONNECT_LEFT,
    item: {
      data,
    }
  }), [data]);

  const [, dragConnectRightRef, previewConnectRight] = useDrag(() => ({
    type: ItemTypes.CONNECT_RIGHT,
    item: {
      data,
    }
  }), [data]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    previewConnectLeft(getEmptyImage(), { captureDraggingState: true });
    previewConnectRight(getEmptyImage(), { captureDraggingState: true });
  }, [type, data]);

  return (
    <GanttItemPure
      refs={{
        dragRef,
        dragConnectLeftRef,
        dragConnectRightRef,
      }}
      item={data}
      left={position.x}
      top={position.y + SAUSAGE_TOP}
      width={position.width}
      opacity={isDragging ? 0 : 1}
      setType={setType}
      onClick={onClick}
      readonly={readonly}
    />
  );
};

export default GanttItem;