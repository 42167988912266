import i18n from "i18next";
import { dictTreeToDict, forrmattedNum, getDict, getPaybackPeriodStr, getRound } from '@/utils';

const AgreementBudgetBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      const result = [];
      result.push(
        {
          title: i18n.t('projectVersion.budget.type'),
          data: getDict(self.props.dict.budgetType, data.budgetTypeId)
        },
        {
          title: i18n.t('projectVersion.budget.budgetDirection'),
          data: getDict(dictTreeToDict(self.props.dict.budgetDirection), data.budgetDirectionId)
        },
        {
          title: i18n.t('projectVersion.budget.budgetAllSum'),
          data: getRound(data.budgetAllSum)
        },
      );

      if (data.projectTypeCode !== 'PROJECT') {
        result.push(
          {
            title: "Суммарный бюджет, тыс. руб. (ОКВ)",
            data: data.totalDds ? `${getRound(data.totalOkb)} тыс. руб.` : null
          },
          {
            title: "Суммарный бюджет, тыс. руб. (ДДС)",
            data: data.totalDds ? `${getRound(data.totalDds)} тыс. руб.` : null
          },
          {
            title: "Суммарный бюджет, тыс. руб. (OPEX)",
            data: data.totalOpex ? `${getRound(data.totalOpex)} тыс. руб.` : null
          },
          {
            title: "Суммарный бюджет, тыс. руб. (ФОТ)",
            data: data.totalFot ? `${getRound(data.totalFot)} тыс. руб.` : null
          },
        );
      }
      
      if (['PROJECT', 'PROGRAM'].includes(data.projectTypeCode)) {
        result.push(
          {
            title: i18n.t('projectVersion.budget.presentValue'),
            data: data.presentValue ? `${getRound(data.presentValue)} тыс. руб.` : null
          },
          {
            title: i18n.t('projectVersion.budget.irr'),
            data: data.irr ? `${data.irr}%` : null
          },
          {
            title: i18n.t('projectVersion.budget.paybackPeriod'),
            data: getPaybackPeriodStr(data.paybackPeriod)
          },
          {
            title: i18n.t('projectVersion.budget.returnIndex'),
            data: data.returnIndex
          },
        );
      }

      result.push(
        {
          title: i18n.t('projectVersion.budget.dpp'),
          data: forrmattedNum(data.dpp)
        },
        {
          title: i18n.t('projectVersion.budget.budgetScaleId'),
          data: getDict(self.props.dict.scale, data.budgetScaleId)
        },
      )

      return result;
    }
  },
])

export default AgreementBudgetBlock;
