import React, {
  CSSProperties, forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import { Modal } from "react-bootstrap";
import ButtonRt from "@/components/Helper/ButtonRt";
import cx from "classnames";
import ButtonSimple from "@/elements/ButtonSimple";
import services from "@/services";
import i18n from "i18next";
import { createEditable, createRemove, DictValue } from "@/components/TableBeta/utils";
import UserSearch from "@/components/UserSearch";
import SelectDict from "@/elements/Select/SelectDict";
import { isEmptyValues, isRoleHidden } from "@/utils";
import TableBeta from "@/components/TableBeta";
import { getEmptyResponsible, isEmptyNotResponsible } from "@/pages/CreateProject/Blocks/Components/ResponsibleForm";
import Input from "@/elements/Input";
import WorkTeamCard from "@/pages/CreateProject/Blocks/Components/WorkTeamCard";
import { useCheckRight } from "@/utils/hooks";

const getWorkTeams = (projectId) => services.get(`/work/getWorkTeam/${projectId}`);
const addWorkTeam = (projectId, data) => services.post(`/work/createWorkTeam/${projectId}`, data);
const updateWorkTeam = (teamId, data) => services.put(`/work/updateWorkTeam/${teamId}`, data);

export const getWorkTeamColumns = (isEditable = true) => {

  const columns: any[] = [
    {
      Header: i18n.t('workDataRequest.responsibleList.user'),
      accessor: 'user',
      required: 'true',
      headerStyle: {
        width: '60%'
      },
      Cell: isEditable
        ? createEditable(UserSearch, {
          titleClassName: "in-table",
          placeholder: "Выберите исполнителя",
          fastApplyData: true,
          isPortal: true
        })
        : ({value}) => value?.label
    },
    {
      Header: i18n.t('workDataRequest.responsibleList.roleId'),
      accessor: 'roleId',
      required: 'true',
      headerStyle: {
        width: '39%'
      },
      Cell: isEditable
        ? createEditable(SelectDict, {
          dictName: 'roles',
          titleClassName: "in-table",
          placeholder: "Выберите роль",
          filterFunc: (item) => item.typeCode === 'PROJECT' && !isRoleHidden(item),
          fastApplyData: true,
          isPortal: true
        })
        : ({value}) => DictValue('roles', value)
    }
  ];

  if (isEditable) {
    columns.push({
      id: 'remove',
      Header: '',
      headerStyle: {
        width: '1%'
      },
      Cell: (args) => isEmptyValues(args.row.original.workFact)
        ? createRemove({style: {paddingLeft: '0.3rem'}})(args)
        : null
    });
  }
  return columns;
}

interface WorkTeamManageModalProps {
  projectId: number,
  setData: any, style?:
    CSSProperties,
  disableChoose?: boolean,
  updateTeam?: any,
  addByKey?: any,
  appendType?: any,
  initialData?: any[],
  setInitialData?: any,
  className?: string,
  onClick?: any,
  onClose?: any
}

export const WorkTeamManageModalButton = ({projectId, setData, style, disableChoose = undefined, className = undefined,
  updateTeam = undefined, addByKey = false, appendType = undefined, initialData = undefined, setInitialData = undefined,
  onClick = undefined, onClose = undefined}
  : WorkTeamManageModalProps) => {

  const modalManageRef = useRef<any>();

  return <Fragment>
    <div>
      <ButtonSimple className={className}
                    children={i18n.t('workTeamWeb.buttonManage')}
                    onClick={() => {
                      onClick && onClick();
                      modalManageRef?.current?.open();
                    }}/>
    </div>
    <WorkTeamManageModal projectId={projectId}
                         ref={modalManageRef}
                         disableChoose={disableChoose}
                         setData={setData}
                         style={style}
                         updateTeam={updateTeam}
                         addByKey={addByKey}
                         appendType={appendType}
                         initialData={initialData}
                         setInitialData={setInitialData}
                         onClose={onClose}/>
  </Fragment>
}


const WorkTeamManageModal = forwardRef(({
  projectId, setData, style, disableChoose = undefined,
  updateTeam = undefined, addByKey = false, appendType = undefined, initialData = undefined,
  setInitialData = undefined, onClose = undefined}
  : WorkTeamManageModalProps, ref) => {

  const [isShow, setIsShow] = useState(false);
  const [section, setSection] = useState<'select' | 'create' | string>(appendType ? 'create' : 'select');
  const [workGroups, setWorkGroups] = useState<any[]>([]);
  const sections = [{title: 'Выбрать группу', code: 'select'},
    {title: 'Создать группу', code: 'create'}];

  const checkRight = useCheckRight();

  const [createMembers, setCreateMembers] = useState<any[]>(initialData?.map(item => (
    {isRemove: item?.isRemove,
    user: item?.user,
    roleId: item?.roleId})) ?? []);
  const [createWorkGroupName, setCreateWorkGroupName] = useState(null);
  const [editTeam, setEditTeam] = useState(null);

  const [checkedIds, setCheckedIds] = useState([]);
  const prepareMemberList = (workTeam) => workTeam?.memberList
    .map(item => ({...item, id: null, workTeamId: workTeam?.id, workTeamName: workTeam?.name}));

  const setDataWrapper = (data: any[]) => {
    if (addByKey) {
      data.forEach(item => {
        setData(`${item?.id}`, prepareMemberList(item));
      });
    } else {
      let selectedTeams = [];
      data.forEach(item => {
        selectedTeams = [...selectedTeams, ...prepareMemberList(item)];
      });
      setData(selectedTeams);
    }
  };

  useEffect(() => {
    if (isShow) {
      updateWorkGroups();
    }
  }, [projectId, isShow]);

  const updateWorkGroups = () => {
    if (projectId) {
      getWorkTeams(projectId).then(setWorkGroups);
    }
    updateTeam && updateTeam();
  }

  const open = () => {
    setCreateMembers(initialData?.map(item => (
      {isRemove: item?.isRemove,
        user: item?.user,
        roleId: item?.roleId})) ?? []);
    setCreateWorkGroupName(null);
    setIsShow(true);
  }
  const close = () => {
    setIsShow(false);
    setCheckedIds([]);
    clear();
    updateTeam && updateTeam();
    onClose && onClose();
  };

  const clear = () => {
    setEditTeam(null);
    setCreateWorkGroupName(null);
    setCreateMembers([]);
  };

  const choose = () => {
    if (checkedIds?.length >= 0) {
      setDataWrapper(workGroups.filter(item => checkedIds.includes(item.id)));
    }
    return close();
  };

  const createAndApply = () => {
    return addWorkTeam(projectId, {name: createWorkGroupName?.trim(),
      memberList: createMembers?.filter(isEmptyNotResponsible)})
      .then(data => setDataWrapper([data]))
      .then(() => updateWorkGroups())
      .then(() => close()).then(() => {
        if (appendType) {
          setInitialData && setInitialData(prevState => prevState.map(item => ({...item, isRemove: true})));
        }
      });
  }

  const create = () => {
    return addWorkTeam(projectId, {name: createWorkGroupName?.trim(),
      memberList: createMembers?.filter(isEmptyNotResponsible)})
      .then(() => updateWorkGroups())
      .then(() => clear());
  }

  const update = () => {
    if (editTeam?.data?.id) {
      return updateWorkTeam(editTeam.data.id, {id: editTeam.data.id,
        name: editTeam.localName,
        memberList: editTeam.localList?.filter(isEmptyNotResponsible)})
        .then(() => setEditTeam(null))
        .then(() => updateWorkGroups());
    }
  }

  const columns = useMemo(getWorkTeamColumns, []);

  useImperativeHandle(ref, () => ({
    open() {
      open();
    },
    close() {
      close();
    },
  }));

  return (
    <>
      <Modal
        onHide={close}
        show={isShow}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="h3">{i18n.t('workTeamWeb.buttonManage')}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageSelector__wrapper">
            {!appendType && <div className="admin-nav__links pageSelector">
              {sections.map((item, i) => (
                <ButtonSimple
                  key={i}
                  className={cx("pageSelectorItem", section === item.code ? "active" : null)}
                  children={item.title}
                  onClick={() => setSection(item.code)}/>
              ))}
            </div>}

            {section === 'select' && <div>
              {workGroups.length > 0
                ? <div className='work-group-modal-container'>
                  {workGroups.map(item => <WorkTeamCard data={item}
                                                        key={item.name}
                                                        getColumns={getWorkTeamColumns}
                                                        updateWorkGroups={updateWorkGroups}
                                                        checkedIds={checkedIds}
                                                        isEdit={editTeam?.data && editTeam.data?.id === item?.id}
                                                        setEditData={setEditTeam}
                                                        saveEditData={update}
                                                        disableChoose={disableChoose}
                                                        setCheckedIds={setCheckedIds}/>)}
                </div>

                : <div className='text-align-center marginTop-20'>
                  Рабочие группы не созданы
                </div>}
            </div>}

            {section === 'create' && <div className='marginTop-20'>
              <Input
                required
                label={i18n.t('workTeamWeb.name')}
                value={createWorkGroupName}
                onChange={(_, val) => setCreateWorkGroupName(val)}
              />

              <div>
                <TableBeta
                  required
                  requiredNoEmptyRows
                  data={createMembers}
                  columns={columns}
                  setData={setCreateMembers}
                  className="input-color"
                  props={{
                    noHideEmpty: true,
                    isAcceptEdit: true,
                    withFilterRemoved: true,
                    withAddButton: true,
                  }}
                  withMobile
                  getEmptyRow={getEmptyResponsible}
                />
              </div>
            </div>}

          </div>
        </Modal.Body>
        <Modal.Footer>
          {section === 'select' && <div className="mb-3 form-group button-felix-group">
            {!editTeam?.data
              ? <ButtonRt onClick={choose} disabled={!checkRight('EDIT_PROJECT')} className="modalButton">Ок</ButtonRt>
              : <ButtonRt onClick={update} disabled={!checkRight('EDIT_PROJECT')}>Сохранить</ButtonRt>}
            <ButtonRt onClick={close} type="outline">Отмена</ButtonRt>
          </div>}
          {section === 'create' && <div className="mb-3 form-group button-felix-group">
            {!disableChoose && <ButtonRt disabled={!checkRight('EDIT_PROJECT')} onClick={createAndApply}>Создать и применить</ButtonRt>}
            {disableChoose && <ButtonRt disabled={!checkRight('EDIT_PROJECT')} onClick={create}>Создать</ButtonRt>}
            <ButtonRt onClick={close} type="outline">Отмена</ButtonRt>
          </div>}
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default WorkTeamManageModal;