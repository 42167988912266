import { compact } from 'lodash';
import React from "react";
import { CalendarType, CalendarTypeData, SAUSAGE_HEIGHT } from '@/components/Gantt/const';
import GanttLineItem from '@/components/Gantt/elements/GanttLineItem';
import moment from "moment";
import { useDragLayer } from "react-dnd";

const fixCoords = (item: PositionGanttItemType, isStart: boolean) => {
  return {
    x: item.x + (isStart ? 0 : item.width),
    y: item.y + SAUSAGE_HEIGHT * 0.5
  };
};

interface GanttLinkProps {
  data: Milestone[];
  positions: PositionGanttItemsType;
  nowPosition: number;
  dateRange: DateRangeType;
  calendarType: CalendarType;
}

const MilestoneGanttLink = ({ data, positions, nowPosition, dateRange, calendarType }: GanttLinkProps) => {
  const headerWidth = CalendarTypeData[calendarType].headerWidth;
  const momentType = CalendarTypeData[calendarType].momentType;

  const { isDragging, dragItem } =
    useDragLayer((monitor) => ({
      isDragging: monitor.isDragging(),
      dragItem: monitor.getItem(),
    }));

  const layout = [];
  const startClone = dateRange.min.clone();
  let width = 0;
  while (startClone.isSameOrBefore(dateRange.max)) {
    const curWidth = headerWidth(startClone);
    width += curWidth;
    layout.push({
      x1: width,
      y1: 0,
      x2: width,
      y2: data.length * SAUSAGE_HEIGHT,
    });
    startClone.add(1, momentType);
  }
  for (let i = 1; i <= data.length; i++) {
    layout.push({
      x1: 0,
      y1: i * SAUSAGE_HEIGHT,
      x2: width,
      y2: i * SAUSAGE_HEIGHT,
    });
  }

  const nowLine = {
    x1: nowPosition,
    x2: nowPosition,
    y1: 0,
    y2: data.length * SAUSAGE_HEIGHT,
  };

  const lines = compact(Object.keys(positions).map(posKey => {
    if (!positions[posKey].milestoneDatePlan) {
      return undefined;
    }
    const candidates = [positions[posKey].milestoneDatePlan];

    if (positions[posKey].milestoneDateFact) {
      candidates.push(positions[posKey].milestoneDateFact);
    }

    if (!positions[posKey].milestoneDateFact && positions[posKey].milestoneDatePrediction) {
      candidates.push(positions[posKey].milestoneDatePrediction);
    }

    const from = candidates
      .find(item => item.date === moment.min(candidates.map(innerItem => innerItem.date)));
    const to = candidates
      .find(item => item.date === moment.max(candidates.map(innerItem => innerItem.date)));

    if (from === to) {
      return undefined;
    }

    const type = {
      from: false, to: false,
    };

    return {
      from: fixCoords(from, type.from),
      to: fixCoords(to, type.to),
      type,
      itemId: parseInt(posKey)
    };
  }));

  return (
    <div className="milestone-gantt-link__container" style={{
      height: data.length * SAUSAGE_HEIGHT,
      width
    }}>
      <svg className="milestone-gantt-line">
        {layout.map((item, i) => (
          <line key={`layout-${i}`} {...item} stroke='#e7e7e7' strokeWidth={1} />
        ))}
        <line key={`now-line`} {...nowLine} stroke='red' strokeWidth={0.8}/>
        {lines.map((line, i) => (
          <GanttLineItem key={i}
                         from={line.from}
                         to={line.to}
                         simple
                         type={line.type}
                         hide={line.itemId === dragItem?.data?.id}/>
        ))}
      </svg>
    </div>
  );
};

export default MilestoneGanttLink;