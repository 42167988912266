import React, { useEffect, useState } from 'react';
import './style.scss'

const Toast = ({toastList, autoDelete, autoDeleteTime}) => {
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteToast = id => {
    const listItemIndex = list.findIndex(e => e.id === id);
    const toastListItem = toastList.findIndex(e => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  }

  return (
    <>
      <div className={`felix-toast-container`}>
        {
          list.map((toast, i) =>
            <div
              key={i}
              className={`felix-toast-plate felix-toast-top-right`}
            >
              <button className='btn-close' onClick={() => deleteToast(toast.id)}></button>
              <div className="felix-toast-message">
                {toast.text}
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default Toast;
