import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, Location } from 'react-router';
import cx from "classnames";
import { useRefreshValOnSidebarChange, useShowModal } from '@/utils/hooks';
import RedDot from "@/elements/RedDot";
import { useAppSelector } from '@/utils/typedHooks';
import { resetProject } from '@/actions/resetProject';

const urlFormat = (url) => url.replace('?', '/')

const NavItem = ({
  isShow,
  title,
  url,
  icon,
  checker,
  location,
  section,
  target
}: NavItemType & { location: Location }) => {
  const [
    projectVersionId,
    requiredData,
  ] = useAppSelector(state => [
    state.NewProject.newProjectData.id,
    state.Required[state.NewProject.newProjectData?.id],
  ]);
  const history = useNavigate();
  const showModal = useShowModal();
  const linkRef = useRef(null);
  const isActive = new RegExp(checker || urlFormat(url)).test(urlFormat(location.pathname + location.search));

  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.scrollIntoView({ block: "end", inline: "nearest" })
    }
  }, [isActive]);

  useEffect(() => {
    //Не даем открывать страницу если к ней нет доступа
    if (isShow === false && isActive) {
      resetProject();
      history('/');
      showModal("Раздел не найден или к нему нет доступа");
    }
  }, [isShow, isActive]);

  if (isShow === false) {
    return null;
  }

  const checkSection = (section) => requiredData?.sections && projectVersionId
    ? requiredData.sections.includes(section) && requiredData.accent
    : false;

  const invalid = checkSection(section);

  return (
    <li className={isActive ? 'active' : null} ref={isActive ? linkRef : null}>
      <Link to={url} target={target ? target : undefined} className="link_flex text-master-color">
        {icon && React.createElement(icon)}
        <span className="project-nav__title">
          {title}
        </span>
        {invalid && <RedDot style={{marginBottom: '2px'}}/>}
      </Link>
    </li>
  )
}

const Nav = ({
  data,
  isShow = true
}: {
  data: NavItemType[]
  isShow?: boolean
}) => {
  const staticCss = useRefreshValOnSidebarChange('static');

  const location = useLocation();

  return (
    <div className={cx('creacte-project-nav', 'custom-scrollbar', staticCss)}>
      <ul className="nav-create-project-felix">
        {isShow && data.map(item => (
          <NavItem
            key={item.title}
            location={location}
            {...item}
          />
        ))}
      </ul>
    </div>
  )
}

export default Nav;
