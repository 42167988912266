export const SET_REQUIRED_SECTIONS = 'SET_REQUIRED_SECTIONS';
export const SET_REQUIRED_ACCENT = 'SET_REQUIRED_ACCENT';

export interface RequiredData {
  sections: string[];
  accent: boolean;
}

export type RequiredProjects = {
  [key: number]: RequiredData;
}

export const initialState = {};

export const Required = (state: RequiredProjects = initialState, action) => {
  switch (action.type) {
    case SET_REQUIRED_SECTIONS:
      return {
        ...state,
        [action.projectVersionId]: {
          ...state[action.projectVersionId],
          sections: action.sections,
        },
      }
    case SET_REQUIRED_ACCENT:
      return {
        ...state,
        [action.projectVersionId]: {
          ...state[action.projectVersionId],
          accent: action.accent,
        },
      }
    default:
      return state;
  }
};
