import React from 'react';
import cx from "classnames";
import Input from "@/elements/Input";
import i18n from "i18next";
import { useStateWithKey } from "@/utils/hooks";
import { prevent } from "@/utils";
import { AppendIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { isEmpty } from "lodash";
import AcceptDelete from "@/components/Helper/AcceptDelete";

const getDefaultNewUrl = () => ({title: null, url: null});

const UrlsForm = ({urls, setUrls, isEdit, updateIsDirty = undefined}) => {
  const [newUrl, setNewUrlByKey, setNewUrl] = useStateWithKey(getDefaultNewUrl());

  const setNewUrlByKeyWrapper = (key, value, shouldUpdateDirty = true) => {
    setNewUrlByKey(key, value);
    if (updateIsDirty && shouldUpdateDirty) {
      updateIsDirty(true);
    }
  }

  const addUrl = () => {
    setUrls([
      ...urls,
      newUrl,
    ]);

    setNewUrl(getDefaultNewUrl());
  }

  const removeUrl = (index) => {
    setUrls(urls.map((url, i) => {
      if (i === index) {
        url.isRemoved = true;
      }
      return url;
    }));
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3 form-group">
            <h3 className={cx('h3-felix', 'workList__contentTitle')}>Ссылки на информацию о блоке работ</h3>
          </div>
        </div>
        <div className='col-md-12'>
          {isEdit && (
            <div className='work-urls-add__container'>
              <div className='work-urls-add-title'>
                <Input
                  tooltip
                  label={i18n.t('workDataRequest.urlList.title')}
                  value={newUrl.title}
                  onChange={e => setNewUrlByKeyWrapper('title', e.target.value)}
                  name="work_url_add_title"
                />
              </div>
              <div className='work-urls-add-url'>
                <Input
                  tooltip
                  label={i18n.t('workDataRequest.urlList.url')}
                  value={newUrl.url}
                  onChange={e => setNewUrlByKeyWrapper('url', e.target.value)}
                  name="work_url_add_url"
                />
              </div>
              <button type="button"
                      className={cx("button-outline-add-felix", {disabled: !newUrl.url})}
                      onClick={prevent(addUrl)}
                      disabled={!newUrl.url}>
                <AppendIcon />
              </button>
            </div>
          )}
        </div>
        <div className='col-md-12 mb-3'>
          {(urls || []).map((url, i) => (
            <div key={i}>
              {!url.isRemoved && (
                <div>
                  <a href={url.url} target='_blank' className='link active'>{url.title || url.url}</a>
                  {isEdit && (
                    <AcceptDelete className="input-file-felix__remove"
                                  onOk={() => removeUrl(i)}
                                  buttonType="text">
                      x
                    </AcceptDelete>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

      </div>
    </>
  );
};

export default UrlsForm;
