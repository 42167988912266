import React from 'react';
import cx from "classnames";

const SwitchSlider = ({
  id = undefined,
  checked = false, 
  onChange, 
  disabled = false 
}) => {
  const onClick = () => {
    if (disabled) {
      return;
    }

    onChange(!checked);
  }

  return (
    <React.Fragment>
      <input
        id={id}
        className={cx("switch-input-felix", {"checked": checked})}
        checked={checked}
        disabled={disabled}
        onChange={() => {}}
        type="checkbox"
      />
      <span onClick={onClick} className="switch-slider-felix"></span>
    </React.Fragment>
  )
}

export default SwitchSlider
