import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import { CalendarType, SAUSAGE_HEIGHT } from "@/components/Gantt/const";
import GanttDateList from "@/components/Gantt/elements/GanttDateList";
import {
  calcNowPosition, getDateRangeMilestone,
  getSausageWidth, getSausageWidthOffset
} from '@/components/Gantt/util/utils';
import { isEmptyValues, parseDate } from "@/utils";
import MilestoneGanttContainer from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttContainer";
import MilestoneGanttLink from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttLink";
import MilestoneGanttDragLayer from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttDragLayer";

interface IGanttGraphProps {
  scrollRef: React.MutableRefObject<any>;
  data: Milestone[];
  setData: any;
  calendarType: CalendarType;
  isEdit: boolean;
  projectData: Project;
}

export const MILESTONE_WIDTH = 12;

const calcMilestonePositions = (
  data: Milestone[],
  dateStart: moment.Moment,
  calendarType: CalendarType
): PositionGanttItemsType => {
  const result = {};

  data.forEach((item, i) => {

    if (!item.datePlan) {
      return;
    }

    const itemDatePlan = parseDate(item.datePlan);
    const itemDateFact = parseDate(item.dateFact);
    const itemDatePlanBase = parseDate(item.datePlanBase);

    const width = MILESTONE_WIDTH;

    const getOffsetData = (date) => ({
      id: item.id,
      x: getSausageWidthOffset(dateStart, date.subtract(1, 'day'), calendarType, true) - MILESTONE_WIDTH,
      y: SAUSAGE_HEIGHT * i,
      width: width,
      date: date
    });

    result[item.id] = {};

    result[item.id].milestoneDatePlan = getOffsetData(itemDatePlan);

    if (itemDateFact) {
      result[item.id].milestoneDateFact = getOffsetData(itemDateFact);
    }

    if (itemDatePlanBase) {
      result[item.id].milestoneDatePlanBase = getOffsetData(itemDatePlanBase);
    }
  });

  return result;
};

const MilestoneGanttGraph = React.forwardRef(({
  scrollRef,
  data,
  setData,
  calendarType,
  isEdit,
  projectData
}: IGanttGraphProps, ref) => {
  const [
    flattenData,
    dateRange,
    positions,
    nowPosition,
  ] = useMemo(() => {
    if (isEmptyValues(data)) {
      return [];
    }

    const flattenData = data;

    const dateRange = getDateRangeMilestone(flattenData, calendarType) || {
      min: moment(),
      max: moment()
    };

    return [
      flattenData,
      dateRange,
      calcMilestonePositions(flattenData, dateRange.min, calendarType),
      calcNowPosition(dateRange.min, calendarType),
    ];
  }, [data, calendarType]);

  const scrollToNow = () => {
    const { width } = scrollRef.current.getBoundingClientRect();
    scrollRef.current.scrollTo(nowPosition - width / 2, 0);
  }

  useImperativeHandle(ref, () => ({ scrollToNow }));

  useEffect(() => {
    if (nowPosition) {
      scrollToNow();
    }
  }, [nowPosition]);

  if (isEmptyValues(data)) {
    return null;
  }

  return (
    <div className={cx("gantt__container", calendarType)}>
      <GanttDateList
        dateRange={dateRange}
        calendarType={calendarType}
      />

      <MilestoneGanttLink
        data={flattenData}
        positions={positions}
        nowPosition={nowPosition}
        dateRange={dateRange}
        calendarType={calendarType}
      />

      <MilestoneGanttContainer
        data={flattenData}
        setData={setData}
        width={getSausageWidth(dateRange.min, dateRange.max, calendarType)}
        positions={positions}
        calendarType={calendarType}
        isEdit={isEdit}
        projectData={projectData}
      />

      <MilestoneGanttDragLayer calendarType={calendarType} />

    </div>
  );
});

export default MilestoneGanttGraph;