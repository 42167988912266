import cx from 'classnames';
import i18n from "i18next";
import { compact, isEmpty } from 'lodash';
import moment from "moment";
import React from 'react';
import { Link } from 'react-router-dom';
import { FORMAT_DATE } from '@/config/const';
import Checkbox from '@/elements/Checkbox';
import { LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";
import {
  WorkWithChecked,
  checkGroup,
  getWorkNameWithProject,
  checkMilestone
} from "@/pages/CreateProject/Blocks/utils";
import { formatDate, formatDays, getDict, getDictObj, isBeforeToday, parseDate } from '@/utils';
import TableVersioned from "@/components/TableVersioned";
import { useAppSelector } from "@/utils/typedHooks";

export const rowClasses = (row: Work) => {
  return checkGroup(row) ? "work-group__item" : undefined;
};

export const dateStartClass = (cell, row, workStatusDict) => {
  if (!cell || !row?.statusId || !workStatusDict?.isLoad || getDictObj(workStatusDict, row.statusId)?.code !== 'PLAN'
    || row.isSystem) {
    return '';
  }

  return moment().diff(parseDate(cell), 'days') > 0 ? 'duration warn' : '';
};

export const dateEndClass = (cell, row, workStatusDict) => {
  if (!cell || !row?.statusId || !workStatusDict?.isLoad || !['WORK', 'SUCCESS',
    'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(getDictObj(workStatusDict, row.statusId)?.code)) {
    return '';
  }

  const dateEndFact = row.dateEndFact ? parseDate(row.dateEndFact) : moment();

  return dateEndFact.diff(parseDate(row.dateEnd), 'days') > 0 ? 'duration warn' : '';
};

const TableResultWorks = ({
  dataFirst, dataSecond = [], dict, projectData, getJiraUrl,
  order, setOrder, onToggleWork, onToggleAll, isShowRemove,
}) => {
  const isHistoryEnabled = useAppSelector(state => state.ProjectHistory[projectData.projectId]?.isEnabled);

  if (isEmpty(projectData)) {
    return null;
  }

  const sortOption = {
    ...order,
    sortFunc: () => 0,
  };

  const isCheckedAll = dataFirst
    .filter(item => (isShowRemove && !item.isExtreme) || item.projectId !== projectData.projectId)
    .every(({ isChecked }) => isChecked);

  const detailColumns = compact([
    {
      dataField: 'id',
      text: '',
      formatExtraData: {
        isShowRemove,
        onToggleWork,
        projectId: projectData.projectId,
      },
      hidden: isHistoryEnabled,
      headerFormatter: () => {
        return (
          <div className="flex-inline-center-vertical">
            {(isShowRemove || dataFirst.some(item => item.projectId !== projectData.projectId)) && (
              <Checkbox
                checked={isCheckedAll}
                onChange={onToggleAll}
                tooltip="Выделить все работы для удаления"
              />
            )}
          </div>
        );
      },
      formatter: (cell, row: WorkWithChecked, _, { isShowRemove, onToggleWork, projectId }) => {
        return (
          <div className="flex-inline-center-vertical">
            {(isShowRemove || row.projectId !== projectId) && !row.isExtreme && (
              <Checkbox
                checked={row.isChecked || false}
                onChange={() => onToggleWork(row.id)}
                tooltip="Выделить работу для удаления"
              />
            )}
            {(isShowRemove || row.projectId !== projectId) && row.isExtreme && <div style={{width: '25px'}}/>}
            {row.rowNum}
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: i18n.t('workDataRequest.name'),
      sort: true,
      onSort: setOrder,
      headerStyle: () => {
        return { minWidth: '250px' };
      },
      formatExtraData: {
        projectId: projectData.projectId,
      },
      formatter: (cell, row: WorkWithChecked, _, { projectId }) => {
        if (!row.name) {
          return null;
        }

        const projectBase = getDictObj(dict.types, row.projectTypeId)?.code.toLowerCase();
        const name = row.projectId === projectId ? row.name : getWorkNameWithProject(row, dict.types);
        return (
          <Link to={`/${projectBase}/${row.projectVersionId}/work/${row.id}`}>
            {name}
          </Link>
        );
      },
    },
    {
      dataField: 'status',
      text: i18n.t('workDataRequest.statusId'),
      sort: true,
      onSort: setOrder,
      versionCellGetter: (row) => row.statusId,
      formatter: (cell, row) => getDict(dict.workStatus, row.statusId),
    },
    {
      dataField: 'responsible',
      text: i18n.t('workDataRequest.responsible'),
      sort: true,
      onSort: setOrder,
      formatter: (cell, row) => row.responsible?.displayName,
    },
    {
      dataField: 'dateStart',
      text: i18n.t('workDataRequest.dateStart'),
      sort: true,
      onSort: setOrder,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        const dateStart = checkMilestone(row) ? row.dateEnd : row.dateStart;
        return (
          <div className={cx(
            dateStartClass(cell, row, workStatusDict),
            checkMilestone(row) ? dateEndClass(cell, row, workStatusDict) : null,
          )}>
            {formatDate(dateStart, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'dateEnd',
      text: i18n.t('workDataRequest.dateEnd'),
      sort: true,
      onSort: setOrder,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        return (
          <div className={cx(
            dateEndClass(cell, row, workStatusDict),
            checkMilestone(row) ? dateStartClass(cell, row, workStatusDict) : null,
          )}>
            {formatDate(cell, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'dateEndFact',
      text: i18n.t('workDataRequest.dateEndFact'),
      sort: true,
      onSort: setOrder,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        return (
          <div className={cx(dateEndClass(cell, row, workStatusDict))}>
            {formatDate(cell, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'dateEndInitial',
      text: i18n.t('workDataRequest.dateEndInitial'),
      sort: true,
      onSort: setOrder,
      formatter: cell => formatDate(cell, FORMAT_DATE)
    },
    {
      dataField: 'duration',
      text: i18n.t('workDataRequest.duration'),
      sort: true,
      onSort: setOrder,
      formatter: (cell, row) => formatDays(checkMilestone(row) ? 0 : cell)
    },
    {
      dataField: 'calendarDuration',
      text: i18n.t('workDataRequest.calendarDuration'),
      sort: true,
      onSort: setOrder,
      formatter: (cell, row) => formatDays(checkMilestone(row) ? 0 : cell)
    },
    {
      dataField: 'dateUpdateStatus',
      text: i18n.t('workDataRequest.dateUpdateStatus'),
      sort: true,
      onSort: setOrder,
      formatter: cell => formatDate(cell, FORMAT_DATE)
    },
    {
      dataField: 'jiraCode',
      text: '',
      formatter: (jiraCode, work) => jiraCode ? (
        <div className="edit-button-felix">
          <a target="blank" href={getJiraUrl(work)} title="Перейти в Jira">
            <LinkIcon className="dashboard-report-link_icon" />
          </a>
        </div>
      ) : null
    },
  ]);

  return (
    <TableVersioned
      keyField='id'
      projectId={projectData.projectId}
      dataFirst={dataFirst}
      dataSecond={dataSecond}
      columns={detailColumns}
      sort={sortOption}
      rowClasses={rowClasses}
      isItemsEqual={(item1, item2) => item1.workId === item2.workId}
    />
  );
};

export default TableResultWorks;
