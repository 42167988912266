import React from 'react';
import { Pagination as BPagination } from 'react-bootstrap';
import { useDeviceContext } from '@/context/DeviceContext';

const MAX_PAGE = 4;
const MAX_PAGE_MOBILE = 2;

const Pagination = ({
  pageInfo,
  onChange,
  className = undefined
}) => {
  const { isMobile } = useDeviceContext();

  if (pageInfo.empty) {
    return null;
  }

  let items = [];
  for (let i = 1; i <= pageInfo.totalPages; i++) {
    items.push(
      <BPagination.Item key={i} active={i === (pageInfo.number + 1)} onClick={() => onChange(i - 1)}>
        {i}
      </BPagination.Item>,
    );
  }

  const maxPage = isMobile ? MAX_PAGE_MOBILE : MAX_PAGE;
  const start = pageInfo.number - maxPage;
  const end = pageInfo.number + maxPage;

  items = items.slice((start < 0 ? 0 : start) + (end > pageInfo.totalPages ? -end : 0),
    end + (start < 0 ? -start : 0));

  items.unshift(<BPagination.Prev key='first' disabled={pageInfo.first} onClick={() => onChange(pageInfo.number - 1)} />)
  items.push(<BPagination.Next key='last' disabled={pageInfo.last} onClick={() => onChange(pageInfo.number + 1)} />)

  return (
    <BPagination className={className}>{items}</BPagination>
  )
}

export default Pagination;
