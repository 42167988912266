import React, { Fragment, useEffect, useState } from 'react';
import services from "@/services";
import { StatusItem } from "@/components/Status/index";
import { useAppSelector } from "@/utils/typedHooks";
import { getDictByCode } from "@/utils";
import Loader from "@/components/Loader";

const getAgreeStageInfo = (projectVersionId) => services.get(`/project/agree/${projectVersionId}/stageInfo`);


const StageStatus = ({ stageInfo, stageCode, title, step }: {
  stageInfo: any,
  stageCode: string,
  title?: string,
  step?: number
}) => {
  const agreeStageDict = useAppSelector(state => state.dict.agreeStage);

  const getColorClass = () => {
    if (stageInfo?.previousStages.includes(stageCode)) {
      return 'finished';
    }
    if (stageInfo?.currentStageCode === stageCode
      || (stageCode === 'COMMITTEE_VOTE' && stageInfo?.currentStageCode === 'COMMITTEE_INTRAMURAL')) {
      if (stageInfo?.isCurrentStageReject) {
        return 'reject';
      }
      return stageInfo?.isCurrentStageFinished ? 'finished' : 'active';
    }
    if (stageInfo?.currentStageCode === 'COMMITTEE_FIRST_QUEUE'
      && !stageInfo?.isCurrentStageReject
      && stageInfo?.isCurrentStageFinished
      && stageCode === 'COMMITTEE_SECOND_QUEUE') {
      return 'active';
    }
    return '';
  }

  const getFullTitle = () => {
    return <div>
      {step && <div>{`${step} шаг`}</div>}
      <div>{title ?? getDictByCode(agreeStageDict, stageCode)?.name}</div>
    </div>
  }

  return <StatusItem className={`project__committee-stage-item committee-agree-stage ${getColorClass()}`}
                     wrap
                     style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                     text={getFullTitle()}/>
}

const CommitteeVoteBarFull = ({ stageInfo }) => {
  return <div className='row'>
    <div className='col-3'><StageStatus step={1} stageInfo={stageInfo} stageCode={'COMMITTEE_FIRST_QUEUE'}/></div>
    <div className='col-3'><StageStatus step={2} stageInfo={stageInfo} stageCode={'COMMITTEE_SECOND_QUEUE'}/></div>
    <div className='col-3'><StageStatus step={3} title={stageInfo?.isIntramural ? 'Очный ПК' : undefined}
                                        stageInfo={stageInfo} stageCode={'COMMITTEE_VOTE'}/></div>
    {!stageInfo?.isIntramural &&
        <div className='col-3'><StageStatus step={4} stageInfo={stageInfo} stageCode={'COMMITTEE_LEADER_VOTE'}/></div>}
  </div>
}

const CommitteeVoteBarSimple = ({ stageInfo }) => {
  return <div className='row'>
    <div className='col-6'><StageStatus title={'Экспертиза'} stageInfo={stageInfo} stageCode={'COMMITTEE_FIRST_QUEUE'}/>
    </div>
    <div className='col-6'><StageStatus title={stageInfo?.directParentType === 'PORTFOLIO'
      ? 'Утверждение Руководителем Портфеля'
      : 'Утверждение Руководителем Программы'} stageInfo={stageInfo} stageCode={'COMMITTEE_PORTFOLIO_LEADER'}/></div>
  </div>
}


const CommitteeVoteBar = ({ stageInfo }) => {
  return <Fragment>
    {stageInfo.isSimpleProfileAgree && <CommitteeVoteBarSimple stageInfo={stageInfo}/>}
    {!stageInfo.isSimpleProfileAgree && <CommitteeVoteBarFull stageInfo={stageInfo}/>}
  </Fragment>
}

const CommitteeVoteStatus = ({ items }) => {
  const projectData = useAppSelector(st => st.NewProject.newProjectData);
  const stageInfo = projectData?.stageInfo;

  const currentStage = items[0]?.value;

  return <Fragment>
    {['REWORK', 'COORDINATION'].includes(currentStage) && <Fragment>
      {stageInfo?.isCommitteeAgree && <CommitteeVoteBar stageInfo={stageInfo}/>}
    </Fragment>}
  </Fragment>
};
export default CommitteeVoteStatus;
