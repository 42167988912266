import React, { useEffect, useState } from "react";
import './index.scss'
import Table from "@/components/Table";
import { cloneDeep, get, isEmpty, isEqual } from "lodash";
import cx from "classnames";
import { useAppSelector } from "@/utils/typedHooks";
import { getVersionHistoryName, isEmptyValues } from "@/utils";
import Tooltip from "@/elements/Tooltip";
import { IProjectHistoryState } from "@/reducers/ProjectHistory";

interface ITableVersionedProps {
  dataFirst: any[];
  dataSecond: any[];
  isItemsEqual: (item1, item2) => boolean;
  keyField: string;
  projectId: number;
  [key: string]: any;
}

const keyPrefix = 'key_';

const TableVersioned = ({
  wrapperClasses = undefined,
  big = false,
  dataFirst,
  dataSecond,
  showCount = false,
  columns = [],
  keyField,
  isItemsEqual,
  projectId,
  ...props
}: ITableVersionedProps, ref) => {
  const projectHistory = useAppSelector(state => state.ProjectHistory[projectId] || ({} as IProjectHistoryState));

  const [mergedData, setMergedData] = useState([]);
  const [editedColumns, setEditedColumns] = useState([]);
  const [newKeyField, setNewKeyField] = useState(keyPrefix + keyField);

  useEffect(() => {
    setNewKeyField(keyPrefix + keyField)
  }, [keyField]);

  //Объединим обе версии в одну
  useEffect(() => {
    let allData = [];
    const dataFirstNew = cloneDeep(dataFirst);
    const dataSecondNew = cloneDeep(dataSecond);

    allData = allData.concat(dataFirstNew?.map(item => {
      const itemSecond = dataSecondNew?.find(itemVersion => isItemsEqual(item, itemVersion));
      if (itemSecond) {
        itemSecond.isExtractedForMerge = true;
      }
      Object.keys(item).forEach(key => {
        if (key === keyField) {
          item[newKeyField] = item[key];
        }

        //Такая сложная конструкция для ререндера колонок, которые всегда row[dataField] = undefinied,
        //нужна смена значения для ререндера колонки, т.к. могут измениться classNames
        const valueFirst = isEmptyValues(item[key]) ? null : item[key];
        const valueSecond = projectHistory.isEnabled ? (isEmptyValues(itemSecond?.[key]) ? null : itemSecond?.[key]) : undefined;
        item[key] = [valueFirst, valueSecond];
      });
      return item;
    }));

    if (projectHistory.isEnabled) {
      allData = allData.concat(dataSecondNew?.filter(item => !item.isExtractedForMerge).map(item => {
        Object.keys(item).forEach(key => {
          if (key === keyField) {
            item[newKeyField] = item[key];
          }

          const value = item[key] || null;
          item[key] = [undefined, value];
        });

        return item;
      }));
    }

    setMergedData(allData);
  }, [dataFirst, dataSecond, newKeyField, projectHistory]);

  //Перепишем форматтер, так чтобы он понял что у нас в одной строке данные из разных версий
  useEffect(() => {
    //Определяем какие данные должны быть в первой строчке (предыдущая версия должна быть сверху (было), следующая снизу (стало))
    const isFirstVersionInTop = !projectHistory.isEnabled || projectHistory.firstVersionId <= projectHistory.secondVersionId;
    const firstVersionName = getVersionHistoryName(projectHistory.versions
      ?.find(item => item.projectVersionId === (projectHistory.isEnabled
        ? (isFirstVersionInTop ? projectHistory.firstVersionId : projectHistory.secondVersionId)
        : null)));
    const secondVersionName = getVersionHistoryName(projectHistory.versions
      ?.find(item => item.projectVersionId === (projectHistory.isEnabled
        ? (isFirstVersionInTop ? projectHistory.secondVersionId : projectHistory.firstVersionId)
        : null)));

    const columnsNew = cloneDeep(columns.filter(col => !col.hidden));
    columnsNew?.forEach(col => {
      const formatter = col.formatter || ((cell) => <div>{cell || ''}</div>);
      col.formatter = (cell, row, index, extraData) => {
        //Нормализуем строки
        const normalizedRow1 = normalizeRow(row, isFirstVersionInTop ? 0 : 1);
        const normalizedRow2 = normalizeRow(row, isFirstVersionInTop ? 1 : 0);

        //Вытащим данные текущей ячейки
        //versionCellGetter кастомное поле у columns, необходимо в случаях когда в dataField не то что нужно
        const value1 = col.versionCellGetter ? col.versionCellGetter(normalizedRow1, index) : get(normalizedRow1, col.dataField.split('.'));
        const value2 = col.versionCellGetter ? col.versionCellGetter(normalizedRow2, index) : get(normalizedRow2, col.dataField.split('.'));

        //Форматируем ячейку тем форматтером, который указан в columns
        const formattedValue1 = formatter(value1, normalizedRow1, index, extraData);
        const formattedValue2 = formatter(value2, normalizedRow2, index, extraData);

        const isValueNotEquals = (isEmptyValues(value1) && isEmptyValues(value2)) || !isEqual(value1, value2);
        const isFormattedValueNotEquals = !isEqual(formattedValue1, formattedValue2);
        const hasDiff = projectHistory.isEnabled && isValueNotEquals && isFormattedValueNotEquals;
        const diffClass = hasDiff ? (!value1 && value2 ? 'append' : 'different') : '';

        return hasDiff ? (
          <>
            <Tooltip text={firstVersionName} placement='top'>
              <div className={cx('first', diffClass)}>
                {formattedValue1}
              </div>
            </Tooltip>
            <Tooltip text={secondVersionName} placement='top'>
              <div className={cx('second', diffClass)}>
                {formattedValue2}
              </div>
            </Tooltip>
          </>
        ) : (
          <div className={cx('first', diffClass)}>
            {formattedValue1}
          </div>
        );
      }
    });
    setEditedColumns(columnsNew);
  }, [columns, dataFirst, dataSecond, projectHistory]);

  //Возвращает строку с данными только одной версии, по индексу версии
  const normalizeRow = (row, idx) => {
    if (!row) {
      return row;
    }

    const newRow = cloneDeep(row);
    Object.keys(newRow).forEach(key => {
      newRow[key] = newRow[key]?.[idx];
    });
    return newRow;
  }

  if (isEmpty(editedColumns)) {
    return null;
  }

  return (
    <Table
      keyField={newKeyField}
      data={mergedData}
      columns={editedColumns}
      {...props}
      ref={ref}
    />
  )
}

export default React.forwardRef(TableVersioned);