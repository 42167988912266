import React from 'react';

interface IExtendableWrapperProps extends IExtendableProps {
  children: React.ReactNode;
  props?: any;
}

const ExtendableWrapper = (props: IExtendableWrapperProps) => {
  const Before = props?.before;
  const Replace = props?.replace;
  const After = props?.after;
  return (
    <>
      {Before && <Before {...props.props}/>}
      {Replace === undefined ? props?.children : <Replace {...props.props}/>}
      {After && <After {...props.props}/>}
    </>
  )
}

export default ExtendableWrapper;