import React from 'react';
import Spoiler from '@/components/Spoiler';
import TableDiff from "@/pages/CreateProject/Agreement/Block/TableDiff";
import cx from "classnames";

interface ISpoilerTableDiffProps {
  title: string;
  dataTitle: string;
  data: any;
  isDiff?: boolean;
  isOnlyUpdate?: boolean;
  defaultSpoilerState?: boolean;
  isItemEmpty?: boolean;
  isFirstAgree?: boolean
  isSubDataHasDiff?: boolean
}

const SpoilerTableDiff = ({
  title,
  dataTitle,
  data,
  isDiff,
  isOnlyUpdate,
  defaultSpoilerState,
  isItemEmpty,
  isFirstAgree = undefined,
  isSubDataHasDiff,
}: ISpoilerTableDiffProps) => {
  if (isItemEmpty) {
    return null;
  }

  const spoilerTitle = dataTitle
    ? (
      <TableDiff
        data={dataTitle}
        isDiff={isDiff}
        isOnlyUpdate={isOnlyUpdate}
        isSubDataHasDiff={isSubDataHasDiff}
        isFirstAgree={isFirstAgree}
      />
    )
    : title;

  return (
    <React.Fragment>
      <Spoiler title={spoilerTitle} defaultState={defaultSpoilerState}
               titleClassName={cx(
                 { 'data-title' : dataTitle },
                 { 'version-block-item-title': !dataTitle },
                 { 'version-block-item-title-color': !dataTitle },
                 { 'first-agree': isFirstAgree })}>
        { isDiff ? (
          <TableDiff
            data={data}
            isDiff={isDiff}
            isOnlyUpdate={isOnlyUpdate}
            isFirstAgree={isFirstAgree}
          />
        ) : (
          <TableDiff data={data} isFirstAgree={isFirstAgree}/>
        ) }

      </Spoiler>
    </React.Fragment>
  );
}

export default SpoilerTableDiff;
