import React from 'react';
import {
  Chart, PieSeries, Tooltip,
} from '@devexpress/dx-react-chart-bootstrap4';
import { EventTracker, Palette } from '@devexpress/dx-react-chart';
import { TemplateConnector } from '@devexpress/dx-react-core';

const PieContentTooltip = (props) => (
  <TemplateConnector>
    {({ data, palette }) => {
      const index = props.targetItem.point;
      const item = data[index];
      const color = palette[index];

      return (
        <div className="stat-pie stat-pie__tooltip">
          <div className="stat-pie__cnt__container">
            <div className="stat-pie__procent dashboard-status__diff parent-color" style={{ color }}>
              {item.value}%
            </div>
          </div>

          <div className="stat-pie__title">
            {item.label}
          </div>
        </div>
      );
    }}
  </TemplateConnector>
);

const Pie = ({ data, colors }) => {
  return (
    <Chart
      data={data}
      height={160}
    >
      <Palette scheme={colors} />
      <PieSeries
        valueField="value"
        argumentField="label"
        innerRadius={0.3}
        name="Процент выполнения проекта"
      />
      <EventTracker />
      <Tooltip contentComponent={PieContentTooltip} />
    </Chart>
  )
}

const ProjectEndProcent = ({ value = 0 }) => {
  const data = [
    {
      value: value,
      label: 'Завершено',
      color: '#66e59b',
    },
    {
      value: 100 - value,
      label: 'Не завершено',
      color: '#ffd36b',
    },
  ]

  return (
    <div className="project-status-procent__container">
      <div className="project-status-procent__title text-center">
      Процент выполнения проекта: {value}%
      </div>
      <Pie data={data} colors={data.map(({ color }) => color)} />
    </div>
  )
}

export default ProjectEndProcent;
