import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localizationResources from '@/config/localizationResources';
import service from '@/services';

service.get('/dict/l10n')
  .then(l10n => i18n.addResourceBundle('BACKEND', 'translation', l10n, true, true));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: localizationResources,
    lng: 'PROJECT',
    fallbackLng: {
      'PROJECT': ['SYSTEM', 'BACKEND'],
      'default': ['PROJECT', 'SYSTEM', 'BACKEND'],
    },
    interpolation: {
      escapeValue: false
    }
  });
