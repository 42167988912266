import i18n from "i18next";
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '@/pages/CreateProject/BasicNav/Icon';
import Nav from '@/pages/CreateProject/BasicNav/Nav';
import { useAppSelector } from '@/utils/typedHooks';
import { prepareNavItems } from '@/pages/CreateProject/BasicNav/navItems';
import { useCheckRight } from '@/utils/hooks';

const BasicNav = () => {
  const { pathname } = useLocation();
  const checkRight = useCheckRight();
  const [projectData, dict] = useAppSelector(state => [
    state.NewProject.newProjectData,
    state.dict,
  ]);
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    prepareNavItems(projectData, dict, checkRight).then(setNavItems);
  }, [projectData, dict]);

  const isCreatePage = new RegExp('^/(project|program|portfolio)/create/?').test(pathname);
  if (isCreatePage) {
    return (
      <Nav
        data={[
          {
            key: 'create',
            title: "Создать",
            url: `/${i18n.t('base')}/create`,
            icon: Icon.ProjectCreate,
          }
        ]}
        isShow={true}
      />
    );
  }

  return (
    <Nav data={navItems} isShow={projectData.id !== undefined} />
  );
};

export default BasicNav;
