export const modalShow = (message, options = {}) => (dispatch) => {
  dispatch({
    type: 'MODAL_SHOW',
    payload: {
      message,
      options
    },
  });
};

export const modalHide = () => (dispatch) => {
  dispatch({
    type: 'MODAL_HIDE',
  });
};

export const incLoad = () => (dispatch) => {
  dispatch({
    type: 'LOAD_INC',
  });
};

export const decLoad = () => (dispatch) => {
  dispatch({
    type: 'LOAD_DEC',
  });
};
