import i18n from "i18next";

import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import { formatDate, formatDays, getDict, getDictObj } from '@/utils';

const AgreementWorkBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.work) {
        return [];
      }

      return (data.work as Work[]).filter(item => !checkGroup(item)).map(item => {
        const controlPoint = getDictObj(self.props.dict.projectWorkControlPoint, item.controlPointId);
        let controlPointTypeName = '';
        let controlPointSubTypeName = '';
        if (controlPoint) {
          if (controlPoint.parent) {
            controlPointTypeName = controlPoint.parent.name;
            controlPointSubTypeName = controlPoint.name;
          } else {
            controlPointTypeName = controlPoint.name;
          }
        }

        return {
          title: item.name,
          data: [
            {
              title: i18n.t('workDataRequest.responsible'),
              data: item.responsible?.displayName
            },
            {
              title: i18n.t('workDataRequest.controlPointTypeName'),
              data: controlPointTypeName
            },
            {
              title: i18n.t('workDataRequest.controlPointSubTypeName'),
              data: controlPointSubTypeName
            },
            {
              title: i18n.t('workDataRequest.statusId'),
              data: getDict(self.props.dict.workStatus, item.statusId)
            },
            {
              title: i18n.t('workDataRequest.dateStart'),
              data: formatDate(item.dateStart)
            },
            {
              title: i18n.t('workDataRequest.dateEnd'),
              data: formatDate(item.dateEnd)
            },
            {
              title: i18n.t('workDataRequest.dateEndFact'),
              data: formatDate(item.dateEndFact)
            },
            {
              title: i18n.t('workDataRequest.duration'),
              data: formatDays(item.duration)
            },
            {
              title: i18n.t('workDataRequest.results'),
              data: item.results
            },
            {
              title: i18n.t('workDataRequest.weight'),
              data: item.weight
            },
            {
              title: i18n.t('workDataRequest.progress.nameNaturalIndex'),
              data: item.nameNaturalIndex
            },
            {
              title: i18n.t('workDataRequest.progress.plannedValue'),
              data: item.plannedValue
            },
            {
              title: i18n.t('workDataRequest.progress.factValue'),
              data: item.factValue
            },
          ]
        }
      });
    }
  },
]);

export default AgreementWorkBlock;
