import cx from 'classnames';
import React from 'react';

import AcceptDelete from '@/components/Helper/AcceptDelete';
import ButtonRt from '@/components/Helper/ButtonRt';
import { prevent } from '@/utils';
import DirtyFormModal from "@/elements/DirtyFormModal";

function SaveCancel({
  disabled = false,
  disabledSave = false,
  save,
  cancel = undefined,
  remove = undefined,
  saveLabel = "Сохранить",
  cancelLabel = "Отменить",
  removeLabel = "Удалить",
  removeMessage = undefined,
  className = undefined,
  children = undefined,
  childrenLast = undefined,
  childrenMiddle = undefined,
  isDirty = false,
  setIsDirty = undefined,
  saveFromModal = undefined,
  preventRedirectOnSave = undefined,
  funcRef = undefined
}) {

  const onRemove = (e) => {
    return remove(e);
  };

  return (
    <div className={cx("mb-3 form-group button-felix-group button-group", className)}>
      {children}
      {save && (
        <ButtonRt
          disabled={disabledSave || disabled}
          onClick={(e) => {
            if (setIsDirty) {
              setIsDirty(false);
            }
            return prevent(save)(e);
          }}
          className="margin-between-12"
        >{saveLabel}</ButtonRt>
      )}
      {childrenMiddle}
      {cancel && (
        <ButtonRt
          disabled={disabled}
          onClick={(e) => {
            if (setIsDirty) {
              setIsDirty(false);
            }
            return prevent(cancel)(e);
          }}
          type="outline"
          className="margin-between-12"
        >{cancelLabel}</ButtonRt>
      )}
      {remove && (
        <AcceptDelete
          className="margin-between-12"
          disabled={disabled}
          type="outline"
          onOk={(e) => {
            if (setIsDirty) {
              setIsDirty(false);
            }
            return onRemove(e);
          }}
        >{removeLabel}</AcceptDelete>
      )}
      {childrenLast}
      <DirtyFormModal isDirty={isDirty} onSave={saveFromModal
        ? async () => {
          if (setIsDirty) {
            setIsDirty(false);
          }
          await saveFromModal();
        }
        : async () => await save()}
      preventRedirectOnSave={preventRedirectOnSave}
      funcRef={funcRef}/>
    </div>

  );
}

export default SaveCancel;
