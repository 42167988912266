import './style.scss';
import React from 'react';
import cx from "classnames";
import { get } from "lodash";
import { isEmptyValues } from "@/utils";

interface ITableDiffProps {
  data: any;
  isOnlyUpdate?: boolean;
  isDiff?: boolean;
  isSubDataHasDiff?: boolean;
  isFirstAgree?: boolean;
}

const TableDiff = ({ isOnlyUpdate, isDiff, data, isSubDataHasDiff, isFirstAgree }: ITableDiffProps) => {
  if (isOnlyUpdate && data.length === 0) {
    return null;
  }

  const getDiffClass = (item1, item2) => {
    if (item1 == item2) { // eslint-disable-line eqeqeq
      return '';
    }

    if (isEmptyValues(item1) && !isEmptyValues(item2)) {
      return 'append';
    }

    if (item1 != item2) { // eslint-disable-line eqeqeq
      return 'different';
    }
  }

  return (
    <React.Fragment>
      <div>
        <table
          className={cx('table-diff', 'no-bottom-border', 'table-diff-design', { 'first-agree': isFirstAgree })}>
          <tbody>
          {data.map((item, i) => {
            const diffClass = isDiff && getDiffClass(item.data[0], item.data[1]);
            return (
              <tr key={`${item.title}-${i}`} className='table-diff-row'>
                <td className={cx('title', {red: isDiff && !diffClass && isSubDataHasDiff})}>{item.title}</td>
                { isDiff ? (
                  <>
                    <td className={cx(
                      'data',
                      'first',
                      'break-all',
                      diffClass,
                      get(item, '_raw.item1.className')
                    )}>{item.data[0]}</td>

                    <td className={cx(
                      'data',
                      'second',
                      'break-all',
                      diffClass,
                      get(item, '_raw.item2.className')
                    )}>{item.data[1]}</td>
                  </>
                ) : (
                  <td className='data'>{item.data[0]}</td>
                )}
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default TableDiff;
