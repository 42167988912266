import { formatDate } from '@/utils'
import i18n from "i18next";
import { FORMAT_DATE } from "@/config/const";

const MilestoneBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.milestone) {
        return []
      }

      return data.milestone.map(item => ({
        key: item.milestoneId,
        title:item.name,
        data: [
          {
            key: 'datePlan',
            title: i18n.t('ProjectMilestoneWeb.datePlanBase'),
            data: formatDate(item.datePlanBase, FORMAT_DATE, null)
          },
          {
            key: 'datePlan',
            title: i18n.t('ProjectMilestoneWeb.datePlan'),
            data: formatDate(item.datePlan, FORMAT_DATE, null)
          },
          {
            key: 'dateFact',
            title: i18n.t('ProjectMilestoneWeb.dateFact'),
            data: formatDate(item.dateFact, FORMAT_DATE, null)
          },
          {
            key: 'responsible',
            title: i18n.t('ProjectMilestoneWeb.responsible'),
            data: item.responsible?.displayName
          },
          {
            key: 'comment',
            title: i18n.t('ProjectMilestoneWeb.comment'),
            data: item.comment
          },
        ]
      }))
    }
  },
]);

export default MilestoneBlock;
