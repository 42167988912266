import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'; 

const SMALL_DESKTOP_BREAKPOINT = 1200;
const TABLET_BREAKPOINT = 768;
const MOBILE_BREAKPOINT = 600;
const SMALL_DESKTOP_QUERY_STRING = `(max-width: ${SMALL_DESKTOP_BREAKPOINT}px) and (min-width: ${TABLET_BREAKPOINT + 1}px)`;
const TABLET_MEDIA_QUERY_STRING = `(max-width: ${TABLET_BREAKPOINT}px) and (min-width: ${MOBILE_BREAKPOINT + 1}px)`;
const MOBILE_MEDIA_QUERY_STRING = `(max-width: ${MOBILE_BREAKPOINT}px)`;

export const DeviceContext = createContext({
  isDesktop: true,
  isSmallDesktop: false,
  isTablet: false,
  isMobile: false,
});
export const useDeviceContext = () => useContext(DeviceContext);

const DeviceContextProvider = ({ children }) => {
  const supportMatchMedia = typeof window.matchMedia !== 'undefined';
  const matchMedia: (query: string) => MediaQueryList = useMemo<any>(
    () => supportMatchMedia ? window.matchMedia : () => ({ matches: false }
  ), [supportMatchMedia]);

  const [isSmallDesktop, setIsSmallDesktop] = useState(matchMedia(SMALL_DESKTOP_QUERY_STRING).matches);
  const [isTablet, setIsTablet] = useState(matchMedia(TABLET_MEDIA_QUERY_STRING).matches);
  const [isMobile, setIsMobile] = useState(matchMedia(MOBILE_MEDIA_QUERY_STRING).matches);
  const isDesktop = !isTablet && !isMobile;

  useEffect(() => {
    if (!supportMatchMedia) return;
    const smallDesktopMediaQueryList = matchMedia(SMALL_DESKTOP_QUERY_STRING);
    const tabletMediaQueryList = matchMedia(TABLET_MEDIA_QUERY_STRING);
    const mobileMediaQueryList = matchMedia(MOBILE_MEDIA_QUERY_STRING);

    const updateSmallDesktopMatch = () => setIsSmallDesktop(smallDesktopMediaQueryList.matches);
    const updateTabletMatch = () => setIsTablet(tabletMediaQueryList.matches);
    const updateMobileMatch = () => setIsMobile(mobileMediaQueryList.matches);

    smallDesktopMediaQueryList.addEventListener('change', updateSmallDesktopMatch);
    tabletMediaQueryList.addEventListener('change', updateTabletMatch);
    mobileMediaQueryList.addEventListener('change', updateMobileMatch);
    return () => {
      smallDesktopMediaQueryList.removeEventListener('change', updateSmallDesktopMatch);
      tabletMediaQueryList.removeEventListener('change', updateTabletMatch);
      mobileMediaQueryList.removeEventListener('change', updateMobileMatch);
    }
  }, [matchMedia, supportMatchMedia]);

  return (
    <DeviceContext.Provider value={{ isDesktop, isSmallDesktop, isTablet, isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceContextProvider;