import React from 'react';
import ModalAsync from '@/components/ModalAsync';
import { FORMAT_DATE_HUMAN } from '@/config/const'

import service from '@/services'
import { formatDateWithFormat } from '@/utils'

import Pagination from '@/elements/Pagination';
import Table from '@/components/Table';

const detailColumns = [
  {
    dataField: 'regionName',
    text: 'Субьект РФ',
  },
  {
    dataField: 'workName',
    text: 'Работа',
  },
  {
    dataField: 'address',
    text: 'Адрес/Объект',
  },
  {
    dataField: 'dateStart',
    text: 'Дата начала',
    formatter: cell => formatDateWithFormat(cell, FORMAT_DATE_HUMAN),
  },
  {
    dataField: 'dateEndPlan',
    text: 'Дата выполнения (план)',
    formatter: cell => formatDateWithFormat(cell, FORMAT_DATE_HUMAN),
  },
  {
    dataField: 'dateEndFact',
    text: 'Дата выполнения (факт)',
    formatter: cell => formatDateWithFormat(cell, FORMAT_DATE_HUMAN),
  },
  {
    dataField: 'isSuccess',
    text: 'Признак выполнения',
    formatter: cell => cell ? 'Выполнена' : 'В работе',
  },

];

const defaultState = {
  data: {
    content: [],
    number: 0,
    size: 300,
    sortBy: 'address,work.id'
  },
  isLoading: false
}

export const normalizeSkupToServer = (data) => data;

export default class SkypFormProjectListModal extends React.Component<any, any> {
  modalRef: any;

  constructor(props) {
    super(props);

    this.state = defaultState;

    this.modalRef = React.createRef();
  }

  loadData() {
    if (this.state.isLoading) {
      return;
    }
    this.setState({
      isLoading: true
    })
    return service.post('/skup/list', {
      ...normalizeSkupToServer(this.props.data),
      pageable: {
        size: this.state.data.size,
        page: this.state.data.number + 1,
        sortBy: this.state.data.sortBy
      }
    }).then(data => {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          ...data
        },
        isLoading: false
      }))
    })
  }

  componentDidUpdate(_, nextState) {
    if (!this.modalRef.current?.state?.isShow) {
      return;
    }

    if (this.state.data.number !== nextState.data.number) {
      this.loadData();
    }
  }

  changePage = (page) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        number: page
      }
    }))
  }

  open() {
    this.loadData();
    this.modalRef.current.open().then(() => {
      this.setState(defaultState)
    });
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <ModalAsync
        title="Выборка данных из СКУП"
        ref={this.modalRef}
        okTitle="Закрыть"
        size="big"
        isAlert={true}
      >
        <Table
          wrapperClasses="skup-list-modal__container input-title__container custom-scrollbar"
          keyField='id'
          data={data.content || []}
          columns={detailColumns}
          noDataIndication={isLoading ? 'Загрузка...' : "Данные не найдены"}
        />
        { data.totalPages > 1 && <Pagination pageInfo={data} onChange={this.changePage} /> }
      </ModalAsync>
    );
  }
}
