import cx from 'classnames';
import i18n from "i18next";
import { compact, isEmpty, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import ButtonRt from '@/components/Helper/ButtonRt';
import Spoiler from '@/components/Spoiler';
import { userSelect } from '@/components/UserSearch';
import { useDeviceContext } from '@/context/DeviceContext';
import Input from '@/elements/Input';
import DateRangeSelect from '@/elements/Input/DateRangeSelect';
import Select from '@/elements/Select';
import { prevent } from "@/utils";

const FilterMilestone = ({ value, onChange, milestones, defaultFilter }) => {
  const { isDesktop } = useDeviceContext();
  const [users, setUsers] = useState([]);
  const [ spoiler, setSpoiler ] = useState(false);
  const setFilterByKey = (key, val) => {
    onChange({
      ...value,
      [key]: val
    });
    setSpoiler(true);
  };

  const clearFilter = () => {
    onChange({ ...defaultFilter });
  };

  useEffect(() => {
    if (isEmpty(milestones) || !isEmpty(users)) {
      return;
    }

    const data = uniqBy(compact(milestones.map(item => item.responsible)).map(user => userSelect(user)), 'label');
    setUsers(data);
  }, [milestones]);

  const spoilerTitle = (
    <div>
      Фильтр
      {isDesktop && (
        <button className="link dashboard__card-title__reset" onClick={prevent(() => clearFilter())}>
          Сбросить
        </button>
      )}
    </div>
  );

  const spoilerOpen = () => {
    return spoiler || !!(value.name || value.responsible
      || (value.datePlan?.from && value.datePlan?.to)
      || (value.dateFact?.from && value.dateFact?.to));
  }

  return (
    <Spoiler
      title={spoilerTitle}
      defaultState={spoilerOpen()}
      className={cx('filter', 'workList_filters')}
      contentClassName="workList_filters__content"
      titleClassName="workList_filters__title"
    >
      <div className={cx('row', 'workList_filters__container')}>
        <div className={cx('col-md-6', 'workList_filters__item')}>
          <Input
            label={i18n.t('ProjectMilestoneWeb.name')}
            value={value.name}
            onChange={val => setFilterByKey('name', val.target.value)}
            isClearable={true}
          />
        </div>

        <div className={cx('col-md-6', 'workList_filters__item')}>
          <Select
            label={i18n.t('ProjectMilestoneWeb.responsible')}
            value={value.responsible}
            onChange={(val) => setFilterByKey('responsible', val)}
            options={users}
            isClearable={true}
          />
        </div>

        <div className={cx('col-md-6', 'workList_filters__item')}>
          <DateRangeSelect
            label={i18n.t('ProjectMilestoneWeb.datePlan')}
            value={value.datePlan}
            onChange={(val) => setFilterByKey('datePlan', val)}
            isClearable={true}
          />
        </div>

        <div className={cx('col-md-6', 'workList_filters__item')}>
          <DateRangeSelect
            label={i18n.t('ProjectMilestoneWeb.dateFact')}
            value={value.dateFact}
            onChange={(val) => setFilterByKey('dateFact', val)}
            isClearable={true}
          />
        </div>

        {!isDesktop && (
          <div className={cx('col-md-6', 'workList_filters__item')}>
            <ButtonRt
              type="outline"
              onClick={prevent(() => clearFilter())}
              className="workList_filters__clear"
            >
              Сбросить
            </ButtonRt>
          </div>
        )}
      </div>
    </Spoiler>
  );
};

export default FilterMilestone;
