import InputTitle from '@/components/InputTitle'
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'classnames';
import { prevent } from '@/utils';

const TextareaWithClear = React.forwardRef(({
  isClearable,
  onChange,
  ...props
}: any, ref) => {
  return (
    <div className="input-rt__container">
      <TextareaAutosize
        {...props}
        ref={ref}
        onChange={onChange}
      />
      {isClearable && (props.value || '') && (
        <div className="input-rt__indicators">
          <div onClick={prevent(() => onChange({ target: { value: '' } }, ''))} aria-hidden="true" className="select-rt__indicator select-rt__clear-indicator">
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="select-rt__clear-indicator-svg">
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  )
})

const TextArea = ({
  titleClassName,
  clearable = false,
  titleMultiline = false,
  hasError = false,
  isHide = false,
  ...props
}: {
  titleClassName?: string;
  clearable?: boolean;
  titleMultiline?: boolean;
  onChange: (e: any, val: string) => void;
  isHide?: boolean,
  [key: string]: any
}) => {
  if (isHide) {
    return null;
  }

  const onChange = (e) => props.onChange(e, e.target.value);
  const {tooltip, ...textAreaProps} = props;

  return (
    <InputTitle
      required={props.required}
      isClearable={clearable}
      tooltip={props.tooltip}
      label={props.label}
      value={props.value}
      hasError={props.hasError}
      className={cx(titleClassName, 'input-textarea-tooltip', { 'input-textarea': titleMultiline })}
      description={props.description}
    >
      <TextareaWithClear
        {...textAreaProps}
        isClearable={clearable}
        onChange={onChange}
        value={props.value || ''}
        className={cx(props.className, 'input-rt', { clearable,  'with-tooltip': props.required || props.tooltip})}
      />
    </InputTitle>
  );
}

export default TextArea;
