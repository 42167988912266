import { uniq } from "lodash";
import React, { useEffect, useState } from "react";
import MonthYearPicker from "@/components/MonthYearPicker";
import ProjectSearch from "@/components/ProjectSearch";
import UserSearch from "@/components/UserSearch";
import Select from "@/elements/Select";
import services from "@/services";
import { getDictForSelect, getDictIdByCode } from "@/utils";
import { useAppSelector } from "@/utils/typedHooks";
import { reportType } from ".";
import SwitchSliderTitle from '@/elements/SwitchSliderTitle';
import StateSaver from "@/components/StateSaver";

const getPeriodList = () => services
  .get(`/consolidated-report/employees-load/load-year`)
  .then(data =>
    uniq([
      ...data,
      new Date().getFullYear()
    ]).sort()
      .map(i => ({
        label: i,
        value: i
      }))
  );

const TeamLoadFilter = ({
  data,
  setData,
  handleChangeSelect,
}) => {
  const [periodList, setPeriodList] = useState([]);
  const [rolesDict, firedModeDict] = useAppSelector(state => [state.dict.roles,
    state.dict.searchFiredMode]);

  const rolesOptions
    = getDictForSelect(rolesDict, undefined, (role) => role.typeCode === 'PROJECT');

  const searchFiredModeOptions = getDictForSelect(firedModeDict);

  useEffect(() => {
    getPeriodList().then(setPeriodList);
  }, [setPeriodList]);

  const setYear = (value) => {
    setData({
      year: value,
      monthYear: {
        from: null,
        to: null
      }
    });
  };

  const setMonthYear = (value) => {
    setData({
      year: undefined,
      monthYear: value
    });
  };

  return (
    <div className="row consolidate-report-filter-item">
      <div className="col-lg-6">
        <Select
          options={periodList}
          label="Год"
          value={data.year}
          name="year"
          onChange={setYear}
          isClearable={true}
          isMulti
        />
      </div>
      <div className="col-lg-6">
        <MonthYearPicker
          label="Период загрузки"
          value={data.monthYear}
          onChange={setMonthYear}
          isClearable
        />
      </div>
      <div className="col-lg-6">
        <UserSearch
          onChange={(val) => handleChangeSelect('userLogin', val)}
          value={data.userLogin}
          label="Сотрудник"
          name="userLogin"
          isClearable={true}
        />
      </div>
      <div className="col-lg-6">
        <Select
          className="change-role-selector__select"
          options={rolesOptions}
          label="Роль"
          value={data.role}
          isClearable={true}
          onChange={(val) => handleChangeSelect('role', val)}
        />
      </div>
      <div className="col-lg-6">
        <ProjectSearch
          onChange={(val) => handleChangeSelect('project', val)}
          value={data.project}
          label="Поиск по названию карточки"
          name="project"
          isClearable={true}
          showClosed
        />
      </div>
      <div className="col-lg-6">
        <Select
          options={reportType}
          label="Тип отчёта"
          value={data.reportType}
          name="reportType"
          isClearable={false}
          onChange={(val) => handleChangeSelect('reportType', val)}
        />
      </div>
      <div className="col-lg-6">
        <Select
          className="change-role-selector__select"
          options={searchFiredModeOptions}
          label="Показать уволенных"
          value={data.searchFiredModeId || getDictIdByCode(firedModeDict, 'SHOW_ALL')}
          isClearable={true}
          onChange={(val) => handleChangeSelect('searchFiredModeId', val)}
        />
      </div>
      <div className="col-lg-6">
        <SwitchSliderTitle
          title="Не показывать закрытые"
          checked={data.isHideClosed}
          onChange={(val) => handleChangeSelect('isHideClosed', val)}
        />
      </div>
      <div className="col-lg-6">
        <SwitchSliderTitle
          title="Показывать только согласованные карточки"
          checked={data.isOnlyRelease}
          onChange={(val) => handleChangeSelect('isOnlyRelease', val)}
        />
      </div>
      <div className="col-lg-6">
        <SwitchSliderTitle
          title="Показывать строки без загрузки"
          checked={data.isShowNoLoad}
          onChange={(val) => handleChangeSelect('isShowNoLoad', val)}
        />
      </div>
      <div className="col-lg-6">
        <SwitchSliderTitle
          title='Брать загрузку из версии "На согласовании"'
          checked={data.isCoordinationPriority}
          onChange={(val) => handleChangeSelect('isCoordinationPriority', val)}
          disabled={data.isOnlyRelease}
        />
      </div>
      <StateSaver outerState={data} setSaved={setData}/>
    </div>
  );
};

export default TeamLoadFilter;