import cx from 'classnames';
import i18n from "i18next";
import moment from 'moment';
import React, { useEffect } from 'react';
import DateRangeSelect from '@/elements/Input/DateRangeSelect';
import TextArea from '@/elements/TextArea';
import {
  checkGroup,
  checkMilestone,
  checkTask,
  getCalendarDuration,
  getDuration
} from '@/pages/CreateProject/Blocks/utils';
import {  momentToSelectDate, selectDateToMoment } from '@/utils';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';
import InputNum from '@/elements/Input/InputNum';
import { isEmpty } from 'lodash';

interface ITimingFormProps {
  required?: boolean | string;
  data: Work;
  setData: (Work) => void;
  isProjectActive: boolean;
  workStatusCode: string;
  handleChangeSelect: any;
  projectDateStart: any;
  projectDateEnd: any;
  updateIsDirty: (boolean) => void;
}

const TimingForm = ({
  required,
  data,
  setData,
  isProjectActive,
  workStatusCode,
  handleChangeSelect,
  projectDateStart,
  projectDateEnd,
  updateIsDirty,
}: ITimingFormProps) => {
  const weekendUtil = useWeekendUtil();

  const isTask = checkTask(data);
  const isMilestone = checkMilestone(data);
  const isGroup = checkGroup(data);

  useEffect(() => {
    if (!isProjectActive) {
      const minDate = getMinDate();
      if (minDate && selectDateToMoment(minDate).isAfter(selectDateToMoment(data.dateStart))) {
        dateChange(minDate, data.dateEnd);
      }
    }
  }, [isProjectActive, data.dateStart, data.dateEnd]);

  useEffect(() => {
    if (isMilestone) {
      setData({
        dateStart: data.dateEnd,
      });
    }
  }, [data.typeId]);

  const dateChange = (dateStart, dateEnd) => {
    setData({
      dateStart: isMilestone ? dateEnd : dateStart,
      dateEnd,
      duration: getDuration({ dateStart, dateEnd }, weekendUtil),
      calendarDuration: getCalendarDuration({ dateStart, dateEnd }),
    });
    if (updateIsDirty) {
      updateIsDirty(true);
    }
  };

  const durationChange = (val, sourceInfo) => {
    if (!sourceInfo.event) {
      return;
    }

    if (val) {
      const newDateEnd = weekendUtil.getDateEnd(selectDateToMoment(data.dateStart), val, false);
      if (!data.dateEnd || !selectDateToMoment(data.dateEnd).isSame(newDateEnd, 'day')) {
        dateChange(data.dateStart, momentToSelectDate(newDateEnd));
      }
    } else {
      if (data.dateEnd) {
        dateChange(data.dateStart, null);
      }
    }
  }

  const calendarDurationChange = (val, sourceInfo) => {
    if (!sourceInfo.event) {
      return;
    }

    if (val) {
      const newDateEnd = selectDateToMoment(data.dateStart).add(val - 1, 'days');
      if (!data.dateEnd || !selectDateToMoment(data.dateEnd).isSame(newDateEnd, 'day')) {
        dateChange(data.dateStart, momentToSelectDate(newDateEnd));
      }
    } else {
      if (data.dateEnd) {
        dateChange(data.dateStart, null);
      }
    }
  }

  const getMinDate = () => {
    return projectDateStart;
  };

  const getMaxDate = () => {
    return projectDateEnd;
  };

  const getMaxDuration = () => {
    return getDuration({ dateStart: data.dateStart, dateEnd: getMaxDate() }, weekendUtil);
  }

  const getMaxCalendarDuration = () => {
    return getCalendarDuration({ dateStart: data.dateStart, dateEnd: getMaxDate() });
  }

  const getMaxForDateStart = () => {
    const dates = [];
    if (data.dateEnd) {
      dates.push(selectDateToMoment(data.dateEnd));
    }
    if (data.dateEndFact) {
      dates.push(selectDateToMoment(data.dateEndFact));
    }

    return isEmpty(dates) ? getMaxDate() : momentToSelectDate(moment.min(dates));
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3 form-group">
            <h3 className={cx('h3-felix', 'workList__contentTitle')}>Сроки выполнения</h3>
          </div>
        </div>

        {!isMilestone && (
          <div className={cx('col-md-6', 'workList__dateInput')}>
            <DateRangeSelect
              required={!isGroup && required}
              value={data.dateStart}
              onChange={(val) => dateChange(val, data.dateEnd)}
              minimumDate={getMinDate()}
              maximumDate={getMaxForDateStart()}
              label={i18n.t('workDataRequest.dateStart')}
              disabled={isProjectActive || isGroup}
              isClearable={true}
              isPortal={true}
              portalScrollClasses=".main-panel, .gantt-edit__container"
            />
          </div>
        )}
        <div className={cx('col-md-6', 'workList__dateInput')}>
          <DateRangeSelect
            required={!isGroup && required}
            value={data.dateEnd}
            onChange={(val) => dateChange(data.dateStart, val)}
            minimumDate={!isMilestone && data.dateStart || getMinDate()}
            maximumDate={getMaxDate()}
            label={i18n.t('workDataRequest.dateEnd')}
            disabled={isProjectActive}
            isClearable={true}
            isPortal={true}
            portalScrollClasses=".main-panel, .gantt-edit__container"
          />
        </div>
        {!isMilestone && (
          <>
            <div className={cx('col-md-6', 'workList__dateInput')}>
              <InputNum
                tooltip
                label='Кол-во рабочих дней'
                value={data.duration}
                disabled={isProjectActive || !isTask || !data.dateStart}
                onChange={durationChange}
                max={getMaxDuration()}
              />
            </div>
            <div className={cx('col-md-6', 'workList__dateInput')}>
              <InputNum
                tooltip
                label='Кол-во календарных дней'
                value={data.calendarDuration}
                disabled={isProjectActive || !isTask || !data.dateStart}
                onChange={calendarDurationChange}
                max={getMaxCalendarDuration()}
              />
            </div>
          </>
        )}
        {(!isMilestone || ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(workStatusCode)) && (
          <div className={cx(isMilestone ? 'col-md-6' : 'col-md-12', 'workList__dateInput')}>
            <DateRangeSelect
              tooltip
              value={data.dateEndFact}
              onChange={(val) => {
                handleChangeSelect('dateEndFact', val);
              }}
              label={i18n.t('workDataRequest.dateEndFact')}
              disabled={!['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(workStatusCode) || isGroup}
              minimumDate={data.dateStart || getMinDate()}
              maximumDate={getMaxDate()}
              isPortal={true}
              isClearable={isMilestone}
              portalScrollClasses=".main-panel, .gantt-edit__container"
            />
          </div>
        )}


        {isTask && (
          <div className="col-md-12">
            <TextArea
              tooltip
              label={i18n.t('workDataRequest.results')}
              value={data.results}
              name="results"
              onChange={e => handleChangeSelect('results', e.target.value)}
              disabled={isProjectActive}
            />
          </div>
        )}

      </div>
    </>
  );
}

export default TimingForm;
