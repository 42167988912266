import cx from 'classnames';
import i18n from "i18next";
import React from 'react';

import Input from '@/elements/Input';
import InputNum from '@/elements/Input/InputNum';
import { isEmptyValues } from '@/utils';

export const getWorkProcent = (factValue, plannedValue) => {
  if (factValue != null && plannedValue != null) {
    return plannedValue == '0' || plannedValue == '-0' ? 0 : Math.floor(factValue / plannedValue * 100);
  }

  return 100;
};

const ProgressForm = ({
  required,
  data,
  setData,
  disabled,
}) => {
  const workProcent = getWorkProcent(data.factValue, data.plannedValue);
  const workProcentFormatted = isEmptyValues(data.plannedValue) ? '' : workProcent + '%';
  const factValueFormatted = data.plannedValue ? (data.factValue || 0) : null;

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3 form-group">
            <h3 className={cx('h3-felix', 'workList__contentTitle')}>Прогресс выполнения</h3>
          </div>
        </div>
        <div className={cx('col-md-12', 'workList__dateInput')}>
          <Input
            required={data.plannedValue ? required : false}
            tooltip
            label={i18n.t('workDataRequest.progress.nameNaturalIndex')}
            value={data.nameNaturalIndex}
            disabled={disabled}
            onChange={e => setData('nameNaturalIndex', e.target.value)}
            name="nameNaturalIndex"
          />
        </div>
        <div className={cx('col-md-6', 'workList__planneValue')}>
          <InputNum
            required={data.nameNaturalIndex ? required : false}
            tooltip
            label={i18n.t('workDataRequest.progress.plannedValue')}
            value={data.plannedValue}
            disabled={disabled}
            onChange={val => setData('plannedValue', val)}
            decimalScale={2}
            min={0}
          />
        </div>

        <div className={cx('col-md-6', 'workList__dateInput')}>
          <InputNum
            tooltip
            label={i18n.t('workDataRequest.progress.factValue')}
            value={factValueFormatted}
            disabled={disabled}
            onChange={val => setData('factValue', val)}
            decimalScale={2}
            min={0}
          />
        </div>
        <div className={cx('col-md-12', 'workList__dateInput')}>
          <Input
            tooltip
            label={i18n.t('workDataRequest.progress.completeValue')}
            value={workProcentFormatted.toString()}
            disabled={true}
            onChange={val => setData('completeValue', val)}
          />
        </div>
      </div>
    </>
  );
};

export default ProgressForm;
