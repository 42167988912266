import Arrow from '@/components/Helper/svg/Arrow'
import { Link } from 'react-router-dom';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ButtonAppend({ to = "..", text = "Вернуться назад", className = null }) {
  const navigate = useNavigate();

  const onClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (window.history.state && window.history.state.idx > 0) {
      e.preventDefault();
      navigate(-1);
    }
  }

  return (
    <Link to={to} className={["link_button", className].join(' ')} onClick={onClick} replace >
      <Arrow />
      &nbsp;{text}
    </Link>
  );
}

export default ButtonAppend;
