import React, { CSSProperties } from "react";
import { useDragLayer } from "react-dnd";

import CustomDragGanttItem from "@/components/Gantt/components/CustomDragGanttItem";
import CustomDragGanttLink from "@/components/Gantt/components/CustomDragGanttLink";
import { CalendarType, ItemTypes } from "@/components/Gantt/const";

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

interface CustomDragLayerProps {
  calendarType: CalendarType;
}

const CustomDragLayer = ({ calendarType }: CustomDragLayerProps) => {
  const { itemType, isDragging, item, initialOffset, currentOffset, clientOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    switch (itemType) {
      case ItemTypes.SAUSAGE:
        return (
          <CustomDragGanttItem
            item={item}
            initialOffset={initialOffset}
            currentOffset={currentOffset}
            calendarType={calendarType}
          />
        );
      case ItemTypes.CONNECT_LEFT:
      case ItemTypes.CONNECT_RIGHT:
        return (
          <CustomDragGanttLink itemType={itemType} initialOffset={initialOffset} currentOffset={clientOffset} />
        );
      default:
        return null;
    }
  }

  if (!isDragging || !item || !item.data) {
    return null;
  }

  return (
    <div style={layerStyles}>
      {renderItem()}
    </div>
  );
};

export default CustomDragLayer;