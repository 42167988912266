import './index.scss';

import cx from "classnames";
import React from "react";

import Message from "@/elements/Message";
import { isEmptyValues } from "@/utils";

const TableMobile = ({ info, alwaysMobile, mobileClassName = undefined }) => {
  if (isEmptyValues(info.data)) {
    return (
      <Message text="Нет данных" />
    );
  }

  return (
    <div className={cx("table-mobile__container", mobileClassName, { alwaysMobile })}>
      {info.rows.map((row, i) => {
        info.prepareRow(row);

        return (
          <div key={i} className="table-mobile__item__container">
            <div className="row">
              {row.cells.map((cell, j) => {
                if (isEmptyValues(cell.value) && !info.props.noHideEmpty) {
                  return null;
                }

                return (
                  <div key={j} className='col-12 table-mobile__line__container'>
                    <div className="table-mobile__line__header">
                      {cell.render('Header')}
                    </div>
                    <div className="table-mobile__line__value">
                      {cell.render('Cell')}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );

};

export default TableMobile;