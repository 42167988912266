export const initialState = {
  isOpenSidebar: true,
};

export const Sidebar = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_OPEN_SIDEBAR':
      return { ...state, isOpenSidebar: action.payload };
    default:
      return state;
  }
};
