import AcceptDelete from '@/components/Helper/AcceptDelete'
import React from 'react';

function ItemEdit({ 
  editButton, 
  deleteButton = undefined, 
  item,
  isHideDelete = false, 
  isHideEdit = false, 
  children = undefined, 
  editTitle = "Редактировать" 
}) {
  const onEditButton = (e) => {
    e.preventDefault();
    editButton(item);
  }
  const onDeleteButton = (e) => {
    e.preventDefault();
    deleteButton(item);
  }

  return (
    <div className="edit-delete-button-group-felix">
      { !isHideEdit && (
        <div className="edit-button-felix" onClick={onEditButton}>{editTitle}</div>
      ) }

      { children }
      { !isHideDelete && (
        <AcceptDelete type="outline" buttonType="text" onOk={onDeleteButton}>Удалить</AcceptDelete>
      ) }
    </div>
  );
}

export default ItemEdit;
