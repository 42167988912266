import React from "react";

import AcceptDelete from "@/components/Helper/AcceptDelete";
import {isFunction} from "lodash";
import { useAppSelector } from "@/utils/typedHooks";
import { dictTreeToDict, getDictForSelect, getDictObj } from "@/utils";

export const updateByRowAndColumn = (setData, rowIndex, columnId, value) => {
  setData(oldData => {
    const result = [];

    let j = -1;
    for (let i in oldData) {
      if (!oldData[i].isRemove) {
        j++;
      }

      if (rowIndex !== j) {
        result.push(oldData[i]);
      } else {
        result.push({
          ...oldData[i],
          [columnId]: value
        });
      }

    }

    return result;
  });
};

export const createEditable = (Component, componentProps: any = {}) => ({
  value: initialValue,
  row,
  column: { id: key },
  setDataById,
  props: { isAcceptEdit, focusKey }
}) => {
  const [value, setValue] = React.useState(initialValue);

  const uniqKey = `${key}_${row.id}`;

  const changeData = (val) => {
    if (initialValue !== val) {
      setDataById(row.id, key, val);
    }
  };

  const onChange = val => {
    let result = val;

    if (val?._reactName === 'onChange') {
      result = val.target.value;
    }

    if (isFunction(componentProps.checkNewVal) && !componentProps.checkNewVal(initialValue, result)) {
      return;
    }

    if (focusKey) {
      focusKey.current = uniqKey;
    }

    if (componentProps.fastApplyData) {
      changeData(result);
    }

    setValue(result);
  };

  const onBlur = () => {
    if (focusKey) {
      focusKey.current = null;
    }

    changeData(value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const isItemDisabled = isFunction(componentProps.disabled) ? componentProps.disabled(row.original) : componentProps.disabled;

  const additionalProps = () => {
    if (componentProps.functions) {
      const keys = Object.keys(componentProps.functions)
        .filter(key => isFunction(componentProps.functions[key]));
      const props = {};
      keys.forEach(key => {
        props[key] = componentProps.functions[key](row.original);
      });
      return props;
    }
    else {
      return {};
    }
  }

  return (
    <Component {...componentProps}
               key={uniqKey}
               disabled={!isAcceptEdit || isItemDisabled}
               value={value}
               onChange={onChange}
               onBlur={onBlur}
               {...additionalProps()}
               autoFocus={uniqKey === focusKey?.current}
    />
  );
};

export const createRemove = (componentProps: {
  style?: React.CSSProperties,
  disabled?: boolean | ((item: any) => boolean)
} = {}) => ({ row, removeRowByIndex, setDataById, props }) => {
  if (!props.isAcceptEdit) {
    return null;
  }

  const remove = () => {
    if (!props.withFilterRemoved) {
      removeRowByIndex(row.id);
    } else {
      setDataById(row.id, 'isRemove', true);
    }
  };

  const isItemDisabled = isFunction(componentProps.disabled) ? componentProps.disabled(row.original) : componentProps.disabled;

  return (
    <span style={componentProps.style}>
      <AcceptDelete onOk={remove} type="outline" buttonType="text" title="Удалить" disabled={isItemDisabled}>
        <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
      </AcceptDelete>
    </span>
  );
};

export const checkboxColumn = (args) => {
  const {
    setDataById,
    row: { id },
    column: { id: key },
    value
  } = args;
  const isShow = args.column.checkboxAccessor ? args.column.checkboxAccessor(args.row.original, args) : true;

  if (!isShow) {
    return null;
  }

  const onChange = () => {
    setDataById(id, key, !value);
  };

  return (
    <input
      type="checkbox"
      checked={value || false}
      onChange={onChange}
    />
  );
};

export const checkboxColumnHeader = (args) => {
  const {
    data,
    column: { id },
    setData,
  } = args;

  if (!data) {
    return null;
  }

  const checkAccept = (row) => args.column.checkboxAccessor ? args.column.checkboxAccessor(row, args) : true;

  const dataFiltered = data.filter(item => checkAccept(item));

  if (!dataFiltered.length) {
    return null;
  }

  const isCheckedAll = dataFiltered.every(item => item[id]);

  const onChange = () => {
    setData(oldData => oldData.map(item => {

      if (!checkAccept(item)) {
        return item;
      }

      return ({
        ...item,
        [id]: !isCheckedAll
      });
    }));
  };

  return (
    <input
      type="checkbox"
      checked={isCheckedAll}
      onChange={onChange}
    />
  );
};

export const DictValue = (dictName, id, isDictTree = false, filterFunc = undefined) => {
  const dict = useAppSelector(state => (isDictTree
        ? dictTreeToDict(state.dict[dictName])
        : state.dict[dictName]));

  return <span>{getDictObj(dict, id).name}</span>
}