export const initialState = {
  kpis: [],
  error: undefined,
};

export const Kpis = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_KPIS_SUCCESS':
      return { ...state, kpis: action.payload };
    case 'GET_ALL_KPIS_FAILED':
      return { ...state, error: action.payload };
    case 'DELETE_KPI_SUCCESS':
      return { ...state, kpis: state.kpis.filter((item) => action.id !== item.id) };
    default:
      return state;
  }
};
