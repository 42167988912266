const mockBriefcaseData = [
      {
        id: 1,
        title: 'Инвестиционный портфель',
        // curator: 'Нудельман Александр',
        includes_program: 12,
        includes_project: 20,
        status: 1,
        origin: 'Северо-Запад',
        segment: 'B2B',
        industry: 'Автоматизация',
      },
      {
        id: 2,
        title: 'Портфель рискового капитала',
        // curator: 'Нудельман Александр',
        includes_program: 12,
        includes_project: 20,
        status: 2,
        origin: 'Северо-Запад',
        segment: 'B2C',
        industry: 'Автоматизация',
      },
      {
        id: 3,
        title: 'Отраслевой портфель',
        // curator: 'Нудельман Александр',
        includes_program: 12,
        includes_project: 20,
        status: 3,
        origin: 'Северо-Запад',
        segment: 'B2B',
        industry: 'Кибербезопасность',
      },
      {
        id: 4,
        title: 'Важный портфель',
        // curator: 'Нудельман Александр',
        includes_program: 12,
        includes_project: 20,
        status: 3,
        origin: 'Северо-Запад',
        segment: 'B2B',
        industry: 'Кибербезопасность',
      },
    ]

export default mockBriefcaseData;
