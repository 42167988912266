import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonRt from "@/components/Helper/ButtonRt";
import { useBlocker } from "@/utils/usePrompt";
import { prevent } from "@/utils";
import { useEscape } from "@/utils/hooks";

const DirtyFormModal = ({isDirty, onSave, preventRedirectOnSave = false, funcRef = undefined}:
  { isDirty: boolean, onSave: () => Promise<any>, preventRedirectOnSave?: boolean, funcRef?: any }) => {
  const [isShow, setIsShow] = useState(false);
  const txRef = useRef(null);

  const blocker = useCallback(
    (tx) => {
      if (tx.location?.state?.disableBlock) {
        tx.retry();
        return;
      }
      setIsShow(true);
      txRef.current = tx;
    },
    [txRef]
  );

  if (funcRef) {
    funcRef.current = {
      retry: () => txRef.current?.retry()
    }
  }

  useBlocker(blocker, isDirty);
  useEscape(() => setIsShow(false));

  return (
    <Modal show={isShow}>
      <Modal.Header>
        <Modal.Title>Внимание</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Вы не сохранили данные на этой форме! При переходе в другой раздел
        или закрытии окна браузера несохраненные данные будут потеряны. Хотите сохранить данные?
      </Modal.Body>
      <Modal.Footer>
        <div className='modal__buttons'>
          <ButtonRt type="outline"
                    onClick={() => {
                      if (onSave) {
                        onSave().then(_ => !preventRedirectOnSave && txRef.current?.retry());
                      }
                      setIsShow(false);
                    }}
          >Сохранить</ButtonRt>
          <ButtonRt onClick={() => {
            setIsShow(false);
            txRef.current?.retry();
          }}>Не сохранять</ButtonRt>
          <ButtonRt
            onClick={() => setIsShow(false)}>
            Отмена
          </ButtonRt>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DirtyFormModal;