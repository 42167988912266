import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import './index.scss'
import cx from 'classnames';

const Table = ({
  wrapperClasses = undefined,
  big = false,
  data,
  showCount = false,
  description = undefined,
  ...props
}, ref) => {
  return (
    <>
      {description && (
        <div className='input-title__description' style={{ paddingLeft: 0 }}>
          {description}
        </div>
      )}

      {showCount && (
        <div className="table-container__count">
          Общее количество записей: {data?.length || 0}
        </div>
      )}

      <BootstrapTable
        bordered={false}
        bootstrap4={true}
        noDataIndication="Данные не найдены"
        wrapperClasses={cx("table-responsive dashboard-result-table__container custom-scrollbar", wrapperClasses, { mini: !big })}
        data={data}
        {...props}
        ref={ref}
      />
    </>
  )
}

export default React.forwardRef(Table);