import React, { useState, useEffect, Fragment } from 'react';

import service from '@/services';
import { getDict, getDictCodeById } from "@/utils";
import TextArea from "@/elements/TextArea";
import cx from "classnames";
import { FORMAT_DATE_TIME_IN, useClockManager } from '@/utils/clockmanager';

const getRisks = (statusReportId) => service.get(`/risk/project/status/${statusReportId}`);

const RiskTable = (({projectVersionId, dict, setData, statusComments,
  isAcceptEdit, statusReportId, accentRequired, reportDate}) => {
  const clockManager = useClockManager();
  const [risksWithComment, setRisksWithComment] = useState([]);

  const setComment = (riskId, comment) => {
    if (!riskId) {
      return;
    }
    setRisksWithComment(prevState => {
      prevState.find(value => value.risk.id === riskId).comment = comment;
      setData([...prevState]);
      return [...prevState];
    })
  }

  const getCommentData = (risks) => {
    return risks.map(risk => {
      const riskComment = statusComments?.find(comment => comment.projectRiskId === risk.id);
      return {riskName: risk.name, riskStatusId: risk.statusId, comment: riskComment?.comment || null, risk: risk}
    });
  }

  useEffect(() => {
    if (!projectVersionId || !statusReportId) {
      return;
    }

    getRisks(statusReportId)
      .then(data => setRisksWithComment(getCommentData(data
        .sort(risk => getDictCodeById(dict.riskStatus, risk.statusId) === 'MONITORING' ? -1 : 1))));
  }, [projectVersionId, statusReportId]);

  const withRedRequired = (condition) =>  accentRequired && condition ? 'red' : 'default';
  const isRiskCommentRequired = !!risksWithComment
    .filter(risk => risk.risk?.riskStatusUpdateTime < reportDate)
    .find(riskComment => !riskComment.comment);

  return (
    <Fragment>
      <div
        className={cx('input-title__description', {'red': withRedRequired(isRiskCommentRequired) === 'red'})}
        style={{paddingLeft: '0px'}}>
        Обязательно к заполнению для всех рисков, изменивших статус в период отчета
      </div>
      <table className='risk-table'>
        <thead>
        <tr>
          <td style={{width: '150px'}}>
            Наименование
          </td>
          <td style={{width: '145px'}}>
            Статус риска
          </td>
          <td style={{width: '235px'}}>
            Выполненные меры/Комментарий
          </td>
        </tr>
        </thead>

        {risksWithComment.length > 0 ? <tbody>
          {risksWithComment.map((risk, index) => {
            const editDate = clockManager.formatDateTime(risk.risk?.riskStatusUpdateTime, FORMAT_DATE_TIME_IN);
            const isHighlight = risk.risk?.riskStatusUpdateTime
              &&  risk.risk.riskStatusUpdateTime < reportDate
              && !risk?.comment
              && accentRequired;
            return <tr key={index} style={isHighlight ? {color: 'rgba(255, 94, 45, 1)'} : {}}>
              <td>
                {risk.riskName}
              </td>
              <td>
                <div className='bold'>{getDict(dict.riskStatus, risk.riskStatusId)}</div>
                {editDate && <div style={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: 'rgba(16, 24, 40, 0.5)'
                }}
                >{editDate}</div>}
              </td>
              <td>
                <TextArea titleClassName='text-area-title'
                          value={risk.comment}
                          onChange={(e, val) => {
                            setComment(risk.risk?.id, val);
                          }}
                          disabled={!isAcceptEdit}
                />
              </td>
            </tr>
          })}
          </tbody>
          :
          <tbody>
          <tr>
            <td className='text-center' colSpan={3}>Нет Данных</td>
          </tr>
          </tbody>
        }
      </table>
    </Fragment>
  )
})

export default RiskTable;
