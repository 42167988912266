import React from 'react';
import { useDeviceContext } from '@/context/DeviceContext';

const getChildHash = (children) => {
  if (!children) {
    return {};
  }

  if (children instanceof Array) {
    return children.filter(v => v).reduce((acc, child) => ({
      ...acc,
      [child.key]: child
    }), {})
  }

  return getChildHash([children])
}

function ListItem({ data, children = undefined }) {
  const { isMobile } = useDeviceContext();

  const childrenHash = getChildHash(children);

  const getValue = (title, value) => childrenHash[title] ? childrenHash[title] : value

  const renderMobileListItem = () => (
    <div className="list-items__container">
      { Object.entries(data).map(([title, value]) => (
        <div key={title} className="list-item">
          <div className="list-item__title">{ title }</div>
          <div className="list-item__text">{ getValue(title, value) }</div>
        </div>
      )) }
    </div>
  )

  return isMobile
    ? renderMobileListItem()
    : (
      <table className="table table-hove table-felix list-items__container">
        <tbody>
          { Object.entries(data).map(([title, value]) => (
            <tr key={title}>
              <th scope="row">{title}</th>
              <td>{getValue(title, value)}</td>
            </tr>
          )) }
        </tbody>
      </table>
    )
}

export default ListItem;
