import React, { useState } from "react";
import ButtonAppend from "@/components/Helper/ButtonAppend";
import GetWorksModal from "@/pages/CreateProject/Blocks/AddFromOtherProjects/GetWorksModal";
import service from "@/services";

interface IAddFromOtherProjectsProps {
  projectId: number;
  projectVersionId: number;
  afterAdd: () => void;
}

const AddFromOtherProjects = ({ projectId, projectVersionId, afterAdd }: IAddFromOtherProjectsProps) => {
  const [isShowModal, setIsShowModal] = useState(false);

  const onSelect = async (works: Work[]) => {
    const curMaxSort = await service.get(`/work/maxSort/project/${projectVersionId}`);
    await service.post(`/work/linkWorks/project/${projectId}`, [
      ...works.map((w, i) => ({
        staticWorkId: w.workId || w.id,
        sort: curMaxSort + i + 1,
      }))
    ]);
    setIsShowModal(false);
    afterAdd();
  }

  const onCancel = () => {
    setIsShowModal(false);
  }

  return (
    <>
      <ButtonAppend
        title="Добавить блок работ из другой карточки"
        onClick={() => setIsShowModal(true)}
      />
      <GetWorksModal
        projectId={projectId}
        isShow={isShowModal}
        onSelect={onSelect}
        onCancel={onCancel}
      />
    </>
  );
}

export default AddFromOtherProjects;