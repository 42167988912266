import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useMemo } from 'react';
import CustomDragLayer from "@/components/Gantt/components/CustomDragLayer";
import { CalendarType } from "@/components/Gantt/const";
import GanttContainer from "@/components/Gantt/elements/GanttContainer";
import GanttDateList from "@/components/Gantt/elements/GanttDateList";
import GanttLink from "@/components/Gantt/elements/GanttLink";
import {
  calcNowPosition,
  calcPositions,
  filterRemoved,
  flatten,
  getDateRange,
  getSausageWidth
} from '@/components/Gantt/util/utils';
import { isEmptyValues } from "@/utils";

interface IGanttGraphProps {
  scrollRef: React.MutableRefObject<any>;
  data: GanttTableItem[];
  setData: UpdateFunc<GanttTableItem[]>;
  expanded: GanttTableExpanded;
  link: GanttTableLink[];
  isShowCpm: boolean;
  cpmIds: number[];
  calendarType: CalendarType;
  setOpenWorkId: UpdateFunc<number>;
  isEdit: boolean;
  isEditLink: boolean;
  isShowDateEndInit: boolean;
}

const GanttGraph = React.forwardRef(({
  scrollRef,
  data,
  setData,
  expanded,
  link,
  isShowCpm,
  cpmIds,
  calendarType,
  setOpenWorkId,
  isEdit,
  isEditLink,
  isShowDateEndInit,
}: IGanttGraphProps, ref) => {
  const [
    flattenData,
    dateRange,
    positions,
    nowPosition,
  ] = useMemo(() => {
    if (isEmptyValues(data)) {
      return [];
    }

    const flattenData = filterRemoved(flatten(data, expanded)).map(w => ({
      ...w,
      cpm: isShowCpm && cpmIds.includes(w.id),
    }));

    const dateRange = getDateRange(flattenData, calendarType) || {
      min: moment(),
      max: moment()
    };

    return [
      flattenData,
      dateRange,
      calcPositions(flattenData, dateRange.min, calendarType, isShowDateEndInit),
      calcNowPosition(dateRange.min, calendarType),
    ];
  }, [data, expanded, calendarType, isShowCpm, cpmIds]);

  const scrollToNow = () => {
    const { width } = scrollRef.current.getBoundingClientRect();
    scrollRef.current.scrollTo(nowPosition - width / 2, 0);
  }

  useImperativeHandle(ref, () => ({ scrollToNow }));

  useEffect(() => {
    if (nowPosition) {
      scrollToNow();
    }
  }, [nowPosition]);

  if (isEmptyValues(data)) {
    return null;
  }

  return (
    <div className={cx("gantt__container", calendarType)}>
      <GanttDateList
        dateRange={dateRange}
        calendarType={calendarType}
      />

      <GanttLink
        data={flattenData}
        links={link}
        positions={positions}
        nowPosition={nowPosition}
        dateRange={dateRange}
        calendarType={calendarType}
      />

      <GanttContainer
        data={flattenData}
        setData={setData}
        width={getSausageWidth(dateRange.min, dateRange.max, calendarType)}
        positions={positions}
        links={link}
        calendarType={calendarType}
        setOpenWorkId={setOpenWorkId}
        isEdit={isEdit}
        isEditLink={isEditLink}
      />

      <CustomDragLayer
        calendarType={calendarType}
      />
    </div>
  );
});

export default GanttGraph;