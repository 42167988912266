import React from "react";
import cx from 'classnames'

const NavigationButton = ({
  type,
  disabled,
  onClick
}: {
  type: "left" | "right"
  disabled?: boolean
  onClick: any
}) => {

  return (
    <button className={cx("presentation__nav_button", type)} disabled={disabled} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6L15.4102 7.41Z" fill="#101828" />
        <path d="M15.4102 7.41L10.8302 12L15.4102 16.59L14.0002 18L8.00016 12L14.0002 6L15.4102 7.41Z" fill="#101828" />
      </svg>
    </button>
  );
}

export default NavigationButton;