export const initialState = {
  msgList: [],
};

export const AppMessage = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_APP_MSG':
      return {
        ...state,
        msgList: [
          ...state.msgList,
          action.payload,
        ],
      }
    case 'CLEAR_APP_MSG':
      return {
        ...state,
        msgList: [],
      }
    default:
      return state;
  }
};
