import React from 'react';
import i18n from 'i18next';
import SelectInTable from '@/elements/Select/SelectInTable';
import { NO_TEAM_KEY_SINGULAR } from '@/pages/CreateProject/Blocks/Components/ResponsibleForm';
import SwitchSliderTitle from '@/elements/SwitchSliderTitle';
import { useAppSelector } from '@/utils/typedHooks';
import { getDict, getDictObj } from '@/utils';
import { formatWithCodeName } from '@/pages/Dashboard2/components/BudgetCfoSelect';
import { useDeviceContext } from '@/context/DeviceContext';
import ExtendableWrapper from '@/components/ExtendableWrapper';
import { useExtImport } from '@/utils/hooks';

export interface IEditEmployeeRowsExtProps {
  infoExt?: IExtendableProps;
  switchesExt?: IExtendableProps;
}

const EditEmployeeRows = (props) => {
  const extModule = useExtImport<IEditEmployeeRowsExtProps>('pages/CreateProject/Command/AddEmployee/EditEmployeeRowsExt');

  const {
    isEditProject, isRoleHidden, data, state, roleOptionsList, handleChangeSelect, tagsForSelect, handleTagChange,
    roleComment
  } = props;
  const { roleId, user, powers } = data;
  const roleDict = useAppSelector(state => state.dict.roles);
  const { isDesktop } = useDeviceContext();

  const role = getDictObj(roleDict, roleId);
  const isShowPowers = role?.code === 'LEADER_WORKING_GROUP';

  return (
    <>
      <tr>
        <td>{i18n.t('employeeDataCreate.roleId')}</td>
        {isEditProject && !isRoleHidden ? (
          <td className='add-employee-position'>
            <SelectInTable
              options={roleOptionsList}
              label={i18n.t('employeeDataCreate.roleId')}
              value={roleId}
              onChange={(val) => handleChangeSelect('roleId', val)}
              widthInput={null}
              maxWidthInput={310}
              widthMenu={null}
              maxWidthMenu={310}
              singleValuePaddingRight={isDesktop ? 135 : 35}
            />
          </td>
        ) : (
          <td>{role?.name}</td>
        )}
      </tr>

      {roleComment && <tr>
        <td>{i18n.t('employeeDataCreate.roleComment')}</td>
        <td>{roleComment}</td>
      </tr>}

      <ExtendableWrapper {...extModule?.infoExt} props={props}>
        <tr>
          <td>{i18n.t('employeeDataCreate.userLogin')}</td>
          <td>{user.displayName}</td>
        </tr>

        <tr>
          <td>{i18n.t('employeeDataCreate.department')}</td>
          <td>{user?.department ?? 'Не указано'}</td>
        </tr>
      </ExtendableWrapper>

      <tr>
        <td>{i18n.t('employeeDataCreate.tag')}</td>
        <td>
          <SelectInTable
            options={tagsForSelect}
            label={i18n.t('employeeDataCreate.tag')}
            value={state.tag}
            onChange={val => handleTagChange(val)}
            widthInput={null}
            maxWidthInput={310}
            widthMenu={null}
            singleValuePaddingRight={140}
            maxWidthMenu={310}
            createble
          />
        </td>
      </tr>

      {isShowPowers && (
        <tr>
          <td>{i18n.t('employeeDataCreate.powers')}</td>
          <td>{powers}</td>
        </tr>
      )}

      <tr>
        <td>
          <div>
            {i18n.t('employeeDataCreate.workTeams')}
          </div>
        </td>
        <td>
          {!state.data.employeeWorkTeamIds?.length && <div>{NO_TEAM_KEY_SINGULAR}</div>}
          {!!state.data.employeeWorkTeamIds?.length && <div>
            {state.data.employeeWorkTeamIds?.map(item =>
              <div>{state.workTeams?.find(team => team.id === item)?.name}</div>)}
          </div>}
        </td>
      </tr>

      <ExtendableWrapper {...extModule?.switchesExt} props={props}>
        {data.isUserResponsibleWorkOrMilestone && (
          <tr>
            <td colSpan={2}>
              <SwitchSliderTitle
                title={i18n.t('employeeDataCreate.isHideEmployee')}
                checked={data.isHideEmployee}
                onChange={(val) => handleChangeSelect('isHideEmployee', val)}
                mini
                className="no-margin-bottom"
                desc='Для скрытых сотрудников отключена проверка на заполнение плановой загрузки'
                disabled={!isEditProject}
              />
            </td>
          </tr>
        )}
      </ExtendableWrapper>
    </>
  );
}

export default EditEmployeeRows;