import cx from 'classnames';
import React from "react";

import { CalendarType, CalendarTypeData } from "@/components/Gantt/const";
import { checkIsHoliday } from '@/components/Gantt/util/dateUtil';
import { formatDate } from "@/utils";
import { useAppSelector } from "@/utils/typedHooks";

const GanttDateRender = ({ start, end, headerWidth, momentFormat, momentType }) => {
  const holidayDict = useAppSelector(state => state.dict.holidays);
  const checkHoliday = checkIsHoliday(holidayDict.data);

  const result = [];
  const startClone = start.clone();
  let width = 0;
  while (startClone.isSameOrBefore(end)) {
    const curWidth = headerWidth(startClone);
    width += curWidth;
    result.push((
      <div
        key={startClone}
        className={cx("gantt-scale-cell", ['days'].includes(momentType) && checkHoliday(startClone) ? 'holiday' : '')}
        style={{ width: curWidth }}
      >
        {formatDate(startClone, momentFormat)}
      </div>
    ));
    startClone.add(1, momentType);
  }

  return (
    <div
      className="gantt-scale-row"
      style={{ width }}
    >
      {result}
    </div>
  );
};

interface GanttDateListProps {
  dateRange: DateRangeType;
  calendarType: CalendarType;
}

const GanttDateList = ({ dateRange, calendarType, }: GanttDateListProps) => {
  const calendarTypeData = CalendarTypeData[calendarType];

  return (
    <div className="gantt-date__conatiner">
      <GanttDateRender start={dateRange.min} end={dateRange.max} {...calendarTypeData} />
    </div>
  );

};

export default GanttDateList;