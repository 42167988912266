export const initialState = {
  history: [],
};

export const Agreement = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AGREEMENT_HISTORY':
      return { ...state, history: action.payload };
    default:
      return state;
  }
};
