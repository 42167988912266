import React from 'react';
import cx from 'classnames'
import { prevent } from '@/utils';
import { AppendIcon } from '@/pages/CreateProject/BasicNav/Icon';

function ButtonAppend({ title, onClick, className = undefined }) {
  return (
    <div className={cx('mb-3 form-group rt-felix-button-append__container', className)}>
      <button
        type="button"
        className="button-outline-add-felix"
        onClick={prevent(onClick)}
      >
        <AppendIcon />
        <div>{title}</div>
      </button>
    </div>
  );
}

export default ButtonAppend;
