import cx from 'classnames';
import React, { Fragment } from 'react';

import { isNotEmptyValues } from '@/utils';

export default class InputTitle extends React.Component<any, any> {
  childRef: any;

  constructor(props) {
    super(props);

    this.state = {
      isFocus: false,
    };

    this.childRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        isFocus: true
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value === this.props.value) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(this.props.value, this.isNotEmptyValue());
    }

    this.setState({
      isFocus: this.isNotEmptyValue()
    });
  }

  isNotEmptyValue = () => isNotEmptyValues(this.props.value);

  onClick = (e) => {
    if (this.props.readonly || this.props.disabled) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e, this.childRef);
      return;
    }

    this.setState({
      isFocus: true
    });

    this.childRef.focus();
  };

  onFocus = (e) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    if (this.props.isNoFocus) {
      return;
    }

    this.setState({
      isFocus: true
    });
  };

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    if (this.isNotEmptyValue()) {
      return;
    }

    this.setState({
      isFocus: false
    });
  };

  render() {
    const select = React.cloneElement(this.props.children as any, {
      ref: (e) => this.childRef = e
    });

    const {label, description, hasError, info, tooltip,
      required, isClearable, hideRequiredDesc, descriptionNewLine} = this.props;
    const className = cx(
      "input-title__container payback-period__input rt",
      this.props.className,
      (this.state.isFocus || this.props.isFilled ? "focus" : "noFocus"),
      hasError ? 'error' : '');

    const labelClassName = 'input-title__label';
    const descriptionClassName = 'input-title__description';

    const getDescription = () => {
      if (description && descriptionNewLine) {
        return <Fragment>
          {required && !hideRequiredDesc && <div>Обязательно к заполнению</div>}
          <div>{description}</div>
        </Fragment>
      }
      return required && !hideRequiredDesc
        ? `Обязательно к заполнению${description ? '. ' + description : ''}`
        : description
    };

    const getTextStyle = () => required === 'red' && !this.isNotEmptyValue()
      ? {color: 'red'}
      : {};

    return (
      <React.Fragment>
        <div className={className}
             onClick={this.onClick}
             onFocus={this.onFocus}
             onBlur={this.onBlur}>
          <div className="input-title__container-main">
            <div className={labelClassName} style={getTextStyle()}>
              {label}
            </div>
            <div className={cx(labelClassName, 'hide')} style={getTextStyle()}>{label}</div>
            <div className={cx('input-title__block')}>
              {select}
              {info}
            </div>
          </div>
          <div className={descriptionClassName} style={getTextStyle()}>{getDescription()}</div>
          {this.props.inTitleDescription
            && <div className='input-title__in-title-description'>{this.props.inTitleDescription}</div>}
        </div>
      </React.Fragment>
    );
  }
}
