import React from 'react';
import { FELIX_REST } from "@/config/const";
import service from "@/services";
import ButtonSimple from '@/elements/ButtonSimple';
import cx from 'classnames';

const getFileInfo = (fileId) => service.get(`/file/info/${fileId}`)

const FileLink = ({
  fileId,
  fileName,
  className = undefined,
}) => {
  const onClick = (e) => {
    e.stopPropagation();
    getFileInfo(fileId).then(fi => {
      window.open(fi?.externalLink || `${FELIX_REST}/file/${fileId}`, '_blank');
    });
  }

  return (
    <React.Fragment>
      <ButtonSimple
        children={fileName}
        onClick={onClick}
        className={cx('uncolored', className)}
      />
    </React.Fragment>
  )
}

export default FileLink
