import React from 'react';
import ButtonRtAccept from '@/components/Helper/ButtonRtAccept';

const AcceptDelete = ({ onOk, message = undefined, ...props }) => {

  return (
    <ButtonRtAccept { ...props } onClick={onOk} message={message ?? 'Вы действительно хотите удалить?'} />
  )
}

export default AcceptDelete;
