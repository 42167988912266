import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import ButtonContainer from "@/components/Helper/ButtonContainer";
import ButtonRt from "@/components/Helper/ButtonRt";
import { getPathByType } from "@/utils/project";
import EditMilestone from "@/pages/CreateProject/Blocks/Milestone/EditMilestone";

const MilestoneGanttEditItem = ({
  projectVersionId,
  openMilestoneIndex,
  setOpenMilestoneIndex,
  data,
  updateData,
  projectData,
}) => {
  const close = () => setOpenMilestoneIndex(null);

  useEffect(() => {
    if (openMilestoneIndex) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openMilestoneIndex]);

  const closeOnEsc = useCallback((event) => {
    if (event.key === "Escape") {
      close();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeOnEsc, false);

    return () => {
      document.removeEventListener("keydown", closeOnEsc, false);
    };
  }, [closeOnEsc]);

  return (
    <div className="gantt-edit__container">
      <ButtonContainer>
        <ButtonRt children="Закрыть" type="outline" onClick={close}/>
      </ButtonContainer>

      {data[openMilestoneIndex - 1]?.id > 0 && (
        <Link className="link active mb-2"
              to={`/${getPathByType(projectData.projectTypeCode || 'PROJECT')}/${data[openMilestoneIndex - 1].projectVersionId}/work/milestone/${data[openMilestoneIndex - 1].id}`}>
          Перейти в основную карточку
        </Link>
      )}
      <EditMilestone
        projectVersionId={projectVersionId}
        isView
        localData={data}
        setLocalData={updateData}
        openMilestoneIndex={openMilestoneIndex}
        setOpenMilestoneIndex={setOpenMilestoneIndex}
      />
    </div>
  );
};

export default MilestoneGanttEditItem;