import React, { useEffect } from "react"
import cx from 'classnames'

const Title = ({
  children,
  className,
  pageTitle,
}: {
  children: any,
  className?: string
  pageTitle?: any
}) => {
  useEffect(() => {
    document.title = pageTitle || children;
  }, [pageTitle, children])

  return (
    <h1 className={cx(className)}>
      {children}
    </h1>
  )
}

export default Title