import React from "react";

const ButtonContainer = ({ children }) => {
  return (
    <div className="report-lesson__buttons button-group">
      {children}
    </div>
  );
};

export default ButtonContainer;