import React from 'react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';

const ModalWindow = ({ onHide, show, title, bodyText, size, footer, centered, className, animation = true }) => (
  <Modal
    onHide={onHide}
    show={show}
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
    centered={centered}
    backdrop="static"
    className={cx(className)}
    animation={animation}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        <div className="h3">{title}</div>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5 className="h5-felix-medium">{bodyText}</h5>
    </Modal.Body>
    {footer}
  </Modal>
);

export default ModalWindow;
