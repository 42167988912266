import React, { useRef } from "react";
import { CalendarType, CalendarTypeData, ItemTypes, SAUSAGE_HEIGHT } from "@/components/Gantt/const";
import { getDictCodeById, prevent, selectDateAddDelta } from "@/utils";
import MilestoneGanttItem from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttItem";
import moment from "moment";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { useAppSelector } from "@/utils/typedHooks";

export interface GanttContainerProps {
  data: Milestone[];
  width: number;
  setData: any;
  positions: PositionGanttItemsType;
  calendarType: CalendarType;
  isEdit: boolean;
  projectData: Project;
}

interface DropItem {
  data: Milestone;
  type: 'plan' | 'fact' | 'prediction' | 'initial';
}

const updateItem = (
  item: DropItem,
  setData: UpdateFunc<Milestone[]>,
  deltaDays: number
) => {
  setData(oldData => {
    const itemToUpdate = oldData.find(oldDataItem => oldDataItem.id === item.data.id);
    if (item.type === 'plan') {
      itemToUpdate.datePlan = selectDateAddDelta(itemToUpdate.datePlan, deltaDays);
    }
    if (item.type === 'fact') {
      itemToUpdate.dateFact = selectDateAddDelta(itemToUpdate.dateFact, deltaDays);
    }
    return [...oldData];
  });
};

const getDelta = (monitor: DropTargetMonitor, calendarType: CalendarType) => {
  const deltaX = monitor.getDifferenceFromInitialOffset().x;
  const width = CalendarTypeData[calendarType].width;
  return Math.round(deltaX / width);
};

const MilestoneGanttContainer = ({
  data, width, positions, isEdit, setData, calendarType, projectData
}: GanttContainerProps) => {
  const ref = useRef(null);
  const acceptEditTypes = [];

  const workStatusDict = useAppSelector(state => state.dict.workStatus);

  if (isEdit) {
    acceptEditTypes.push('plan');
    acceptEditTypes.push('fact');
  }

  const [, dropRef] = useDrop(
    () => ({
      accept: acceptEditTypes,
      drop: (item: DropItem, monitor) => {
        updateItem(item, setData, getDelta(monitor, calendarType));
      },
    }), [positions]);

  dropRef(ref);

  return (
    <div className="gantt-items" ref={!isEdit ? undefined : ref} style={{
      height: (SAUSAGE_HEIGHT) * data.length,
      width
    }}>
      {data.map(item => {
        if (!positions[item.id]) {
          return;
        }

        const positionDatePlan = positions[item.id].milestoneDatePlan;
        const positionDateFact = positions[item.id].milestoneDateFact;
        const positionDatePlanBase = positions[item.id].milestoneDatePlanBase;

        const getLastDateType = () => {
          const dates = [positionDatePlan?.date, positionDateFact?.date, positionDatePlanBase?.date];

          const lastDate = dates
            .filter(item => !!item)
            .reduce((prev, curr) => moment(prev).isAfter(curr, 'day') ? prev : curr);

          if (positionDatePlan?.date && moment(lastDate).isSame(positionDatePlan.date, 'day')) {
            return 'plan';
          }

          if (positionDatePlanBase?.date && moment(lastDate).isSame(positionDatePlanBase?.date, 'day')) {
            return 'initial';
          }
          return 'fact';
        }

        const lastDateType = getLastDateType();
        const shouldShowDatePlan = !positionDateFact?.date
          || !moment(positionDatePlan.date).isSame(moment(positionDateFact?.date), 'day');

        const isTodayAfterPlan = !positionDateFact?.date
          && moment().isAfter(moment(positionDatePlan.date), 'day');

        const isFactAfterPlan = positionDateFact?.date
          && moment(positionDateFact.date).isAfter(moment(positionDatePlan.date), 'day');

        const isPlanAfterFact = positionDateFact?.date
          && moment(positionDatePlan.date).isAfter(moment(positionDateFact.date), 'day');

        const showBaseDate = positionDatePlanBase?.date
          && !(moment(positionDatePlanBase.date).isSame(moment(positionDatePlan.date), 'day'));

        const isCanceled = 'CANCEL' === getDictCodeById(workStatusDict, item.statusId);

        return (
          <React.Fragment key={item.id}>
            {showBaseDate && (
              <MilestoneGanttItem
                key={`${positionDatePlanBase.id}-initial`}
                data={item}
                position={positionDatePlanBase}
                onClick={prevent(() => {})}
                readonly={!isEdit || item.isExtreme}
                type={'initial'}
                lastDateType={lastDateType}
                projectData={projectData}
              />
            )}
            {shouldShowDatePlan && (
              <MilestoneGanttItem
                key={`${positionDatePlan.id}-plan`}
                data={item}
                position={positionDatePlan}
                onClick={prevent(() => {})}
                readonly={!isEdit || item.isExtreme}
                type={isCanceled ? '' : 'plan'}
                overdue={(isFactAfterPlan || isTodayAfterPlan) && !item.isSystem}
                lastDateType={lastDateType}
                projectData={projectData}
                closedInTime={isPlanAfterFact}
              />
            )}
            {positionDateFact && (
              <MilestoneGanttItem
                key={`${positionDateFact.id}-fact`}
                data={item}
                position={positionDateFact}
                onClick={prevent(() => {})}
                readonly={!isEdit || item.isExtreme}
                type={'fact'}
                lastDateType={lastDateType}
                projectData={projectData}
              />
            )}
          </React.Fragment>
        )})}
    </div>
  );
};

export default MilestoneGanttContainer;