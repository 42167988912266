import { UPDATE_DICT } from '@/reducers/Dictionaries';
import service from '@/services';

export const statusDict = [
  'status',
  'stage',
  'types',
  'projectTypes',
  'segment',
  'manager',
  'roles',
  'roleTypes',
  'rights',
  'userStatus',
  'riskProbability',
  'riskPriority',
  'riskAffects',
  'riskStatus',
  'cfo',
  'workStatus',
  'kpiType',
  'kpiStatus',
  'mboStatus',
  'mboGoalType',
  'mboQuarter',
  'budgetPayback',
  'fileSection',
  'fileType',
  'reportStatus',
  'properties',
  'lessonKnowledge',
  'region',
  'reportCloseGrade',
  'committeeType',
  'committeeStatus',
  'committeeObject',
  'agreeStatus',
  'agreeUserStatus',
  'agreeSection',
  'agreeStage',
  'businessCaseIndex',
  'businessCaseType',
  'businessCaseStatus',
  'businessCaseOpexShpp',
  'businessCaseOpexBusinessPrc',
  'businessCaseOpexR12',
  'scale',
  'businessCaseCategory',
  'budgetSourceType',
  'budgetType',
  'projectWorkControlPoint',
  'investProgram',
  'projectImplementationEvaluation',
  'holidays',
  'externalCustomer',
  'employeeCfo',
  'budgetDirection',
  'budgetIndicator',
  'territory',
  'vertical',
  'budgetMarginType',
  'budgetIncomeIndicatorType',
  'investCategory',
  'budgetPredictionStatus',
  'budgetPredictionOrderType',
  'budgetSection',
  'leaderRating',
  'scaleRating',
  'meetingStatus',
  'meetingUserStatus',
  'keyEventStatus',
  'workLinkType',
  'timesheetStatus',
  'workType',
  'certificateRating',
  'assignmentStatus',
  'assignmentAgreeStatus',
  'assignmentAgreeType',
  'projectRiskType',
  'projectRiskStrategyThreat',
  'projectRiskStrategyOpportunity',
  'budgetSourceInputType',
  'projectDirection',
  'budgetSourceInputType',
  'changeRunCriteria',
  'projectAssessment',
  'ppmProjectType',
  'topCriteria',
  'financingType',
  'goalType',
  'ppmRequestStatus',
  'ppmZone',
  'ppmRequestAgreeStatus',
  'settingsType',
  'businessProcess',
  'territoryPsr',
  'reportAgreeStatus',
  'searchFiredMode',
  'projectClass',
  'projectWorkAgreeType',
  'budgetPredictionOrderAction',
];

export const dictLoad = (...names) => {
  return (dispatchInside) =>
    service.get('/dict/all').then(data => {
      names.map(name => dispatchInside({
        type: UPDATE_DICT,
        name,
        data: data[name]
      }));
    });
};

export const loadAllDict = dictLoad(...statusDict);