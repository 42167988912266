import cx from 'classnames';
import React from "react";

const ButtonSimple = ({ children, className, ...props }: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button className={cx("button_Link_borderNone", className)} {...props}>
      {children}
    </button>
  );
};

export default ButtonSimple;