import React from 'react';
import { BarSeries, Chart } from '@devexpress/dx-react-chart-bootstrap4';
import NoData from '@/pages/Dashboard2/components/NoData';
import { reportColors } from "@/pages/Dashboard2/utils";
import BarSeriesPoint from '@/pages/TopReport/components/BarSeriesPoint';
import { EventTracker, Stack } from '@devexpress/dx-react-chart';
import Legend from '@/pages/Dashboard2/components/Legend';

const labelLegendComponent = (props) => {
  const ser = props.series?.find(item => props.text === item.name);
  const count = props.data?.[ser?.key];

  return <span>{props.text} ({count})</span>;
}

const series = [...reportColors].reverse();

const stacks = [
  { series: series.map((obj) => obj.name) }
];

const StatusConsolidate = ({ data = [] }) => {
  const localData = data
    .filter(p => !!p.reportConsolidateStatusCode)
    .reduce((acc, p) => {
      acc.name = 'Сводный статус'
      acc[p.reportConsolidateStatusCode] = acc[p.reportConsolidateStatusCode] + 1;
      return acc;
      }, { SIGNIFICANT_DEVIATION: 0, SLIGHT_DEVIATION: 0, PLAN: 0 });

  return (
    <div className='top-report-status-consolidate'>
      <Chart data={[localData]} height={65} rotated={true}>
        {series?.map(item => (
          <BarSeries
            key={item.name}
            name={item.name}
            valueField={item.key}
            argumentField='name'
            color={item.color}
            pointComponent={BarSeriesPoint}
          />
        ))}
        <EventTracker />
        <NoData />
        <Stack stacks={stacks} />
        <Legend series={series} data={localData} labelComponent={labelLegendComponent} />
      </Chart>
    </div>
  )
}

export default StatusConsolidate;
