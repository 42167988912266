import { getDict, formatDate, getDictObj, dictTreeToDict, importExt } from '@/utils'
import i18n from "i18next";
import { AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';

const prepareResult = (self: any, data: any): any[] => {
  if (!data.qualitativeIndicators) {
    return [];
  }

  return data.qualitativeIndicators.map((item) => ({
    key: item.staticId,
    title: (_, i) => `Показатель ${i + 1}`,
    data: [
      {
        key: 'indexInput',
        title: i18n.t('businessCaseWeb.indexInput'),
        data: item.indexInput
      },
      {
        key: 'benefitDescription',
        title: i18n.t('businessCaseWeb.benefitDescription'),
        data: item.benefitDescription
      },
      {
        key: 'methodMeasurement',
        title: i18n.t('businessCaseWeb.methodMeasurement'),
        data: item.methodMeasurement
      },
      {
        key: 'dateBenefit',
        title: i18n.t('businessCaseWeb.dateBenefit'),
        data: formatDate(item.dateBenefit)
      },
      {
        key: 'beneficiarySegment',
        title: i18n.t('businessCaseWeb.beneficiarySegment'),
        data: getDict(dictTreeToDict(self.props.dict.segment), item.beneficiarySegment)
      },
      {
        key: 'responsible',
        title: i18n.t('businessCaseWeb.responsible'),
        data: item.responsible?.displayName
      },
      {
        key: 'beneficiary',
        title: i18n.t('businessCaseWeb.beneficiary'),
        data: item.beneficiary?.displayName
      },
      {
        key: 'statusId',
        title: i18n.t('businessCaseWeb.statusId'),
        data: getDict(self.props.dict.businessCaseStatus, item.statusId)
      },
      ...(getDictObj(self.props.dict.businessCaseStatus, item.statusId)?.code !== 'PLANNED'
        ? [
          {
            key: 'statusEndDescription',
            title: i18n.t('businessCaseWeb.statusEndDescription'),
            data: item.statusEndDescription
          },
          {
            key: 'dateActualAssessment',
            title: i18n.t('businessCaseWeb.dateActualAssessment'),
            data: formatDate(item.dateActualAssessment)
          },
        ]
        : [])
    ]
  }));
}

const qualitativeIndicators = async (self) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/qualitativeIndicatorsExt')
    .then(ext => ext?.(self)) as AgreementBlockExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data);
        result = extModule?.updateResult(data, result) || result;
        return result;
      }
    },
  ]
}

export default qualitativeIndicators;
