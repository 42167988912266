import React, { useMemo } from 'react';
import AcceptDelete from "@/components/Helper/AcceptDelete";
import TableBeta from "@/components/TableBeta";
import SelectDict from "@/elements/Select/SelectDict";
import ButtonSimple from '@/elements/ButtonSimple';
import Select from '@/elements/Select';
import { getAutoFakeIndex } from '@/components/Gantt/util/utils';
import { getWorkNameWithProject } from '@/pages/CreateProject/Blocks/utils';
import { useAppSelector } from '@/utils/typedHooks';
import { sortBy, uniqBy } from 'lodash';
import { getDictByCode } from '@/utils';
import { LinkTypes } from '@/components/Gantt/const';

const getColumns = ({ remove, changeType, readonly, works, worksForSelect, changeWork, isPrev, projectId, typesDict, curWorkId, curWorkProjectId }) => {
  const result = [
    {
      Header: 'Название работы',
      accessor: isPrev ? 'fromId' : 'toId',
      required: 'true',
      Cell: ({ value, row }) => {
        return (
          <Select
            placeholder="Выберите блок работ"
            value={value}
            onChange={(val) => changeWork(row.original.id, val, works.find(w => w.id === val)?.projectId, isPrev)}
            titleClassName="in-table"
            disabled={readonly}
            options={sortBy(uniqBy(worksForSelect
                .map(w => ({ value: w.id, label: w.projectId === projectId ? w.name : getWorkNameWithProject(w, typesDict) }))
              , 'value'), 'label')}
            isPortal
          />
        );
      },
    },
    {
      Header: 'Тип связи',
      accessor: 'typeId',
      required: 'true',
      Cell: ({ value, row }) => {
        return (
          <SelectDict
            placeholder="Выберите тип связи"
            value={value}
            onChange={(val) => changeType(row.original.id, val)}
            dictName="workLinkType"
            titleClassName="in-table"
            disabled={readonly}
            isPortal
          />
        );
      },
    },
    {
      accessor: 'remove',
      Header: '',
      hidden: readonly,
      Cell: ({ row }) => {
        return (
          <AcceptDelete disabled={row.original.disabledRemove} onOk={() => remove(row.original.id)} type="outline" buttonType="text" title="Удалить">
            <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" className="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path></svg>
          </AcceptDelete>
        );
      }
    },
  ];

  return result;
};

export type EditItemLinkProp = {
  data: GanttTableItem[];
  links: GanttTableLink[];
  curWorkId: number;
  curWorkProjectId: number;
  setLinks: (func: (links: GanttTableLink[]) => GanttTableLink[]) => void;
  readonly: boolean;
  required?: boolean | 'red' | 'default';
  projectId: number;
};

const EditItemLink = ({ data, links, curWorkId, curWorkProjectId, setLinks, readonly, required, projectId }: EditItemLinkProp) => {
  const [typesDict, workLinkTypeDict] = useAppSelector(state => [
    state.dict.types,
    state.dict.workLinkType
  ]);

  const isDisabledRemove = (work1, work2) => {
    return work1?.isFromOtherProject && work2?.isFromOtherProject;
  }

  const curWork = data.find(item => item.id === curWorkId);

  const worksForSelect = data
    .filter(w => w.id !== curWorkId)
    .filter(w => curWorkProjectId === projectId || w.projectId === projectId);

  const workIdsForSelect = worksForSelect.map(w => w.id);

  const tableLinksNext = links
    .filter(link => link.fromId === (curWorkId || -1) && (!link.toId || workIdsForSelect.includes(link.toId)))
    .reduce((acc, link) => {
      const workTo = data.find(item => item.id === link.toId);
      const workName = workTo?.name;

      return [...acc, {
        ...link,
        workName,
        disabledRemove: isDisabledRemove(curWork, workTo),
      }];
    }, []);

  const tableLinksPrev = links
    .filter(link => link.toId === (curWorkId || -1) && (!link.fromId || workIdsForSelect.includes(link.fromId)))
    .reduce((acc, link) => {
      const workFrom = data.find(item => item.id === link.fromId);
      const workName = workFrom?.name;

      return [...acc, {
        ...link,
        workName,
        disabledRemove: isDisabledRemove(curWork, workFrom),
      }];
    }, []);

  const remove = (linkId: number) => {
    setLinks(oldLinks => oldLinks.filter(item => item.id !== linkId));
  };

  const changeType = (linkId: number, typeId: number) => {
    setLinks(oldLinks => oldLinks.map(link => link.id === linkId ? { ...link, typeId } : link));
  };

  const changeWork = (linkId: number, workId: number, projectId: number, isPrev: boolean) => {
    setLinks(oldLinks => oldLinks.map(link => link.id === linkId ? {
      ...link,
        fromId: isPrev ? workId : link.fromId,
        fromProjectId: isPrev ? projectId : link.fromProjectId,
        toId: isPrev ? link.toId : workId,
        toProjectId: isPrev ? link.toProjectId : projectId,
    } : link));
  };

  const columns = (isPrev) => getColumns({
    remove,
    changeType,
    readonly,
    works: data,
    worksForSelect,
    changeWork,
    isPrev,
    projectId,
    typesDict,
    curWorkId,
    curWorkProjectId
  });

  const columnsPrev = useMemo(() => columns(true), [readonly, projectId, typesDict, curWorkId,
    curWorkProjectId, data]);
  const columnsNext = useMemo(() => columns(false), [readonly, projectId, typesDict, curWorkId,
    curWorkProjectId, data]);

  const endToStartId = getDictByCode(workLinkTypeDict, LinkTypes.END_TO_START)?.id;

  const addPrev = () => {
    setLinks(oldLinks => oldLinks.concat([{
      id: getAutoFakeIndex(),
      toId: curWorkId || -1,
      toProjectId: curWorkProjectId,
      fromId: null,
      fromProjectId: null,
      typeId: endToStartId,
    }]));
  }

  const addNext = () => {
    setLinks(oldLinks => oldLinks.concat([{
      id: getAutoFakeIndex(),
      toId: null,
      toProjectId: null,
      fromId: curWorkId || -1,
      fromProjectId: curWorkProjectId,
      typeId: endToStartId,
    }]));
  }

  return (
    <div>
      <div className='col-md-12 flex-start'>
        <h4 className="h4-felix">Предшествующие</h4>
        <ButtonSimple className='work-link-add-button' children="Добавить" onClick={addPrev}/>
      </div>
      <TableBeta
        columns={columnsPrev}
        data={tableLinksPrev}
        className="gantt-item-link"
        required={required}
        requiredNoEmptyRows
      />

      <div className='col-md-12 flex-start'>
        <h4 className="h4-felix">Последующие</h4>
        <ButtonSimple className='work-link-add-button' children="Добавить" onClick={addNext}/>
      </div>
      <TableBeta
        columns={columnsNext}
        data={tableLinksNext}
        className="gantt-item-link"
        required={required}
        requiredNoEmptyRows
      />
    </div>
  );
};

export default EditItemLink;