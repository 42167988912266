import React, { useEffect, useState } from "react";

import TableBeta from "@/components/TableBeta";
import { getEmptyResponsible } from "@/pages/CreateProject/Blocks/Components/ResponsibleForm";
import Spoiler from "@/components/Spoiler";
import Checkbox from "@/elements/Checkbox";
import { prevent } from "@/utils";
import { DeleteIcon, EditIcon, ExitIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { BigSaveIcon } from "@/components/Gantt/elements/Icons";
import services from "@/services";
import * as ReactDOM from "react-dom";
import ModalAsync from "@/components/ModalAsync";
import Input from "@/elements/Input";
import ButtonSimple from "@/elements/ButtonSimple";
import { useCheckRight } from "@/utils/hooks";

const removeWorkTeam = (teamId) => services.remove(`/work/deleteWorkTeam/${teamId}`);

type WorkGroupCardProps = {
  data: { name: string, memberList: any[], id: number };
  getColumns: any;
  checkedIds?: number[];
  setCheckedIds?: any;
  updateWorkGroups?: any;
  isEdit: boolean;
  setEditData: any;
  saveEditData: any;
  withTableButtons?: boolean;
  disableChoose?: boolean;
};

const WorkTeamCard = ({
  data, getColumns, checkedIds, setCheckedIds,
  updateWorkGroups, isEdit, setEditData, saveEditData,
  withTableButtons, disableChoose
}: WorkGroupCardProps) => {

  const getCheckboxTitle = () => {
    if (disableChoose) {
      return <span className='display-flex-direction-row'>{data.name}</span>
    }
    return <span className='display-flex-direction-row'>
      <Checkbox
        checked={(checkedIds || []).includes(data.id)}
        onChange={(e) => {
          if ((checkedIds || []).includes(data.id)) {
            setCheckedIds(prevState => prevState.filter(value => value !== data.id));
          } else {
            setCheckedIds(prevState => ([...prevState, data.id]));
          }
        }}/>
      {data.name}
    </span>
  }

  return (
    <div>
      <Spoiler defaultState={true} key={data.id} title={getCheckboxTitle()}>
        <WorkTeamCardInner data={data}
                           getColumns={getColumns}
                           isEdit={isEdit}
                           setEditData={setEditData}
                           saveEditData={saveEditData}
                           updateWorkGroups={updateWorkGroups}
                           withTableButtons={withTableButtons}/>
      </Spoiler>
    </div>
  );
};

export const WorkTeamCardInner = ({
  data, getColumns, withTableButtons,
  updateWorkGroups, isEdit, setEditData, saveEditData
}: WorkGroupCardProps) => {
  const [localList, setLocalList] = useState(data.memberList ?? []);
  const [localName, setLocalName] = useState(data.name ?? null);
  const modalRef = React.useRef<any>();
  const checkRight = useCheckRight();

  const setEditDataWrapper = (item) => {
    if (data) {
      setEditData({data: item, localList, localName});
    }
  }

  useEffect(() => {
    if (isEdit) {
      setEditDataWrapper(data);
    }
  }, [localList, localName]);

  const openDeleteModal = () => {

    return modalRef.current.open().then((_) => {
      if (data.id) {
        return removeWorkTeam(data.id).then(() => updateWorkGroups && updateWorkGroups());
      }
    });
  }

  const save = () => {
    saveEditData();
  }

  const cancel = () => {
    updateWorkGroups && updateWorkGroups();
    setEditData(null);
    setLocalList(data.memberList ?? []);
    setLocalName(data.name ?? null)
  }

  const tableButtons = withTableButtons && isEdit
    ? [{title: 'Сохранить', func: save},
      {title: 'Отменить', func: cancel}]
    : undefined;

  return (
    <div>
      <div className='work-group-card-button-container marginBottom-10'>
        {!withTableButtons && !isEdit && checkRight('EDIT_PROJECT') &&
          <ButtonSimple onClick={() => setEditDataWrapper(data)}
                        children='Редактировать'/>
        }

        {!withTableButtons && !isEdit && checkRight('EDIT_PROJECT') &&
          <ButtonSimple style={{color: 'red'}} onClick={openDeleteModal} children='Удалить'/>
        }
      </div>

      <div className='work-group-card-button-container'>
        {!withTableButtons && isEdit && checkRight('EDIT_PROJECT') &&
          <ButtonSimple onClick={save}
                        children='Сохранить'/>
        }

        {!withTableButtons && isEdit && checkRight('EDIT_PROJECT') &&
          <ButtonSimple onClick={cancel}
                        children='Отменить'/>
        }
      </div>

      {isEdit && <div>
        <Input
          required
          label='Название'
          value={localName}
          onChange={(_, val) => setLocalName(val)}
        />
      </div>}

      <TableBeta
        requiredNoEmptyRows
        data={localList}
        columns={getColumns(isEdit)}
        setData={setLocalList}
        className='input-color marginBottomNegative-05rem'
        props={{
          noHideEmpty: true,
          isAcceptEdit: isEdit && checkRight('EDIT_PROJECT'),
          withFilterRemoved: true,
          withAddButton: true,
          addButtonTitle: 'Добавить исполнителя',
          additionalButtons: tableButtons
        }}
        withMobile
        getEmptyRow={getEmptyResponsible}
      />
      {ReactDOM.createPortal((
        <ModalAsync title='Внимание' ref={modalRef} okTitle="Да" cancelTitle="Нет">
          Вы уверены, что хотите удалить эту рабочую группу? Данное действие удалит ее из всех блоков работ
        </ModalAsync>
      ), document.body)}
    </div>
  );
};

export default WorkTeamCard;