const initialState = {
  local: {},
};

export const UserStorage = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_USER_VALUE':
      return {
        ...state,
        local: {
          ...state.local,
          [action.key]: action.value,
        }
      }
    default:
      return state;
  }
};
