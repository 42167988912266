import React from 'react';
import services from '@/services'

import PromiseSearch from '@/components/PromiseSearch';

interface ProjectSearchProps {
  value: ProjectSearchValue;
  onChange: (item: any) => void;
  label: string;
  isClearable?: boolean;
  showClosed?: boolean;
  isPortal?: boolean;
  name?: string;
  required?: boolean;
  projectStatus?: string;
  projectType?: projectTypes;
  projectVersionType?: string;
  excludeIds?: number[];
  isShowAll?: boolean;
  parentProjectId?: number;
}

interface ProjectSearchValue {
  value: number;
  label: string;
  projectId: number;
}

export const prjSelect = (prj): ProjectSearchValue => ({
  value: prj.id,
  label: prj.name,
  projectId: prj.projectId,
});

const getProject = (inputValue, props: ProjectSearchProps) => services.get(`/project/search/select`, {
  'page.sortBy': 'name',
  'page.sortDesc': 'true',
  'page.size': 20,
  'page.page': 1,
  query: inputValue,
  projectType: props.projectType,
  projectVersionType: props.projectVersionType,
  statusCode: props.projectStatus,
  isShowClosed: props.showClosed,
  isShowAll: props.isShowAll,
  parentProjectId: props.parentProjectId,
}).then(data => data.filter(prj => !props.excludeIds?.includes(prj.projectId)).map(prjSelect));

const ProjectSearch = (props: ProjectSearchProps) => {
  const key = [props.showClosed, props.projectStatus, props.projectType].join()

  return (
    <PromiseSearch key={key} loadOptions={getProject} {...props} />
  )
}

export default ProjectSearch;
