import { uniqBy } from "lodash";

export const initialState = {
  toastList: [],
};

export const Toast = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toastList: uniqBy([
          ...state.toastList,
          action.payload,
        ], toast => toast.id),
      }
    default:
      return state;
  }
};
