import React, { Fragment } from 'react';
import ArrowSvg from '@/components/Helper/svg/Arrow';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { getPathByType } from "@/utils/project";
import Arrow from "@/components/Helper/svg/Arrow";

interface ButtonToVersionProps extends ButtonToEditProps, ButtonToReleaseProps{
  isAccess: boolean;
  hasAccessToAgreement: boolean;
  isEdit: boolean;
  currentStatusCode: string;
}

interface ButtonToEditProps {
  projectTypeCode: string;
  projectVersionNextId: number;
  nextStatusCode: string;
}

interface ButtonToReleaseProps {
  projectTypeCode: string;
  projectVersionReleaseId: number;
}

const ButtonToEdit = ({projectTypeCode, projectVersionNextId, nextStatusCode}: ButtonToEditProps) => {

  const to = `/${getPathByType(projectTypeCode)}/${projectVersionNextId}`;

  const getNextStatus = (statusCode) => {
    if (statusCode === "COORDINATION") {
      return "На согласовании";
    }
    if (statusCode === "REWORK") {
      return "На доработке";
    }
    return "К редактированию";
  };

  const getButtonClassForNextStatus = (statusCode) => {
    if (statusCode === "COORDINATION") {
      return "project__status-item nowrap status COORDINATION";
    }
    if (statusCode === "REWORK") {
      return "project__status-item nowrap status REWORK";
    }
    return "project__status-item nowrap status EDITING";
  };

  return <Fragment>
    <Link to={to} className={cx(getButtonClassForNextStatus(nextStatusCode), "project-back__to-edit")}>
      {getNextStatus(nextStatusCode)}
    </Link>
    <Link to={to} className={"project-back__to-edit"}>
      <ArrowSvg className="project-back__go-edit__icon"/>
    </Link>
  </Fragment>;
}

const ButtonToRelease = ({projectTypeCode, projectVersionReleaseId}: ButtonToReleaseProps) => {
  const to = `/${getPathByType(projectTypeCode)}/${projectVersionReleaseId}`;

  return <Fragment>
    <Link to={to} className={"project-back__to-edit"}>
      <Arrow />
    </Link>
    <Link to={to} className='project__status-item nowrap status RELEASE project-back__to-edit'>
      К согласованной
    </Link>
  </Fragment>;
}

const ButtonToVersion = ({projectTypeCode, projectVersionNextId, nextStatusCode, projectVersionReleaseId,
  currentStatusCode, isAccess, hasAccessToAgreement, isEdit}: ButtonToVersionProps) => {

  const isVersionWithReleaseLink = ['COORDINATION', 'REWORK', 'EDITING'].includes(currentStatusCode);

  if (isVersionWithReleaseLink) {
    return <Fragment>
      {projectVersionReleaseId && <ButtonToRelease projectTypeCode={projectTypeCode}
                                                   projectVersionReleaseId={projectVersionReleaseId}/>}
    </Fragment>
  }

  return <Fragment>{(isAccess || hasAccessToAgreement)
    && !isEdit
    && projectVersionNextId
    && <ButtonToEdit projectTypeCode={projectTypeCode}
                     projectVersionNextId={projectVersionNextId}
                     nextStatusCode={nextStatusCode}/>}
  </Fragment>


}

export default ButtonToVersion;
