import React from 'react';

function Arrow({ className = null }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81731 12.749L11.0289 16.9388L9.97105 18.0022L4.47105 12.5307C4.32955 12.39 4.25 12.1986 4.25 11.999C4.25 11.7994 4.32955 11.6081 4.47105 11.4673L9.97105 5.99585L11.0289 7.05926L6.81737 11.249L20 11.249L20 12.749L6.81731 12.749Z"
        fill="#101828"
      />
    </svg>
  );
}

export default Arrow;
