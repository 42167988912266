import React from "react";
import { XYCoord } from "react-dnd";

import { CalendarType, ItemType } from "@/components/Gantt/const";
import { GanttItemPure } from "@/components/Gantt/elements/GanttItem";
import { calcAppendWith, calcOffset, getSausageWidth } from "@/components/Gantt/util/utils";
import { parseDate, selectDateToMoment } from "@/utils";
import { MilestoneGanttItemPure } from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttItem";
import { MILESTONE_WIDTH } from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttGraph";
import moment from "moment/moment";

const getItemStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  type: ItemType,
  calendarType: CalendarType,
  sausageWidth?: number
) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const transformPosition = calcOffset(initialOffset, currentOffset, type, calendarType, sausageWidth);
  const transform = `translate(${transformPosition.x}px, ${transformPosition.y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

interface DragItemProps {
  item: {data: Milestone, type: any};
  initialOffset: any;
  currentOffset: any;
  calendarType: any;
  itemType: any;
}

const MilestoneGanttDragItem = ({ item, initialOffset, currentOffset, calendarType, itemType } : DragItemProps ) => {
  const overdue = itemType === 'plan'
    && !item.data.dateFact
    && moment().isAfter(selectDateToMoment(item.data.datePlan), 'day');

  return (
    <div style={getItemStyles(initialOffset, currentOffset, item.type, calendarType)} >
      <MilestoneGanttItemPure
        item={item.data}
        overdue={overdue}
        width={MILESTONE_WIDTH}
        type={itemType}/>
    </div>
  );
};

export default MilestoneGanttDragItem;