import React from 'react';
import { TemplateConnector, Template, Plugin, TemplatePlaceholder } from '@devexpress/dx-react-core';

const NoData = (props) => {
  return (
    <Plugin name="NoDataLayout">
      <Template name="series">
        <TemplatePlaceholder />
        <TemplateConnector>
          { ({ data, layouts }) => {
            if (data.length) {
              return null;
            }

            const x = layouts.pane.width / 2;
            const y = layouts.pane.height / 2;

            return (
              <g transform={`translate(${x}, ${y})`}>
                <text className="dx-c-bs4-fill-current-color dx-c-bs4-label text-muted dashboard-no-data"
                      textAnchor="middle"
                      dominantBaseline="central"
                >
                  Нет данных
                </text>
              </g>
            );
          } }
        </TemplateConnector>

      </Template>
    </Plugin>
  )
}

export default NoData;
