import React, { CSSProperties } from 'react';

const RedDot = ({className, style}: {className?: string, style?: CSSProperties}) =>
  <span className={className} style={{width: '6px',
    height: '6px',
    borderRadius: '3px',
    background: '#FF0C0C',
    ...style}}/>

export default RedDot
