import * as React from 'react';
import ReactSelect from 'react-select';

import Tooltip from "@/elements/Tooltip";
import { isNotEmptyValues } from '@/utils';

export const LoadingMessage = props => {
  return (
    <div
      {...props.innerProps}
      style={props.getStyles('loadingMessage', props)}
    >
      Загрузка
    </div>
  );
};

export const NoOptionsMessage = props => {
  return (
    <div
      {...props.innerProps}
      style={props.getStyles('noOptionsMessage', props)}
    >
      Нет данных
    </div>
  );
};

export const Placeholder = props => {
  return (
    <div
      {...props.innerProps}
      style={{ ...props.getStyles('placeholder', props), overflow: 'hidden' }}
    >
      {props.selectProps.placeholder}
    </div>
  );
};

const SingleValue = props => (
  <div {...props.innerProps}
       style={{ ...props.getStyles('singleValue', props), zIndex: 0 }}>
    <div style={props.data.hideLabel ? { display: 'none' } : undefined}>{props.data.label}</div>
    {props.data.tooltip && (
      <Tooltip text={props.data.tooltip}/>
    )}
  </div>
);

const customClassNames = ({
  menuList: prop => 'custom-scrollbar'
});

const SelectBase = ({
                      options,
                      value,
                      onChange,
                      isMulti = false,
                      disabled = false,
                      showUndefined = false, // заставляет селект показать значения если их нет в списке
                      linkMode = false,
                      isHideDropdown = false,
                      ...props
                    }, ref) => {
  const getValues = () => {
    let result;

    if (isMulti) {
      result = value
        ? value.map(value => options.find(item => item.value === value))
        : null;
    } else {
      result = options.find(item => item.value === value) || null;

      if (!result && showUndefined) {
        result = {
          label: value,
          value
        };
      }

      if (result) {
        result.hideLabel = linkMode;
      }
    }

    return result;
  };

  const onChangeLocal = (e) => {
    const valueLocal = isMulti ? (e ? e.map(v => v.value) : []) : (isNotEmptyValues(e) ? e.value : null);

    if (value === valueLocal) {
      return;
    }

    onChange(valueLocal);
  };

  const components = { SingleValue, LoadingMessage, NoOptionsMessage, Placeholder };
  if (isHideDropdown) {
    components['DropdownIndicator'] = () => null;
  }

  return (
    <ReactSelect
      ref={ref}
      components={components}
      isMulti={isMulti}
      value={getValues()}
      options={options}
      isDisabled={disabled}
      onChange={onChangeLocal}
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      isOptionDisabled={option => option.disabled || option.isActive === false}
      classNames={customClassNames}
      {...props}
      placeholder={props.placeholder || ""}
    />
  );
};

export default React.forwardRef(SelectBase);