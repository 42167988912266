interface INewProject {
  newProjectData: Project
}

export const initialState = ({
  newProjectData: {},
} as INewProject);

export const NewProject = (state = initialState, action): INewProject => {
  switch (action.type) {
    case 'SET_NEW_PROJECT_DATA':
      return { ...state, newProjectData: action.payload };
    case 'UPDATE_NEW_PROJECT_NAME':
      return { ...state, newProjectData: { ...state.newProjectData, name: action.payload } };
    case 'RESET_PROJECT':
      return { ...state, newProjectData: ({} as Project) };
    default:
      return state;
  }
};
