import { getDict, getDictObj } from '@/utils'

const AgreementRiskBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.risk) {
        return []
      }

      return data.risk.map(item => {
        const typeCode = getDictObj(self.props.dict.projectRiskType, item.typeId)?.code;
        const strategyDict = typeCode === 'THREAT'
          ? self.props.dict.projectRiskStrategyThreat
          : self.props.dict.projectRiskStrategyOpportunity;

        return {
        title: item.name,
        data: [
          {
            title: 'Тип риска',
            data: getDict(self.props.dict.projectRiskType, item.typeId)
          },
          {
            title: 'Описание',
            data: item.description
          },
          {
            title: 'Ответственный за митигацию',
            data: item.user?.displayName
          },
          {
            title: 'Вероятность наступления риска',
            data: getDict(self.props.dict.riskProbability, item.probabilityId)
          },
          {
            title: 'Степень влияния риска',
            data: getDict(self.props.dict.riskPriority, item.priorityId)
          },
          {
            title: 'На что влияет',
            data: item.affects.map(item => getDict(self.props.dict.riskAffects, item)).join(', ')
          },
          {
            title: 'Причины возникновения',
            data: item.cause
          },
          {
            title: 'Последствия возникновения риска',
            data: item.effects
          },
          {
            title: 'Стратегия',
            data: (getDict(strategyDict, item.strategyId)),
          },
          {
            title: 'Меры реагирования',
            data: item.response
          },
          {
            title: 'Статус риска',
            data: getDict(self.props.dict.riskStatus, item.statusId)
          },
        ]
      }})
    }
  },
]);

export default AgreementRiskBlock;
