import cx from 'classnames';
import i18n from "i18next";
import { round } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

import Spoiler from '@/components/Spoiler';
import Input from '@/elements/Input';
import DateRangeSelect from '@/elements/Input/DateRangeSelect';
import Select from '@/elements/Select';
import SkypFormProjectListModal, { normalizeSkupToServer }
  from '@/pages/CreateProject/Blocks/Components/SkypFormProjectListModal';
import service from '@/services';
import { isEmptyDate, isNotEmptyValues, updateByKeyGenerator } from '@/utils';

export const calcSkupProcentNum = (data) => !data || data.cnt === null || data.cnt === undefined
  ? null
  : round(data.cnt ? data.cntSuccess / data.cnt * 100 : 100);

export const calcSkupPercent = (data, endSymbol = '%') => {
  const percent = calcSkupProcentNum(data);

  return percent === null ? null : `${percent}${endSymbol}`;
};

const toSelect = (data) => data.map(({ id, name }) => ({
  label: name,
  value: id
}));

const SkypForm = ({ data = {
  projectId: undefined,
  regionId: undefined,
  workId: undefined,
  dateStart: undefined,
  dateEndPlan: undefined,
  dateEndFact: undefined,
  cntSuccess: undefined,
  cnt: undefined,
}, setData, disabled, required, updateIsDirty = undefined }) => {
  const modalProjectList = useRef(null);
  const [projects, setProjects] = useState([]);
  const [regions, setRegions] = useState([]);
  const [works, setWorks] = useState([]);
  const updateByKey = updateByKeyGenerator(setData);
  const updateData = (newData) => setData(prevData => ({
    ...prevData,
    ...newData
  }));

  const updateByKeyWrapper = (key, value, shouldUpdateDirty = true) => {
    updateByKey(key, value);
    if(updateIsDirty && shouldUpdateDirty) {
      updateIsDirty(true);
    }
  }

  const openProjectList = () => {
    modalProjectList.current.open(data);
  };

  useEffect(() => {
    service.get('/skup/projects').then(setProjects);
  }, []);

  useEffect(() => {
    if (!data.projectId) {
      setRegions([]);
      return;
    }

    service.get('/skup/regions', {
      projectId: data.projectId
    }).then(setRegions);
  }, [data.projectId]);

  useEffect(() => {
    if (!data.projectId || !data.regionId) {
      setWorks([]);
      return;
    }

    service.get('/skup/works', {
      projectId: data.projectId,
      regionId: data.regionId
    }).then(setWorks);
  }, [data.projectId, data.regionId]);

  useEffect(() => {
    if (!data.projectId || (isEmptyDate(data.dateEndPlan) && isEmptyDate(data.dateEndFact))) {
      return updateData({
        cnt: null,
        cntSuccess: null
      });
    }

    service.post('/skup/calcCnt', normalizeSkupToServer(data)).then(result => {
      updateData({
        ...result
      });
    });
  }, [data.projectId, data.regionId, data.workId, data.dateStart, data.dateEndPlan, data.dateEndFact]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Spoiler
        title={(<h3 className={cx('h3-felix', 'workList__contentTitle')}>Отслеживать выполнение по СКУП</h3>)}
        defaultState={isNotEmptyValues(regions)}
      >
        <div className="row">
          <div className="col-md-12">
            <Select
              tooltip
              options={toSelect(projects)}
              label={i18n.t('workDataRequest.skup.projectId')}
              value={data.projectId}
              onChange={(val) => {
                if (!val) {
                  updateData({
                    projectId: null,
                    regionId: null,
                    workId: null,
                  });
                } else {
                  updateByKeyWrapper('projectId', val);
                }
              }}
              isClearable={true}
              disabled={disabled}
            />
          </div>

          <div className="col-md-12">
            <Select
              required={data.projectId ? required : false}
              tooltip
              options={toSelect(regions)}
              label={i18n.t('workDataRequest.skup.regionId')}
              value={data.regionId}
              onChange={(val) => {
                if (!val) {
                  updateData({
                    regionId: null,
                    workId: null,
                  });
                } else {
                  updateByKeyWrapper('regionId', val);
                }
              }}
              isClearable={true}
              disabled={disabled}
            />
          </div>

          <div className="col-md-12">
            <Select
              required={data.projectId ? required : false}
              tooltip
              options={toSelect(works)}
              label={i18n.t('workDataRequest.skup.workId')}
              value={data.workId}
              onChange={(val) => updateByKeyWrapper('workId', val)}
              isClearable={true}
              disabled={disabled}
            />
          </div>

          <div className="col-md-12">
            <div className="row">
              {/* <div className={cx('col-md-6', 'workList__dateInput')}>
                <DateRangeSelect
                  label={i18n.t('workDataRequest.skup.dateStart')}
                  value={data.dateStart}
                  onChange={(val) => updateByKeyWrapper('dateStart', val)}
                  isClearable={true}
                  isHideIcon={true}
                  disabled={disabled}
                />
              </div> */}
              <div className={cx('col-md-6', 'workList__dateInput')}>
                <DateRangeSelect
                  tooltip
                  label={i18n.t('workDataRequest.skup.dateEndPlan')}
                  value={data.dateEndPlan}
                  onChange={(val) => updateByKeyWrapper('dateEndPlan', val)}
                  isClearable={true}
                  isHideIcon={true}
                  disabled={disabled}
                  isPortal={true}
                  portalScrollClasses=".main-panel, .gantt-edit__container"
                />
              </div>

              <div className={cx('col-md-6', 'workList__dateInput')}>
                <DateRangeSelect
                  tooltip
                  label={i18n.t('workDataRequest.skup.dateEndFact')}
                  value={data.dateEndFact}
                  onChange={(val) => updateByKeyWrapper('dateEndFact', val)}
                  isClearable={true}
                  isHideIcon={true}
                  disabled={disabled}
                  isPortal={true}
                  portalScrollClasses=".main-panel, .gantt-edit__container"
                />
              </div>

              <div className={cx('col-md-12', 'workList__dateInput')}>
                <Input
                  tooltip
                  label={i18n.t('workDataRequest.skup.cnt')}
                  value={data.cnt === null || data.cnt === undefined ? null : (`${data.cntSuccess} / ${data.cnt}`)}
                  onChange={() => { }}
                  disabled={true}
                />
              </div>

            </div>
          </div>

          <div className="col-md-12">
            <div
              className={cx('edit-button-felix', 'float-end', { 'disabled': !data.projectId })}
              onClick={openProjectList}
            >
              Посмотреть выборку
            </div>
          </div>

          <div className="col-md-12">
            <Input
              tooltip
              label={i18n.t('workDataRequest.skup.percent')}
              value={calcSkupPercent(data)}
              onChange={() => { }}
              disabled={true}
            />
          </div>

        </div>
      </Spoiler>
      <SkypFormProjectListModal ref={modalProjectList} data={data} />
    </>
  );
};

export default SkypForm;
