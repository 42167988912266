import React from 'react';
import { DeviceContext } from '@/context/DeviceContext';

export default function withDeviceQuery(WrappedComponent) {
  class ExtendedComponent extends React.Component<any, any> {
    render() {
      const { isDesktop, isSmallDesktop, isTablet, isMobile } = this.context;
      return (
        <WrappedComponent
          isDesktop={isDesktop}
          isSmallDesktop={isSmallDesktop}
          isTablet={isTablet}
          isMobile={isMobile}
          {...this.props}
        />
      );
    }
  };

  ExtendedComponent.contextType = DeviceContext;
  // @ts-ignore ts-migrate(2339) FIXME: Property 'displayName' does not exist on type 'typ... Remove this comment to see the full error message
  ExtendedComponent.displayName = `withDeviceQuery(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return ExtendedComponent;
}
