import React from "react"
import cx from 'classnames';

const ToggleButton = ({ isOpen }) => (
  <button className={cx("table-spoiler-botton", { open: isOpen })}>
    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.46809 6.53393L0.996094 1.06086L2.05686 0.000305176L6.99847 4.94289L11.9401 0.000305294L13.0008 1.06086L7.52885 6.53393C7.38819 6.67461 7.19741 6.75365 6.99847 6.75365C6.79953 6.75365 6.60874 6.67461 6.46809 6.53393Z" fill="#797E8B" />
    </svg>
  </button>
)

export default ToggleButton