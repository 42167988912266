import cx from 'classnames';
import i18n from "i18next";
import React, { useEffect, useRef, useState } from 'react';
import Spoiler from '@/components/Spoiler';
import { useDeviceContext } from '@/context/DeviceContext';
import Select from '@/elements/Select';
import { formatDate, prevent } from '@/utils';
import { cloneDeep, keys, sortBy, uniqBy } from 'lodash';
import { FORMAT_MONTH_YEAR } from "@/config/const";

const BudgetPredictonTableFilter = ({ onChange = undefined, orders = undefined }) => {
  const { isDesktop } = useDeviceContext();
  const fullOrders = useRef<any[]>(cloneDeep(orders));

  const [filter, setFilter] = useState({
    sysNumber: null,
    contractor: null,
    completionDate: null,
    r12code: null,
    description: null,
    dueDate: null,
    status: null,
  });

  useEffect(() => fullOrders.current = cloneDeep(orders), [orders]);

  const updateFilter = (key, value) => {
    setFilter((prevFilter) => {
      prevFilter[key] = value;
      onChange && onChange(prevFilter);
      return {...prevFilter};
    });
  }

  const getOptions = (accessor, formatter = undefined, valueFormatter = undefined, sorted = false) => {
    if (!fullOrders.current) {
      return [];
    }
    const options = uniqBy(fullOrders.current
      .filter(value => value[accessor])
      .map(value => ({value: valueFormatter ?  valueFormatter(value[accessor]) : value[accessor],
        label: formatter ? formatter(value[accessor]) : value[accessor]})), 'label');
    return options.length === 0 ? [] : sorted ? sortBy(options, 'label') : options;
  }

  const spoilerTitle = (
    <div>
      Фильтр
      {isDesktop && (
        <button className="link dashboard__card-title__reset"
                onClick={prevent(() => keys(filter).forEach(key => updateFilter(key, null)))}>
          Сбросить
        </button>
      )}
    </div>
  );

  const [
    docNumberOptions,
    contractorOptions,
    completionDateOptions,
    r12codeOptions,
    subjectDescriptionOptions,
    dueDateOptions,
    statusOptions,
  ] = [
    getOptions('sysNumber'),
    getOptions('contractor'),
    getOptions('completionDate',
      (val) => formatDate(val, FORMAT_MONTH_YEAR),
      (val) => formatDate(val, FORMAT_MONTH_YEAR)),
    getOptions('r12code'),
    getOptions('description'),
    getOptions('dueDate',
      (val) => formatDate(val, FORMAT_MONTH_YEAR),
      (val) => formatDate(val, FORMAT_MONTH_YEAR)),
    getOptions('status', null, null, true),
  ];

  return (
    <Spoiler
      title={spoilerTitle}
      defaultState={false}
      className={cx('filter', 'workList_filters')}
      contentClassName="workList_filters__content"
      titleClassName="workList_filters__title"
    >
      <div className={cx('row', 'workList_filters__container')}>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.docNumber')}
            value={filter.sysNumber}
            onChange={(val) => updateFilter('sysNumber', val)}
            options={docNumberOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.contractor')}
            value={filter.contractor}
            onChange={(val) => updateFilter('contractor', val)}
            options={contractorOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.completionDate')}
            value={filter.completionDate}
            onChange={(val) => updateFilter('completionDate', val)}
            options={completionDateOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.r12code')}
            value={filter.r12code}
            onChange={(val) => updateFilter('r12code', val)}
            options={r12codeOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.subjectDescription')}
            value={filter.description}
            onChange={(val) => updateFilter('description', val)}
            options={subjectDescriptionOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label={i18n.t('budgetPredictionOrderWeb.dueDate')}
            value={filter.dueDate}
            onChange={(val) => updateFilter('dueDate', val)}
            options={dueDateOptions}
            isClearable
          />
        </div>
        <div className={cx('col-md-4', 'workList_filters__item')}>
          <Select
            label='Статус'
            value={filter.status}
            onChange={(val) => updateFilter('status', val)}
            options={statusOptions}
            isClearable
          />
        </div>
      </div>
    </Spoiler>
  );
};

export default BudgetPredictonTableFilter;
