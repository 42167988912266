import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { LoadingMessage, NoOptionsMessage } from './SelectBase';

const SelectCreateble = ({
  options,
  value,
  onChange,
  isMulti = false,
  disabled = false,
  showUndefined = false, // заставляет селект показать значения если их нет в списке
  ...props
}, ref) => {
  return (
    <CreatableSelect
      ref={ref}
      components={{ LoadingMessage, NoOptionsMessage }}
      isMulti={isMulti}
      value={value}
      options={options}
      isDisabled={disabled}
      onChange={onChange}
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      {...props}
      formatCreateLabel={(val) => `Добавить значение: "${val}"`}
      placeholder={props.placeholder || ""}
    />
  );;
};

export default React.forwardRef(SelectCreateble);