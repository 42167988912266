import { useEffect, useRef } from "react";

// на основе
// https://github.com/agorf/react-use-sync-scroll

const syncScroll = (target, others, TopLeft, WidthHeight) => {
  const percentage =
    target[`scroll${TopLeft}`] /
    (target[`scroll${WidthHeight}`] - target[`offset${WidthHeight}`]);

  window.requestAnimationFrame(() => {
    others.forEach(el => {
      el.current[`scroll${TopLeft}`] = (
        percentage * (el.current[`scroll${WidthHeight}`] - el.current[`offset${WidthHeight}`])
      );
    });
  });
};

const syncVerticalScroll = (target, others) => {
  syncScroll(target, others, "Top", "Height");
};

const syncHorizontalScroll = (target, others) => {
  syncScroll(target, others, "Left", "Width");
};

const useSyncScroll = (refsRef, { vertical = true, horizontal = false } = {}, deps = []) => {
  const locksRef = useRef(0);

  useEffect(() => {
    if (refsRef.current.filter(el => el.current).length < 2) return;

    function handleScroll({ target }) {
      if (locksRef.current > 0) {
        locksRef.current -= 1; // Release lock by 1
        return;
      }

      locksRef.current = refsRef.current.length - 1; // Acquire lock

      const others = refsRef.current.filter(ref => ref !== target);

      if (vertical) syncVerticalScroll(target, others);
      if (horizontal) syncHorizontalScroll(target, others);
    }

    const elements = refsRef.current;
    elements.forEach(el => el.current.addEventListener("scroll", handleScroll));

    return () => {
      elements.forEach(el => el.current?.removeEventListener("scroll", handleScroll));
    };
  }, [refsRef, vertical, horizontal, locksRef, deps]);

  return (ref) => refsRef.current.push(ref);
};

export default useSyncScroll;