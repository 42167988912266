import React from 'react';
import Select from '@/elements/Select';
import i18n from 'i18next';
import UserCommandSearch from '@/pages/CreateProject/Command/components/UserCommandSearch';
import Input from '@/elements/Input';
import { NO_TEAM_KEY_SINGULAR } from '@/pages/CreateProject/Blocks/Components/ResponsibleForm';
import { getDictCodeById } from '@/utils';
import { useAppSelector } from '@/utils/typedHooks';
import { useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

export interface IAddEmployeeFieldsExtProps {
  inputExt?: IExtendableProps;
  infoExt?: IExtendableProps;
}

const AddEmployeeFields = (props) => {
  const extModule = useExtImport<IAddEmployeeFieldsExtProps>('pages/CreateProject/Command/AddEmployee/AddEmployeeFieldsExt');

  const {
    redRequired, data, handleChangeSelect, roleOptionsList, tagsForSelect, state, handleTagChange, employeeWorkTeams
  } = props;
  const { userLogin, roleId, powers } = data;

  const roleDict = useAppSelector(state => state.dict.roles);
  const roleCode = getDictCodeById(roleDict, roleId);
  const isShowPowers = roleCode === 'LEADER_WORKING_GROUP';

  return (
    <>
      <ExtendableWrapper {...extModule?.inputExt} props={props}>
        <Select
          required={redRequired}
          options={roleOptionsList}
          label={i18n.t('employeeDataCreate.roleId')}
          value={roleId}
          name="role"
          onChange={(val) => handleChangeSelect('roleId', val)}
        />

        <UserCommandSearch
          roleId={roleId}
          onChange={(val) => handleChangeSelect('userLogin', val)}
          value={userLogin}
          redRequired={redRequired}
        />

        <Select
          options={tagsForSelect}
          label={i18n.t('employeeDataCreate.tag')}
          value={state.tag}
          onChange={val => handleTagChange(val, false)}
          createble
        />

        {isShowPowers && (
          <Input
            required={redRequired}
            label={i18n.t('employeeDataCreate.powers')}
            value={powers}
            onChange={(e) => handleChangeSelect('powers', e.target.value)}
          />
        )}
      </ExtendableWrapper>

      <ExtendableWrapper {...extModule?.infoExt} props={props}>
        <div style={{display: 'flex', gap: '10px'}}>
          <div style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>
            {i18n.t('employeeDataCreate.workTeams')}:
          </div>
          {!employeeWorkTeams?.length && <div>{NO_TEAM_KEY_SINGULAR}</div>}
          {!!employeeWorkTeams?.length && <div>
            {employeeWorkTeams?.map(item =>
              <div>{item}</div>)}
          </div>}
        </div>
      </ExtendableWrapper>
    </>
  );
}

export default AddEmployeeFields;