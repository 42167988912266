import React from 'react';
import { Link } from 'react-router-dom';
import { getDict, isNotEmptyValues, NO_SPECIFIED } from '@/utils'
import cx from 'classnames'
import TeamLoadTable from '@/pages/CreateProject/Command/TeamLoadTable'
import { parseData } from '@/pages/CreateProject/Command/AddEmployee';
import i18n from "i18next";

export const isOverload = (load) => load?.find(item => item.sum > 100) || false;

const LoadCommandItem = ({ load, roleId, name, id, dictRoles, loadFact }) => {

  const getRole = (roleId) => getDict(dictRoles, roleId)

  return (
    <div className={cx('marginBottom-30', 'command_loadItem')}>
      <Link
        className={cx('h5-felix-medium', { red: isOverload(load) })}
        to={`../team/${id}`}
      >
        {getRole(roleId)} / {name}
      </Link>

      <hr />

      <div className="mb-3">
        <div>
          {i18n.t('employeeDataCreate.title.loadPlan')}
        </div>
        {load.length ? (
          <TeamLoadTable
            data={parseData(load)}
            readonly={true}
          />
        ) : (
          <div className="command_loadEmpty">{NO_SPECIFIED}</div>
        )}
      </div>

      <div>
        <div>
          {i18n.t('employeeDataCreate.title.loadFact')}
        </div>
        {isNotEmptyValues(loadFact) ? (
          <TeamLoadTable
            data={parseData(loadFact)}
            readonly={true}
          />
        ) : (
          <div className="command_loadEmpty">{NO_SPECIFIED}</div>
        )}
      </div>

      <hr />
    </div>
  )
}

export default LoadCommandItem;
