import cx from 'classnames';
import React from 'react';
import { SingleValueProps, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { getShortName, getUsersLdap } from '@/components/UserSearch';
import { LoadingMessage, NoOptionsMessage, Placeholder } from '@/elements/Select/SelectBase';
import { getStyles } from '@/elements/Select/SelectInTable';

const SingleValue = ({
  children,
  ...props
}: SingleValueProps<any>) => {
  // тут заменяем вывод компонента
  const val = getShortName(props.data.displayName);

  return (
    <components.SingleValue {...props}>{val}</components.SingleValue>
  );
};

const UserSearchInTable = ({
  loadOptions = getUsersLdap,
  widthInput = 80,
  widthMenu = 80,
  isHideDropdown = false,
  fontSize = undefined,
  ...props
}: any) => {
  const minLength = props.minLength === 0 ? 0 : props.minLength || 2;

  const onChange = (e: any) => {
    props.onChange(e);
  };

  const promiseOptions = (inputValue) => {
    if (inputValue.length <= minLength) {
      return Promise.resolve();
    }

    return loadOptions(inputValue, props);
  };

  const styles = React.useMemo(
    () => getStyles({
      widthInput, widthMenu, newTable: true, fontSize
    }),
    [widthInput, widthMenu, fontSize]
  );

  const components = { LoadingMessage, NoOptionsMessage, SingleValue, Placeholder };
  if (isHideDropdown) {
    components['DropdownIndicator'] = () => null;
  }

  return (
    <AsyncSelect
      {...props}
      minLength={minLength}
      className={cx(props.className)}
      cacheOptions
      components={components}
      defaultOptions={[props.defaultValue].filter((x) => x)}
      value={props.value}
      loadOptions={promiseOptions}
      onChange={onChange}
      isDisabled={props.disabled}
      isClearable={props.isClearable}
      openMenuOnFocus={true}
      placeholder={props.placeholder || ""}

      styles={styles}
      menuPosition={'fixed'}
      menuPortalTarget={document.body}
    />
  );
};


export default UserSearchInTable;