import service from '@/services';
import { keyBy } from 'lodash'

export const loadCommandSumLoad = async (data, employeeLoadList, loadList) => {
  const load = keyBy(loadList, (item) => [item.userId, item.date].join('_'));

  return data.map(item => {
    return {
      ...item,
      load: employeeLoadList?.filter(loadItem => loadItem.employeeId === item.id).map(loadItem => ({
        ...loadItem,
        sum: (load[[item.user.id, loadItem.date].join('_')] || {}).sum,
      }))
    }
  })
}

export const searchCommandProjectByProjectVersionId = (projectVersionId: number) =>
  service.get(`/employee/search/project/${projectVersionId}`);

export const getLoadListByEmployeeIds = (employeeIds: number[]) =>
  service.post(`/employee/load`, employeeIds);
