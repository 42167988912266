import { compact } from 'lodash';
import React from 'react';

import PromiseSearch from '@/components/PromiseSearch';
import services from '@/services';

export const getShortName = (name) => {
  if (!name) {
    return null;
  }

  const fio = name.split(' ');
  if (fio.length !== 3) {
    return name;
  }

  return [fio[0], fio[1][0] + '.', fio[2][0] + '.'].join(' ');
};

export const userSelect = (user: UserWeb, isShort = false, valueField = undefined) => {
  if (!user) {
    return {};
  }

  const shortName = getShortName(user.displayName);

  if (!user.isLdap) {
    return {
      id: user.id,
      value: valueField ? user[valueField] : user.login,
      label: isShort
        ? shortName
        : compact([user.displayName, user.department, user.post]).join(' / '),
      displayName: isShort ? shortName : user.displayName,
      isLdap: user.isLdap,
      functionCode: user.functionCode
    };
  }

  return {
    id: user.id,
    value: valueField ? user[valueField] : user.login,
    label: isShort
      ? shortName
      : (user.displayName + (user.department ? ` / ${user.department}` : '')),
    displayName: isShort ? shortName : user.displayName,
    isLdap: user.isLdap,
    functionCode: user.functionCode
  };
};

export const getUsersProject = (inputValue, props) => services.get(`/user/getByProjectRole`, {
  query: inputValue,
  role: props.role,
}).then(data => data.map((user: UserWeb) => ({
  value: user.id,
  label: user.displayName,
  displayName: user.displayName,
  functionCode: user.functionCode
})));

export const getUsersLdap = (inputValue, props) => services.get(`/user/${props.type || 'search'}`, {
  query: inputValue,
  role: props.role,
}).then(data => data.map(user => userSelect(user, props.isShort)));

const UserSearch = ({ isHide = false, searchFunc = getUsersLdap, ...props }: {
  isHide?: boolean
  onChange: (item: UserWeb) => void;
  [key: string]: any;
}) => {
  return !isHide && (
    <PromiseSearch loadOptions={searchFunc} {...props} />
  );
};

export default UserSearch;
