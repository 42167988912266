import i18n from "i18next";
import { getDictObj, importExt } from '@/utils'
import { sortBy } from 'lodash';
import { AgreementBlockExt } from '@/pages/CreateProject/Agreement/helpers';

const prepareResult = (self, data): any[] => {
  if (!data.budget) {
    return []
  }

  return sortBy(data.budget, 'r12Code').map((item, i) => {
    const type = getDictObj(self.props.dict.budgetSourceType, item.typeId);
    return {
      key: item.staticId,
      title: `Источник финансирования ${type?.name} ${i + 1}`,
      data: [
        {
          key: 'isForInfoOnly',
          title: i18n.t(`projectBudgetSourceWeb.isForInfoOnly`),
          data: item.isForInfoOnly
        },
        {
          key: 'r12Code',
          title: i18n.t(`projectBudgetSourceWeb.r12Code`),
          data: item.r12Code
        },
        {
          key: 'name',
          title: i18n.t(`projectBudgetSourceWeb.name`),
          data: item.name
        },
        {
          key: 'cfoId',
          title: i18n.t(`projectBudgetSourceWeb.cfoId`),
          data: ((data) => {
            const cfo = getDictObj(self.props.dict.cfo, data.cfoId);

            if (!cfo) {
              return;
            }

            return `${cfo.code} - ${cfo.name}`
          })(item)
        },
      ]
    }
  });
}

const AgreementBudgetDetailBlock = async (self) => {
  const extModule = await importExt('pages/CreateProject/Agreement/blocks/budgetDetailExt')
    .then(ext => ext?.(self)) as AgreementBlockExt;

  return [
    {
      multiple: true,
      generator: (data) => {
        let result = prepareResult(self, data);
        result = extModule?.updateResult(data, result) || result;
        return result;
      }
    },
  ]
}

export default AgreementBudgetDetailBlock;
