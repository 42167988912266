import './style.scss';

import React, { Fragment } from 'react'
import DocList, { IDocListProps } from './docList';
import SaveDoc, { ISaveDocProps } from './SaveDoc';
import { connect } from 'react-redux';
import { getDictByCode } from '@/utils';

interface DocProps extends ISaveDocProps, IDocListProps {
  componentType?: undefined | 'select' | 'button';
  isMobile?: boolean;
  hideFileType?: boolean;
  isEdit?: boolean;
  hideTitle?: boolean;
  disabledSave?: boolean;
  saveLabel?: string;
  uniqueByName?: boolean;
}

class DocBlock extends React.Component<DocProps, any> {
  docList: React.RefObject<any>;
  saveDoc: React.RefObject<any>;

  constructor(props) {
    super(props);

    this.saveDoc = React.createRef();
    this.docList = React.createRef();
  }

  sendFiles = (id: number, externalId?: any) => {
    if (!this.saveDoc.current) {
      return;
    }

    return this.saveDoc.current.sendFiles(id, externalId);
  }

  deleteFiles = (id: number, externalId?: any) => {
    if (!this.saveDoc.current) {
      return;
    }

    return this.saveDoc.current.deleteFiles(id, externalId);
  }

  getItems = () => {
    return this.saveDoc.current.getItems();
  }

  setItems = (items) => {
    return this.saveDoc.current.setItems(items);
  }

  reset = () => {
    return this.saveDoc.current?.reset();
  }

  getFilesInfo = (id?: number, externalId?: any) => {
    return this.docList.current.getFilesInfo(id, externalId);
  }

  isEmpty = () => {
    return this.saveDoc.current.isEmpty();
  }

  isEmptyWithOld = () => {
    return this.isEmpty() && this.docList.current.isEmpty();
  }

  isExists = (fileTypeCode) => {
    const currentSection = getDictByCode(this.props.dict.fileSection, this.props.typeCode);
    const fileType = this.props.dict.fileType.data.find(item => (!currentSection || item.section.id === currentSection.id)
      && item.code === fileTypeCode);

    return !!this.saveDoc.current.getItems()?.find(item => item.typeId === fileType.id)
      || !!this.docList.current.getData()[currentSection.id]?.find(item => item.typeId === fileType.id);
  }

  onStateChanged = () => {
    /*
    немного костыль, но прикол в том что ref не обновляется если поменялось состояние дочернего компонента
    и поэтому нужно как то всё таки сказать что чето изменилось
    в качестве примера проблемы:
      хотим отображать кнопку только когда выбрали хотя бы один файл
      если используем реф, то если написать просто ref.current.isEmpty() то ререндера не будет автоматически
      а вот если использовать колбек и стейт то всё норм, пример есть в
        src\pages\CreateProject\Mini\pages\Plan\PlanSpoiler.tsx
     */
    if (this.props._onStateChanged) {
      this.props._onStateChanged(this)
    }
  }

  render() {
    const { hideTitle } = this.props;
    return (
      <Fragment>
        <div className="file-add-felix marginBottom-20">
          <div className="file-add-felix">
            {!hideTitle && <h3 className="h3-felix">Сопроводительная документация</h3>}
            {this.props.isEdit &&
              <SaveDoc
                required={this.props.required}
                tooltip={this.props.tooltip}
                projectId={this.props.projectId}
                ppmRequestId={this.props.ppmRequestId}
                ref={this.saveDoc}
                label={this.props.saveLabel}
                disabled={this.props.disabledSave}
                typeCode={this.props.typeCode}
                externalId={this.props.otherId}
                afterUpdate={this.props.afterUpdate}
                _onStateChanged={this.onStateChanged}
                ignoreOwnerFiles={this.props.ignoreOwnerFiles}
                description={this.props.description}
                descriptionInOptions={this.props.descriptionInOptions}
                componentType={this.props.componentType}
                isMobile={this.props.isMobile}
                hideFileType={this.props.hideFileType}
                defaultTypeCode={this.props.defaultTypeCode}
              />
            }

            <DocList
              projectId={this.props.projectId}
              ppmRequestId={this.props.ppmRequestId}
              otherId={this.props.otherId}
              sectionCode={this.props.typeCode}
              isWithoutSection={true}
              isHideEmpty={this.props.isHideEmpty || this.props.isEdit}
              isAcceptDelete={this.props.isAcceptDelete}
              loadByProject={this.props.loadByProject}
              loadByPpmRequest={this.props.loadByPpmRequest}
              isRejectRemove={this.props.isRejectRemove}
              afterRemove={this.props.afterRemove}
              skipLoadCurrentFiles={this.props.skipLoadCurrentFiles}
              ref={this.docList}
              isOnlyProjectFiles={this.props.isOnlyProjectFiles}
              hideFileType={this.props.hideFileType}
              forceLoad={this.props.forceLoad}
              uniqueByName={this.props.uniqueByName}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProp = (state) => ({
  dict: state.dict,
});

export default connect(mapStateToProp, null, null, {forwardRef: true})(DocBlock);