import React, { useState } from "react";
import { useLocation, useNavigate, useParams, useResolvedPath, useSearchParams } from 'react-router-dom';

import ButtonRt from "@/components/Helper/ButtonRt";
import RouteModal from "@/components/SwitchRouter/RouteModal";

// пока не понимаю как получать текущий урл (нужен для ссылок), по этому такой костыль
export const useCurrentPath = () => useResolvedPath("").pathname;

/*
 показывает является ли текущий урл именно тем что мы должны отрендерить
 решает проблему отображения модальных окон в компоненте, когда мы рендерим компонент, 
 а в нем есть роутер и он рендерит модальные окна

 например урл /test
 для /test или /test/ будет да
 для /test/123 или /test/test/ будет нет
*/
export const useIsCurrentPath = () => {
  const { "*": route } = useParams();

  return !route;
};

// временное исправление пока не перепишу на функциональный компонент
export const withRouterParams = (Component: React.ElementType) => (props: any) => {
  const mathParams = useParams();

  return (
    <Component {...mathParams} {...props} />
  );
};

export const withNavitage = (Component: React.ElementType) => (props: any) => {
  const navigate = useNavigate();

  return (
    <Component navigate={navigate} />
  );
};

export const withRouter = (Component: React.ElementType) => (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Component location={location} navigate={navigate} searchParams={searchParams} {...props} />
  );
};

interface WithModalProps {
  withButton?: boolean;
  buttonOkLabel?: string;
  buttonCancelLabel?: string;
  title?: string;
  size?: string;
}
// обертка для отображения модальных окон
export const withModal = (Component: React.ElementType, {
  withButton = false,
  buttonOkLabel = 'Сохранить',
  buttonCancelLabel = "Отменить",
  title: titleStatic,
  size: sizeStatic,
}: WithModalProps = {}) => ({
  size,
  title,
  ...props
}: {
  title?: string;
  size?: string;
  [other: string]: any;
}) => {
    const history = useNavigate();
    const pathBase = useResolvedPath("..");
    const [onOk, setOnOk] = useState<any>();
    const onApply = async () => {
      if (onOk) {
        await onOk();
      }
      onHide();
    };

    const setApply = (func) => setOnOk(() => func);

    const onHide = () => {
      history(pathBase, { replace: true });
    };

    return (
      <RouteModal onHide={onHide} title={titleStatic || title} size={sizeStatic || size}>
        <Component {...props} onHide={onHide} _setApply={setApply} />

        {withButton && (
          <div className="report-lesson__buttons button-group">
            <ButtonRt label={buttonOkLabel} onClick={onApply} />
            <ButtonRt label={buttonCancelLabel} type="outline" onClick={onHide} />
          </div>
        )}
      </RouteModal>
    );
  };
