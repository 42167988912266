import InputTitle from '@/components/InputTitle'
import React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash'
import { prevent } from '@/utils';

const InputWithClear = React.forwardRef(({
  isClearable,
  onChange,
  ...props
}: any, ref) => {
  return (
    <div className="input-rt__container">
      <input autoComplete='off' {...props} ref={ref} onChange={onChange} value={props.value || ''} className={cx(props.className, 'inputField')} />
      {isClearable && !props.disabled && (props.value || '') && (
        <div className="input-rt__indicators">
          <div onClick={prevent(() => onChange({ target: { value: '' } }, ''))} aria-hidden="true" className="select-rt__indicator select-rt__clear-indicator">
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="select-rt__clear-indicator-svg">
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  )
})

const Input = ({
  isHide = false,
  titleClassName = undefined,
  isFilled = false,
  disabled = false,
  onChange,
  ...props
}: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void
    [other: string]: any
}) => {
  if (isHide) {
    return null;
  }

  const onChangeLocal = (e) => {
    if (isFunction(onChange)) {
      onChange(e, e.target.value);
    }
  }

  const {tooltip, ...inputWithClearProps} = props;

  return (
    <InputTitle
      required={props.required}
      isClearable={props.isClearable}
      tooltip={props.tooltip}
      label={props.label}
      value={props.value}
      isFilled={isFilled}
      description={props.description}
      className={cx(titleClassName, 'input-simple')}
      readonly={props.readonly}
      disabled={disabled}
    >
      <InputWithClear 
        {...inputWithClearProps}
        disabled={disabled} 
        onChange={onChangeLocal} 
      />
    </InputTitle>
  )
}

export default Input;
