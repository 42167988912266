import { randomNumber } from "@/pages/Dashboard2/utils";

export const addToast = (text, id = `UNDEFINED_${randomNumber()}`) => (dispatch) => {
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        id,
        text,
      },
    });
  };