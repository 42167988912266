import i18n from "i18next";
import React from 'react';
import TableDragable from '@/components/Gantt/components/TableDragable';
import { EditIcon, UpDownIcon } from '@/components/Gantt/elements/Icons';
import { getShortName } from '@/components/UserSearch';
import UserSearchInTable from '@/components/UserSearch/UserSearchInTable';
import { FORMAT_DATE } from '@/config/const';
import Input from '@/elements/Input';
import DateSelect from '@/elements/Input/DateSelect';
import { createEditable } from '@/components/TableBeta/utils';
import {
  formatDate, getDict, getDictObj, getProjectRoleOptionsList, parseDate, prevent
} from '@/utils';
import Tooltip from '@/elements/Tooltip';
import { Link } from "react-router-dom";
import { getPathByType } from "@/utils/project";
import SelectDict from "@/elements/Select/SelectDict";
import { useAppSelector } from "@/utils/typedHooks";
import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { dateStartClass } from '@/pages/CreateProject/Blocks/TableResultWorks';
import moment from 'moment/moment';

const dateEndClass = (cell, row, workStatusDict) => {
  if (!cell || !row?.statusId || !workStatusDict?.isLoad
    || !['MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(getDictObj(workStatusDict, row.statusId)?.code)) {
    return '';
  }

  const dateEndFact = row.dateFact ? parseDate(row.dateFact) : moment();

  return dateEndFact.diff(parseDate(row.datePlan), 'days') > 0 ? 'duration warn' : '';
};

const getColumns = ({
  readonly,
  isEditRight,
  projectData,
  setOpenMilestoneIndex,
  editRowId,
  dict,
}) => {
  const result: any[] = [];

  const isEditTable = !readonly && isEditRight;

  const getMinDate = () => {
    return projectData.dateStart;
  }

  const getMaxDate = () => {
    return projectData.dateEnd;
  }

  const dragTooltip = (children = undefined, delay = undefined) =>
    <Tooltip
      children={children}
      delay={delay}
      text={<span>
        <b>Потяните</b> стрелочки для интерактивного перемещения Контрольной точки<br/>
    </span>}
    />;

  if (!readonly && projectData.edit) {
    result.push({
      id: 'remove',
      Header: ({ props }) => {
        return (
          <input
            checked={props.isCheckedAll}
            onChange={() => prevent(props.onToggleCheckedAll(!props.isCheckedAll))}
            type="checkbox"
          />
        );
      },
      width: 30,
      Cell: ({ row, props }) => {
        return (
          <input
            checked={props.checked[row.original.rowNum] || false}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onToggleChecked(row.original.rowNum);
            }}
            type="checkbox"
            title="Выделить контрольную точку"
          />
        );
      }
    });
  }

  result.push({
    Header: "",
    width: 40,
    accessor: 'rowNum',
    Cell: ({value}) => value || '',
  });

  if (!readonly) {
    result.push({
      id: 'edit',
      Header: "",
      width: 24,
      className: 'icon-cell',
      Cell: ({row}) => {
        if (row.original.isExtreme) {
          return null;
        }
        return (
          <EditIcon className="link" onClick={prevent(() => {
            setOpenMilestoneIndex(row.original.rowNum);
          })}/>
        );
      }
    });
  }

  if (!readonly) {
    result.push({
      width: 24,
      className: 'icon-cell',
      id: 'dragable',
      Header: () => dragTooltip(),
      headerStyle: { paddingLeft: 0, paddingRight: 0 },
      Cell: ({row}) => {
        if (row.original.isExtreme) {
          return null;
        }
        return <div><UpDownIcon/></div>
      },
      draggable: ({ row }) => !row.original.isExtreme,
    });
  }

  result.push(...[
    {
      Header: () => (
        <div className="gantt-row-title__header">{i18n.t('ProjectMilestoneWeb.name')}</div>
      ),
      width: 300,
      accessor: 'name',
      resizable: true,
      Cell: (args) => {
        const { row, value } = args;
        const name = isEditTable && projectData.edit && !row.original.isExtreme
          ? (editRowId === row.id ? createEditable(Input, {})(args) : value)
          : <Link to={`/${getPathByType(projectData.projectTypeCode) || 'project'}/${projectData.id}/work/milestone/${row.original.id}`}>{value}</Link>;

        return (
          <span
            style={{
              paddingLeft: `${row.depth * 16}px`,
            }}
            className="gantt-row-title"
          >
            <div className="text" title={value}>
              {name}
            </div>
          </span>
        );
      },
    },
    {
      Header: i18n.t('ProjectMilestoneWeb.statusId'),
      width: 110,
      accessor: 'statusId',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original)
          || args.row.original.isFromOtherProject) {
          return <span className='gantt-row-text'>{getDict(dict.workStatus, args.row.original.statusId)}</span>
        }

        return createEditable(SelectDict, {
          dictName: 'workStatus',
          placeholder: 'Выберите статус',
          inTable: true,
          widthInput: 110,
          widthMenu: 120,
          fontSize: '12px',
          isHideDropdown: true,
          fastApplyData: true,
          filterFunc: item => item.isMilestone
        })(args);
      },
      dictType: 'workStatus'
    },
    {
      Header: i18n.t('ProjectMilestoneWeb.datePlan'),
      width: 80,
      accessor: 'datePlan',
      Cell: (args) => {
        const dateClass = cx(
          dateStartClass(args.value, args.row.original, dict.workStatus),
          dateEndClass(args.value, args.row.original, dict.workStatus),
        );

        if (!projectData.edit || !isEditTable || editRowId !== args.row.id || args.row.original.isExtreme) {
          const date = args.value;
          return <span className={dateClass}>
            {formatDate(date, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          isPortal: true,
          fastApplyData: true,
          minimumDate: getMinDate(),
          maximumDate: getMaxDate(),
          isClearable: true,
          isInTable: true,
          isHideIcon: true,
          isHideClear: true,
          className: dateClass,
        })(args);
      }
    },
    {
      Header: i18n.t('ProjectMilestoneWeb.dateFact'),
      width: 80,
      accessor: 'dateFact',
      Cell: (args) => {
        if (editRowId !== args.row.id || args.row.original.isExtreme) {
          const date = args.value;
          return <span className={dateEndClass(args.value, args.row.original, dict.workStatus)}>
            {formatDate(date, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          isPortal: true,
          fastApplyData: true,
          minimumDate: args.row.original.datePlan || getMinDate(),
          maximumDate: getMaxDate(),
          isClearable: true,
          isInTable: true,
          isHideIcon: true,
          isHideClear: true,
          className: dateEndClass(args.value, args.row.original, dict.workStatus),
        })(args);
      }
    },
    {
      Header: i18n.t('ProjectMilestoneWeb.datePlanBase'),
      width: 80,
      accessor: 'datePlanBase',
      Cell: (args) => {
        const date = args.value;
        return <span>
            {formatDate(date, FORMAT_DATE, null)}
          </span>;
      }
    },
    {
      Header: i18n.t('ProjectMilestoneWeb.responsible'),
      width: 140,
      accessor: 'responsible',
      Cell: (args) => {
        if (!projectData.edit || !isEditTable || editRowId !== args.row.id || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{getShortName(args.value?.displayName) || null}</span>
        }

        return createEditable(UserSearchInTable, {
          placeholder: "Выберите исполнителя",
          inTable: true,
          widthInput: 140,
          widthMenu: 150,
          fontSize: '12px',
          isShort: true,
          isHideDropdown: true,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.responsibleRoleId'),
      width: 120,
      accessor: 'responsibleRoleId',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{getDict(dict.roles, args.row.original.responsibleRoleId)}</span>
        }

        const projectTypeCode = getDictObj(dict.projectTypes, projectData.typeId)?.code;

        return createEditable(SelectDict, {
          customOptions: getProjectRoleOptionsList(dict.roles, projectTypeCode),
          placeholder: "Выберите роль",
          inTable: true,
          widthInput: 120,
          widthMenu: 130,
          fontSize: '12px',
          fastApplyData: true,
          isHideDropdown: true,
        })(args);
      }
    }
  ]);

  return result;
};

const MilestoneGanttTable = ({
  projectData,
  data,
  maxDepth,
  setData,
  openMilestoneIndex,
  setOpenMilestoneIndex,
  editRowId,
  setEditRowId,
  readonly,
  isEditRight,
  checked,
  setChecked,
  scrollContainer,
}) => {
  const onClickRow = (row, e) => {
    if (isEditRight && !readonly) {
      if (editRowId !== row.id) {
        setEditRowId(row.id);
      }
      return;
    }
    return true;
  };

  const onToggleChecked = (milestoneIndex: number) => {
    setChecked(prev => {
      return {
        ...prev,
        [milestoneIndex]: !prev[milestoneIndex]
      };
    });
  };

  const isCheckedAll = !isEmpty(data) && data.every(item => checked[item.rowNum]);

  const onToggleCheckedAll = (isChecked) => {
    setChecked(data.reduce((acc, item) => ({
      ...acc,
      [item.rowNum]: isChecked,
    }), {}));
  };

  const dict = useAppSelector(state => state.dict);

  const columns = React.useMemo(() => {
    return getColumns({
      readonly,
      isEditRight,
      projectData,
      setOpenMilestoneIndex,
      editRowId,
      dict,
    });
  }, [maxDepth, editRowId, projectData, checked, data]);

  return (
    <div className="gantt_table__container">
      <TableDragable
        data={data}
        link={[]}
        columns={columns}
        expandedLocal={{}}
        setExpanded={() => {}}
        expandAllClick={() => {}}
        isAllNotExpanded={true}
        setData={setData}
        rowProps={() => {}}
        readonly={readonly}
        isEditRight={isEditRight}
        onClickRow={onClickRow}
        scrollContainer={scrollContainer}
        disableDragModal
        ignoreWorkGroups
        onMove={data => data.forEach((item, index) => item.rowNum = index + 1)}
        props={{
          checked,
          onToggleChecked,
          isCheckedAll,
          onToggleCheckedAll,
          isAcceptEdit: !readonly && isEditRight && !openMilestoneIndex,
        }}
      />
    </div>
  );

};

export default MilestoneGanttTable;