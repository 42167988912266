import downloadjs from 'downloadjs'

const download = async (resp) => {
  const data = await resp.blob()
  const fileName = resp.headers.get('Content-Disposition').match("\"(.+)\"")[1]
  return downloadjs(data, fileName);
}

export {
  download
};

export default download;
