import cx from 'classnames';
import React from "react";

interface GanttItemConnectProps {
  type: 'left' | 'right';
}

const GanttItemConnect = React.forwardRef<HTMLDivElement, GanttItemConnectProps>(({ type }, ref) => {
  return (
    <div ref={ref} className={cx("gantt-item__connect", type)}></div>
  );
});

export default GanttItemConnect;