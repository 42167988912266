import cx from 'classnames';
import React, { CSSProperties } from 'react';

import ButtonWithLoader from '@/components/Helper/ButtonWithLoader';

interface MyProps {
  onClick: any;
  type?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  preventDefault?: any;
  ref?: any;
  tooltip?: string;
  style?: CSSProperties
}

export default class ButtonRt extends React.Component<MyProps, any> {
  isStop: any;

  constructor(props) {
    super(props);

    this.state = {
      sendState: '',
      stateTimeout: null
    };

    this.isStop = false;
  }

  updateStateButton = (newState) => {
    if (this.isStop) {
      return;
    }

    this.setState({
      sendState: newState
    });
  };

  updateStateTimeout = (timeoutId) => {
    this.setState((prevState) => {
      clearTimeout(prevState.stateTimeout);

      return {
        stateTimeout: timeoutId
      };
    });
  };

  handleClick = async (e) => {
    if (this.props.preventDefault) {
      e.preventDefault();
    }

    this.updateStateTimeout(setTimeout(() => {
      this.updateStateButton('loading');
    }, 150));

    try {
      let isShowFinished = true;

      if (this.props.onClick) {
        isShowFinished = await this.props.onClick(e);
      }

      if (!this.isStop) {
        this.updateStateTimeout(null);
        this.updateStateButton(isShowFinished === false || this.props.type === 'animation-disable' ? '' : 'finished');
      }

    } catch (e) {
      console.error(e);
      this.updateStateTimeout(null);
      this.updateStateButton('error');
    }

    if (!this.isStop) {
      this.updateStateTimeout(setTimeout(() => {
        this.updateStateButton('');
      }, 2000));
    }
  };

  componentWillUnmount() {
    this.isStop = true;
    clearTimeout(this.state.stateTimeout);
  }

  render() {
    const label = this.props.label || this.props.children;
    return (
      <ButtonWithLoader
        className={cx(this.props.className, this.props.type, this.state.sendState)}
        disabled={this.props.disabled || (this.state.sendState !== '')}
        onClick={this.handleClick}
        label={label}
        tooltip={this.props.tooltip}
        style={this.props.style}
      />
    );
  }
}
