import ButtonSimple from "@/elements/ButtonSimple";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import VersionForm from "@/pages/CreateProject/Version/VersionForm";
import ButtonRt from "@/components/Helper/ButtonRt";
import { ProjectSection } from "@/config/const";

interface IVersionFormModalProps {
  section?: ProjectSection;
}

const VersionFormModal = ({ section }: IVersionFormModalProps) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <ButtonSimple children="Посмотреть версии раздела" onClick={() => setIsShow(true)} />

      <Modal show={isShow} onHide={() => setIsShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Сравнение версий</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VersionForm section={section} isShowSectionFilter={false}/>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRt onClick={() => setIsShow(false)} type='outline'>
            Закрыть
          </ButtonRt>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VersionFormModal;