import React from "react";
import { useDragLayer } from "react-dnd";

import { CalendarType } from "@/components/Gantt/const";
import MilestoneGanttDragItem from "@/pages/CreateProject/Blocks/Milestone/Gantt/MilestoneGanttDragItem";

interface DragLayerProps {
  calendarType: CalendarType;
}

const MilestoneGanttDragLayer = ({ calendarType }: DragLayerProps) => {
  const { isDragging, item, itemType, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (!isDragging || !item || !item.data) {
    return null;
  }

  return (
    <div className='milestone-custom-drag-layer'>
      <MilestoneGanttDragItem
        item={item}
        initialOffset={initialOffset}
        currentOffset={currentOffset}
        calendarType={calendarType}
        itemType={itemType} />
    </div>
  );
};

export default MilestoneGanttDragLayer;