import React, { Fragment, useEffect, useState } from 'react';
import { useStateMemorizing } from "@/utils/hooks";

const StateSaver = ({setSaved, outerState}) => {
  const [data, setDataWithSave, restore] = useStateMemorizing(outerState);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    setDataWithSave(outerState);
  }, [outerState]);

  useEffect(() => {
    restore();
    setShouldUpdate(true);
  }, []);

  useEffect(() => {
    setSaved(data);
  }, [shouldUpdate])

  return <Fragment/>
};

export default StateSaver;
