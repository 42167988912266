import React from 'react';
import * as ReactDOM from 'react-dom';
import ModalAsync from '@/components/ModalAsync';
import ButtonRt from '@/components/Helper/ButtonRt';

const ButtonRtAccept = (props) => {
  const { onClick, children, buttonType, title = "Внимание", message } = props;
  const modalRef = React.useRef<any>();

  const openModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return modalRef.current.open().then((data) => {
      if (onClick) {
        return onClick(data);
      }

    }).catch((e) => {
      if (e === undefined) {
        return false;
      }

      throw e;
    })
  }

  return (
    <>
      <ButtonRt {...props} type={buttonType || 'outline'} onClick={openModal}>{children}</ButtonRt>
      { ReactDOM.createPortal((
        <ModalAsync title={title} ref={modalRef} okTitle="Да" cancelTitle="Нет">
          { message }
        </ModalAsync>
      ), document.body) }
    </>
  )
}

export default ButtonRtAccept;
