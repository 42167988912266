import React from 'react';
// @ts-ignore
import Image from '../../assets/images/Header/logo-felix.svg';
import { useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

const HeaderLogo = () => {
  const extModule = useExtImport<IExtendableProps>('pages/Main/HeaderLogoExt');

  return (
    <ExtendableWrapper {...extModule}>
      <div style={{
        width: '130px',
        height: '44px',
        background: 'url(' + Image + ') no-repeat center',
        backgroundSize: 'contain',
      }}/>
    </ExtendableWrapper>
  );
}

export default HeaderLogo;