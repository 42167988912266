import React, { useState } from 'react';
import { useShowModal } from '@/utils/hooks';

function hideText(text, textLimit) {
  var str = text.slice(0, textLimit);
  var a = str.split(' ');
  a.splice(a.length - 1, 1);
  str = a.join(' ');
  return (str + '... ');
}

export const textToHtml = (text) => {
  if (!text) {
    return '';
  }

  const rows = text.split('\n');

  return rows.map((row, i) => (<span key={i}>{row}{i !== rows.length - 1 && (<br/>)}</span>));
}

interface ITextHideProps {
  text: string;
  limit: number;
  TextWrapper?: any;
  wrapperProps?: any;
  withModal?: boolean;
  modalTitle?: string;
}

function TextHide({ text, limit, TextWrapper, wrapperProps, withModal, modalTitle = 'Полный текст' }: ITextHideProps) {
  const [isHide, setHideState] = useState(true);
  const showModal = useShowModal();

  const onHideClick = (e) => {
    e.preventDefault();
    if (withModal) {
      showModal(text, false, { title: modalTitle });
    } else {
      setHideState(!isHide);
    }
  }

  const Wrapper = TextWrapper || 'span';

  return (
    <>
      <Wrapper { ...wrapperProps }>{isHide && text?.length > limit ? textToHtml(hideText(text, limit)) : textToHtml(text)}</Wrapper>
      {text?.length > limit && (
        <>
          &nbsp;
          <button className="link textButton" onClick={onHideClick}>
            {isHide ? 'Далее' : 'Скрыть'}
          </button>
        </>
      )}
    </>
  )
}

export default TextHide;
