export type IHistoryState = {
  [key: number]: IProjectHistoryState;
}

export interface IProjectHistoryState extends IProjectHistoryData {
  isEnabled: boolean;
  versions: VersionHistory[];
}

export interface IProjectHistoryData {
  firstVersionId: number;
  secondVersionId: number;
  isShowGanttDateEndInit: boolean;
}

export const HISTORY_SET_VERSIONS = 'HISTORY_SET_VERSIONS';
export const HISTORY_SET_DATA = 'HISTORY_SET_DATA';

export const ProjectHistory = (state: IHistoryState = {}, action) => {
  switch (action.type) {
    case HISTORY_SET_VERSIONS:
      return {
        ...state,
        [action.projectId]: {
          isEnabled: false,
          firstVersionId: null,
          secondVersionId: null,
          isShowGanttDateEndInit: false,
          ...state[action.projectId],
          versions: action.versions,
        },
      };
    case HISTORY_SET_DATA:
      return {
        ...state,
        [action.projectId]: {
          versions: [],
          ...state[action.projectId],
          firstVersionId: action.firstVersionId,
          secondVersionId: action.secondVersionId,
          isShowGanttDateEndInit: action.isShowGanttDateEndInit,
          isEnabled: !!action.firstVersionId && !!action.secondVersionId,
        },
      }
    default:
      return state;
  }
};
