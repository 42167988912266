import React, { CSSProperties, useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonRt from "@/components/Helper/ButtonRt";
import service from "@/services";
import TextArea from "@/elements/TextArea";

const rework = (statusId, reworkComment) => service.put(`/status-report/rework/${statusId}`,
  {reworkComment: reworkComment});

const SendToReworkBti = ({statusReport, goBack = undefined, pullStatus = undefined, style = undefined, className = undefined}
  : {statusReport: any, goBack ?: any, pullStatus?: any, style?: CSSProperties, className?: string}) => {

  const [isShow, setIsShow] = useState(false);
  const [reworkComment, setReworkComment] = useState(null);
  const open = () => {
    setIsShow(true);
  }
  const close = () => setIsShow(false);

  const onRework = async () => {
    try {
      await rework(statusReport.id, reworkComment);
      pullStatus && await pullStatus(statusReport.id);
      goBack && goBack();
    } catch (e) {
      pullStatus && await pullStatus(statusReport.id);
      goBack && goBack();
    }
  };

  return (
    <>
      <ButtonRt className={className}
                style={style}
                onClick={open}
                type="outline">На доработку</ButtonRt>
      <Modal
        onHide={close}
        show={isShow}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >
        <Modal.Header closeButton style={{borderBottom: '0px'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="h3">Возварт на доработку</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingTop: '0px'}}>
          <div>{'Необходимо ввести комментарий отклонения'}</div>

          <TextArea
            required
            label={'Комментарий'}
            value={reworkComment}
            onChange={(_, val) => setReworkComment(val)}
          />

        </Modal.Body>
        <Modal.Footer>
          <div className="mb-3 form-group button-felix-group">
            <ButtonRt disabled={!reworkComment} onClick={onRework}>Отправить</ButtonRt>
            <ButtonRt type="outline" onClick={close}>Отмена</ButtonRt>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendToReworkBti;