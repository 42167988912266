import React from 'react';
import Marquee from "react-fast-marquee";
import { useAppSelector } from '@/utils/typedHooks';
import { isEmpty } from 'lodash';

const AppMessage = () => {
  const messages = useAppSelector(state => state.AppMessage.msgList);

  return isEmpty(messages) ? null : (
    <div className='app-message-line'>
      <Marquee
        delay={3}
      >
        <span style={{color: 'red'}}>{messages.join(' ')}</span>
      </Marquee>
    </div>
  );
}

export default AppMessage;