import React from 'react';
import moment from 'moment';
import DateRangeSelect, { isEndFilling, isRange } from '@/elements/Input/DateRangeSelect';
import { FORMAT_MONTH_YEAR } from '@/config/const';

const formatDateRange = (value) => {
  if (value && !isRange(value)) {
    return moment([value.year, value.month - 1, value.day]).format(FORMAT_MONTH_YEAR)
  }

  if (value && !isEndFilling(value)) {
    return 'Завершите выбор';
  }

  if (!value || !value.from || !value.to) {
    return '';
  }

  const { from, to } = value;
  return moment([from.year, from.month - 1, from.day]).format(FORMAT_MONTH_YEAR)
    + ' - '
    + moment([to.year, to.month - 1, to.day]).format(FORMAT_MONTH_YEAR)
}

const MonthYearPicker = (props) => {
  return (
    <DateRangeSelect
      picker="month"
      formatDateRange={formatDateRange}
      {...props}
    />
  );
}

export default MonthYearPicker;