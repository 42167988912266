import React from "react";

import { SAUSAGE_HEIGHT } from "@/components/Gantt/const";
import cx from "classnames";

const GanttLineItem = ({ from, to, type, simple = false, cpm = false, hide = false }) => {
  const fromStart = type.from ? -1 : 1;
  const toStart = type.to ? -1 : 1;
  const isUp = to.y > from.y ? -1 : 1;

  const points = [];

  if (simple) {
    points.push(
      from.x + 3 * fromStart, from.y,
      to.x, to.y,
    );
  } else {
    points.push(
      from.x + 3 * fromStart, from.y,
      from.x + 20 * fromStart, from.y,
      from.x + 20 * fromStart, to.y + (SAUSAGE_HEIGHT * 0.5) * isUp - 1,
      to.x + 20 * toStart, to.y + (SAUSAGE_HEIGHT * 0.5) * isUp - 1,
      to.x + 20 * toStart, to.y,
      to.x + 4 * toStart, to.y,
      to.x + 8 * toStart, to.y - 3,
      to.x + 8 * toStart, to.y + 3,
      to.x + 4 * toStart, to.y,
    );
  }

  return (
    <polyline points={points.join()} className={cx({CPM: cpm})} style={hide ? {display: 'none'} : {}}/>
  );
};

export default GanttLineItem;