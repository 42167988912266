import i18n from "i18next";
import { getDict, getDictObj } from '@/utils'

const AgreementGoalBlock = async (self) => ([
  {
    key: 'background',
    title: i18n.t('projectVersion.background'),
    data: (data) => data.background
  },
  { 
    key: 'alternativeDoNothing',
    title: i18n.t('projectVersion.alternativeDoNothing'),
    data: (data) => data.alternativeDoNothing
  },
  {
    key: 'alternativeImplementationMin',
    title: i18n.t('projectVersion.alternativeImplementationMin'),
    data: (data) => data.alternativeImplementationMin
  },
  {
    key: 'alternativeImplementation',
    title: i18n.t('projectVersion.alternativeImplementation'),
    data: (data) => data.alternativeImplementation
  },
  {
    key: 'alternativeJustification',
    title: i18n.t('projectVersion.alternativeJustification'),
    data: (data) => data.alternativeJustification
  },
  {
    multiple: true,
    generator: (data) => {
      if (!data.goals) {
        return [];
      }

      return data.goals.map((goal, i) => ({
        key: `goal_${i}`,
        title: `Цель ${i + 1}`,
        data: goal
      }))
    }
  },
  {
    multiple: true,
    generator: (data) => {
      if (!data.tasks) {
        return [];
      }

      return data.tasks.map((task, i) => ({
        key: `task_${i}`,
        title: `Задача ${i + 1}`,
        data: task
      }))
    }
  },
  {
    multiple: true,
    generator: (data) => {
      if (getDictObj(self.props.dict.stage, data.stageId)?.code !== "CLOSE") {
        return [];
      }

      return [
        {
          key: 'reportCloseGoal',
          title: i18n.t('projectVersion.reportCloseGoal'),
          data: data.reportCloseGoal
        },
        {
          key: 'reportCloseTask',
          title: i18n.t('projectVersion.reportCloseTask'),
          data: data.reportCloseTask
        },
        {
          key: 'reportCloseGradeId',
          title: i18n.t('projectVersion.reportCloseGradeId'),
          data: getDict(self.props.dict.reportCloseGrade, data.reportCloseGradeId)
        },
      ]
    }
  },
])

export default AgreementGoalBlock;
