import { getDict } from '@/utils'
import { isOverload } from '@/pages/CreateProject/Command/LoadCommandItem'
import cx from 'classnames';
import { sortBy } from 'lodash'
import moment from 'moment';
import { FORMAT_MONTH_YEAR } from '@/config/const';

const AgreementEmployeesBlock = async (self) => ([
  {
    multiple: true,
    generator: (data) => {
      if (!data.employee) {
        return []
      }

      const empl = data.employee.map(item => ({
        key: `${item.roleId}_${item.user.id}`,
        title: getDict(self.props.dict.roles, item.roleId),
        data: {
          value: item.user.displayName,
          data: item.load
            ? sortBy(item.load.map(load => ({ title: load.date, data: load.value })), 'title')
              .map(item => ({ title: moment(item.title).format(FORMAT_MONTH_YEAR), data: item.data }))
            : []
        },
        className: cx({ red: isOverload(item.load) }),
      }));

      return sortBy(empl, item => item.title);
    }
  },
])

export default AgreementEmployeesBlock;
