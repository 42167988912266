import React from "react";
import { XYCoord } from "react-dnd";

import { ItemTypes, LinkType, LinkTypes, LinkTypesData, SAUSAGE_HEIGHT } from "@/components/Gantt/const";
import GanttLineItem from "@/components/Gantt/elements/GanttLineItem";

const fixOffset = (offset: XYCoord, type: LinkType) => {
  return {
    x: offset.x + (type.from ? 16 : 0),
    y: offset.y + SAUSAGE_HEIGHT * 0.5 - 5,
  };
};

const CustomDragGanttLink = ({ itemType, initialOffset, currentOffset }) => {
  if (!initialOffset || !currentOffset) {
    return null;
  }

  const type = itemType === ItemTypes.CONNECT_LEFT
    ? LinkTypesData[LinkTypes.START_TO_START]
    : LinkTypesData[LinkTypes.END_TO_START];

  return (
    <svg className="gantt-link">
      <GanttLineItem
        from={fixOffset(initialOffset, type)}
        to={currentOffset}
        type={type}
        simple
      />
    </svg>
  );
};

export default CustomDragGanttLink;