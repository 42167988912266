import React, { useEffect } from 'react';
import { PROJECT_NAME_TITLE } from '@/config/const';
import cx from 'classnames';

export interface PageTitleProps {
  children: React.ReactNode,
  pageTitle?: string,
  isHide?: boolean
  className?: string
}

// https://stackoverflow.com/a/60565322
const getNodeText = node => {
  if (['string', 'number'].includes(typeof node)) return node
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node) return getNodeText(node.props.children)
}

const PageTitle = ({
  children,
  pageTitle = undefined,
  isHide = false,
  className
}: PageTitleProps) => {
  useEffect(() => {
    document.title = pageTitle || (`${PROJECT_NAME_TITLE} - ${getNodeText(children)}`);
  }, [pageTitle, children])

  if (isHide) {
    return null;
  }

  return (
    <h1 className={cx("h1-felix", className)}>{children}</h1>
  );
};

export default PageTitle;
