import React from 'react';
import service from '@/services';
import { isEmpty, isFunction } from 'lodash'
import i18n from "i18next";
import { loadCommandSumLoad } from '@/actions/getCommandProject'
import teamHead from './blocks/team';
import employeesHead from './blocks/employees';
import budgetHead from './blocks/budget';
import oibdaHead from './blocks/oibda';
import capexHead from './blocks/capex';
import riskHead from './blocks/risk';
import kpiHead from './blocks/kpi';
import kpiYearsWeightHead from './blocks/kpiYearsWeight';
import mboHead from './blocks/mbo';
import lessonHead from './blocks/lesson';
import workHead from './blocks/work';
import goalHead from './blocks/goal';
import generalHead from './blocks/general';
import budgetDetailHead from './blocks/budgetDetail';
import quantitativeIndicatorsHead from './blocks/quantitativeIndicators';
import qualitativeIndicatorsHead from './blocks/qualitativeIndicators';
import noBenefitStatementHead from './blocks/noBenefitStatement';
import { Dict } from '@/reducers/Dictionaries';
import { LinkIcon } from "@/pages/CreateProject/BasicNav/Icon";
import { Link } from "react-router-dom";
import { getDictByCode, getDictCodeById } from '@/utils';
import Block from "@/pages/CreateProject/Agreement/Block";
import { ProjectSection } from "@/config/const";
import MilestoneBlock from "@/pages/CreateProject/Agreement/blocks/milestone";
import { getBasicNewProjectData } from "@/actions/getBasicNewProjectData";
import { connect } from "react-redux";
import Loader from '@/components/Loader';

interface AgreementCardProps {
  dict: Dict;
  isDiff: boolean;
  activeVersionId: number;
  newVersionId?: number;
  isOnlyUpdate?: boolean;
  sections?: number[];
  availSections?: number[];
  isFirstAgree?: boolean;
  newProjectData?: Project
}

class AgreementCard extends React.Component<AgreementCardProps, any> {

  docList: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      tableTeamHead: [],
      tableEmployeesHead: [],
      tableBudgetHead: [],
      tableOibdaHead: [],
      tableCapexHead: [],
      tableOpexHead: [],
      tableFotHead: [],
      tableBudgetDetailtHead:[],
      tableRiskHead: [],
      tableKpiHead: [],
      tableKpiYearsWeightHead: [],
      tableMboHead: [],
      tableLessonHead: [],
      tableWorkHead: [],
      tableGoalHead: [],
      tableHead: [],
      quantitativeIndicatorsHead: [],
      qualitativeIndicatorsHead: [],
      noBenefitStatementHead: [],
      tableMilestoneHead: [],
      newData: {},
      oldData: {},
      loadCounter: 0,
    }

    this.docList = React.createRef();
  }

  getData = (f, data) => {
    return f(data)
  }

  getGeneral = (data, state) => {
    if (!data) {
      return [];
    }

    return data.reduce((acc, item) => {
      if (item.multiple) {
        acc.push(...item.generator(state).map(element => ({
          ...element,
          title: isFunction(element.title) ? element.title : element.title.trim()
        })));
      } else {
        acc.push({
          key: item.key,
          title: item.title.trim(),
          data: this.getData(item.data, state)
        })
      }

      return acc;
    }, [])
  }

  loadDataAll = (projectVersionId, sections) => service.post(`/project/agree/data/${projectVersionId}`, sections);

  availSectionsCodes = () => isEmpty(this.props.availSections)
    ? Object.keys(ProjectSection)
    : this.props.availSections.map(sectionId => getDictCodeById(this.props.dict.agreeSection, sectionId));

  loadProjectById = async (stateName, id) => {
    if (!id) {

      return Promise.resolve();
    }

    this.setState(oldState => ({ loadCounter: oldState.loadCounter + 1 }));
    const otherData = await this.loadDataAll(id, this.availSectionsCodes())
      .then(async data => {
        return {
          ...data.project,
          parentProgramName: data.parentProgramName,
          parentPortfolioName: data.parentPortfolioName,
          parentProjectName: data.parentProjectName,
          risk: data.risk,
          work: data.work,
          kpi: data.kpi,
          kpiYearsWeight: data.kpiYearsWeight,
          mbo: data.mbo,
          budget: data.budget,
          oibda: data.oibda,
          lesson: data.lesson,
          employee: data.loadList ? await loadCommandSumLoad(data.employee, data.employeeLoadList, data.loadList) : null,
          quantitativeIndicators: data.quantitativeIndicators,
          qualitativeIndicators: data.qualitativeIndicators,
          noBenefitStatement: data.noBenefitStatement,
          milestone: data.milestone
        }
    });

    this.setState((oldState) => ({
      [stateName]: {
        ...oldState[stateName],
        ...otherData
      },
      loadCounter: oldState.loadCounter - 1,
    }));

    return otherData;
  }

  loadState = async () => this.setState({
    tableTeamHead: await teamHead(this),
    tableEmployeesHead: await employeesHead(this),
    tableBudgetHead: await budgetHead(this),
    tableOibdaHead: await oibdaHead(this),
    tableCapexHead: await capexHead(this, 'CAPEX'),
    tableOpexHead: await capexHead(this, 'OPEX'),
    tableFotHead: await capexHead(this, 'FOT'),
    tableBudgetDetailtHead: await budgetDetailHead(this),
    tableRiskHead: await riskHead(this),
    tableKpiHead: await kpiHead(this),
    tableKpiYearsWeightHead: await kpiYearsWeightHead(this),
    tableMboHead: await mboHead(this),
    tableLessonHead: await lessonHead(this),
    tableWorkHead: await workHead(this),
    tableGoalHead: await goalHead(this),
    tableHead: await generalHead(this),
    quantitativeIndicatorsHead: await quantitativeIndicatorsHead(this),
    qualitativeIndicatorsHead: await qualitativeIndicatorsHead(this),
    noBenefitStatementHead: await noBenefitStatementHead(this),
    tableMilestoneHead: await MilestoneBlock(this),
  });

  componentDidMount() {
    this.loadState();

    if (this.props.isDiff) {
      this.loadProjectById('newData', this.props.activeVersionId);
      this.loadProjectById('oldData', this.props.newVersionId);
    } else {
      this.loadProjectById('newData', this.props.newVersionId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.sections !== prevProps.sections) {

    }
    if (this.props.isDiff) {
      if (this.props.activeVersionId !== prevProps.activeVersionId) {
        this.loadProjectById('newData', this.props.activeVersionId);
      }
      if (this.props.newVersionId !== prevProps.newVersionId) {
        this.loadProjectById('oldData', this.props.newVersionId);
      }
    } else {
      if (this.props.newVersionId !== prevProps.newVersionId) {
        this.loadProjectById('newData', this.props.newVersionId);
      }
    }
  }

  hasSection = (sectionCode) =>
    isEmpty(this.props.sections) || this.props.sections.includes(getDictByCode(this.props.dict.agreeSection, sectionCode)?.id);

  getNewMboTitle = (oldTitle) => {
    const projectData = this.state.oldData;
    if (!projectData || isEmpty(projectData.mbo)) {
      return oldTitle;
    }

    const mbo = projectData.mbo.find(m => m.goal === oldTitle);
    if (!mbo) {
      return oldTitle;
    }

    return (
      <div>
        {oldTitle}
        <Link to={`/${i18n.t('base')}/${mbo.projectVersionId}/mbo/${mbo.id}`} >
          <LinkIcon className="link-icon" />
        </Link>
      </div>
    )
  }

  render() {
    const { isOnlyUpdate, isDiff } = this.props;

    if (this.state.loadCounter > 0) {
      return (
        <div className="passport-info">
          <Loader isMini/>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="passport-info">
          <div>
            {this.hasSection(ProjectSection.COMMON) && (
              <Block title="Общее"
                     data={this.getGeneral(this.state.tableHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableHead, this.state.oldData)}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}

            {this.hasSection(ProjectSection.BUSINESS_CASE) && (
              <>
                <Block title='Бизнес-кейс'
                       data={this.getGeneral(this.state.tableGoalHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableGoalHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Количественные показатели"
                       data={this.getGeneral(this.state.quantitativeIndicatorsHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.quantitativeIndicatorsHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Качественные показатели"
                       data={this.getGeneral(this.state.qualitativeIndicatorsHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.qualitativeIndicatorsHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Заявление об отсутствии измеримых выгод"
                       data={this.getGeneral(this.state.noBenefitStatementHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.noBenefitStatementHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />
              </>
            )}

            {this.hasSection(ProjectSection.EMPLOYEES) && (
              <>
                <Block title="Команда"
                       data={this.getGeneral(this.state.tableTeamHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableTeamHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Роли"
                       data={this.getGeneral(this.state.tableEmployeesHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableEmployeesHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       subDataKeyDiff='title'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />
              </>
            )}

            {this.hasSection(ProjectSection.RISKS) && (
              <Block title="Риски"
                     data={this.getGeneral(this.state.tableRiskHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableRiskHead, this.state.oldData)}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     isGroup={true}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}

            {this.hasSection(ProjectSection.BUDGET) && (
              <>
                <Block title='Информация о бюджете'
                       data={this.getGeneral(this.state.tableBudgetHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableBudgetHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="OIBDA (тыс. руб.)"
                       data={this.getGeneral(this.state.tableOibdaHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableOibdaHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Общий CAPEX (тыс. руб., без НДС)"
                       data={this.getGeneral(this.state.tableCapexHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableCapexHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Общий OPEX (тыс. руб., без НДС)"
                       data={this.getGeneral(this.state.tableOpexHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableOpexHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Общий ФОТ (тыс. руб.)"
                       data={this.getGeneral(this.state.tableFotHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableFotHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       keyDiff='key'
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />

                <Block title="Бюджет и финансирование"
                       data={this.getGeneral(this.state.tableBudgetDetailtHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableBudgetDetailtHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />
              </>
            )}

            {this.hasSection(ProjectSection.WORKS)
              && this.props.newProjectData?.projectWorkAgreeTypeCode === 'BY_MILESTONE'
              && (
              <Block title="График работ - контрольные точки"
                     data={this.getGeneral(this.state.tableMilestoneHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableMilestoneHead, this.state.oldData)}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     isGroup={true}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}

            {this.hasSection(ProjectSection.WORKS)
              && this.props.newProjectData?.projectWorkAgreeTypeCode === 'BY_WORK'
              && (
              <Block title="График работ - детальный"
                     data={this.getGeneral(this.state.tableWorkHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableWorkHead, this.state.oldData)}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     isGroup={true}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}

            {this.hasSection(ProjectSection.KPI) && (
              <>
                <Block title="КПЭ"
                       data={this.getGeneral(this.state.tableKpiHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableKpiHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       isGroup={true}
                       isFirstAgree={this.props.isFirstAgree}
                />
                <Block title="Вес КПЭ по годам, %"
                       data={this.getGeneral(this.state.tableKpiYearsWeightHead, this.state.newData)}
                       dataSecond={this.getGeneral(this.state.tableKpiYearsWeightHead, this.state.oldData)}
                       isOnlyUpdate={isOnlyUpdate}
                       isDiff={isDiff}
                       isFirstAgree={this.props.isFirstAgree}
                />
              </>
            )}

            {this.hasSection(ProjectSection.MBO) && (
              <Block title="МВО"
                     data={this.getGeneral(this.state.tableMboHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableMboHead, this.state.oldData)}
                     newTitle={this.getNewMboTitle}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     isGroup={true}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}

            {this.hasSection(ProjectSection.LESSONS) && (
              <Block title="Извлеченные уроки"
                     data={this.getGeneral(this.state.tableLessonHead, this.state.newData)}
                     dataSecond={this.getGeneral(this.state.tableLessonHead, this.state.oldData)}
                     isOnlyUpdate={isOnlyUpdate}
                     isDiff={isDiff}
                     keyDiff="key"
                     isGroup={true}
                     isFirstAgree={this.props.isFirstAgree}
              />
            )}
          </div>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProp = (state) => ({
  newProjectData: state.NewProject.newProjectData
});

const mapDispatchToProps = (dispatch) => ({
  getNewProjectData: (id) => dispatch(getBasicNewProjectData(id, () => { })),
});

export default connect(mapStateToProp, mapDispatchToProps)(AgreementCard);
