import { get } from "lodash";
import { WorkStatus, WorkType } from '@/config/const';
import { getDictObj, isEmptyValues, parseDate, selectDateToMoment } from '@/utils';
import { numericWorks } from "@/components/Gantt/util/historyUtil";
import { groupWorks } from "@/components/Gantt/util/utils";
import moment from 'moment/moment';
import { DictDataItem } from '@/reducers/Dictionaries';

const groupBlock = (data: Work[]): GanttTableItem[] => {
  const groupedWorks = groupWorks(data);
  numericWorks(groupedWorks);
  return groupedWorks;
};

const flattenBlock = (data: GanttTableItem[], acc: GanttTableItem[] = []): Work[] => {
  data.forEach(work => {
    acc.push({
      ...work,
      subRows: undefined
    });

    if (checkGroup(work) && work.subRows) {
      flattenBlock(work.subRows, acc);
    }
  });

  return acc;
};

/*
если кто то скажет что это странная шутка, то не пугайтесь, это норм
идея в том что мы группирвем блоки работ по их группам, получается список групп+обычные блоки не входящие в гуппу
(или группы по какой то причине нет)
а потом мы этот сгруппированный список разворачиваем в обычный список без групп
тогда сохраняется исходная сторитровка, но блоки работ входящие в группу будут идти за этой группой
*/
export const workGroupSort = (data: Work[]): Work[] => {
  return flattenBlock(groupBlock(data));
};

export const getProjectTypeTextNominative = projectType => ({
  PROJECT: 'Проект',
  PROGRAM: 'Программа',
  PORTFOLIO: 'Портфель'
}[projectType]);

const getProjectTypeCode = (typesDict, typeId) => {
  return get(getDictObj(typesDict, typeId), 'code');
};

export const getWorkNameWithProject = (work: Work, typesDict: any) => {
  return `${work.name} (${getProjectTypeTextNominative(getProjectTypeCode(typesDict, work.projectTypeId))} "${work.projectVersionName}")`;
};

export const getWorkUrl = (work: Work, dict: any) => {
  return `/${getDictObj(dict.types, work.projectTypeId).code.toLowerCase()}/${work.projectVersionId}/work/${work.id}`;
};

export const checkGroup = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.GROUP;
};

export const checkMilestone = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.MILESTONE;
};

export const checkTask = (work: Work | GanttTableItem) => {
  return work && work.typeId === WorkType.TASK;
};

export type WorkWithChecked = Work & {
  isChecked: boolean;
};

export const getDuration = ({ dateStart, dateEnd }, weekendUtil) => {
  return isEmptyValues(dateStart) || isEmptyValues(dateEnd)
    ? null
    : weekendUtil.getDuration(parseDate(dateStart), parseDate(dateEnd));
};

export const getCalendarDuration = ({ dateStart, dateEnd }) => {
  return isEmptyValues(dateStart) || isEmptyValues(dateEnd)
    ? null
    : selectDateToMoment(dateEnd).diff(selectDateToMoment(dateStart), 'days') + 1;
};

export const isWorkExpired = (work: Work & {status: DictDataItem} ) => {
  if (work.isExtreme) {
    return false;
  }

  const today = moment().startOf('day');

  if (checkMilestone(work) && work.dateEndFact && work.dateEndFact) {
    return selectDateToMoment(work.dateEndFact).isAfter(selectDateToMoment(work.dateEnd));
  }

  if (checkMilestone(work) && !work.dateEndFact) {
    return today.isAfter(selectDateToMoment(work.dateEnd));
  }

  if (work.status?.code === WorkStatus.PLAN) {
    return today.isAfter(selectDateToMoment(work.dateStart));
  }

  if (work.status?.code === WorkStatus.WORK) {
    return today.isAfter(selectDateToMoment(work.dateEnd));
  }

  return false;
}