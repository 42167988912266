import { get } from 'lodash';
import XLSX from 'xlsx';

export const dashboardExportXlsx = (data, header, {
  name = "Dashboard",
  context = undefined
} = {}) => {
  const linkList = [];
  const headerWithouthide = header.filter(item => item.excel?.isHide !== true)
  const th = headerWithouthide.map(item => item.text);
  const td = data.map((itemData, rowIndex) => headerWithouthide.map((itemHeader, ceilIndex) => {
    const value = get(itemData, itemHeader.dataField);

    if (itemHeader.excel?.linkFormater) {
      linkList.push({
        c: ceilIndex,
        r: rowIndex + 1,
        l: itemHeader.excel.linkFormater(value, itemData, context)
      })
    }

    if (itemHeader.excel?.formatter) {
      return itemHeader.excel.formatter(value, itemData, context)
    }

    return value;
  }));

  const ws = XLSX.utils.aoa_to_sheet([
    th,
    ...td
  ]);

  Object.keys(ws).forEach(key => {
    const item = ws[key]
    if (!item.v) {
      return;
    }

    if (item.t === 's') {
      item.v = item.v.replaceAll('\n', ', ')
    }
  })

  linkList.forEach((item) => {
    if (!ws[XLSX.utils.encode_cell(item)]) {
      return;
    }

    ws[XLSX.utils.encode_cell(item)].l = item.l;
  });

  const wsCols = headerWithouthide.map(item => item.excel?.width ? item.excel : undefined);
  if (!wsCols.every(v => v === undefined)) {
    ws["!cols"] = wsCols
  }

  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, name);

  XLSX.writeFile(wb, `${name}.xlsx`);
}
