import { isEmpty } from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import download from '@/services/download';
import { isSelectDate, selectDateToMoment } from '@/utils';
import { checkError, decLoadCount, incLoadCount } from '@/actions/actionHelper';
import { FELIX_REST, FORMAT_DATE_SERVER } from '@/config/const';
import mockBriefcaseData from './mockBriefcaseData';

interface SettingsParam {
  isShowLoad?: boolean;
  disableCheckStatus?: number[];
  rawResult?: boolean;
  modalOptions?: any;
  ignoreError?: boolean;
}

const request = async (
  type,
  url,
  data: any = undefined,
  settings: SettingsParam = {},
  isMultiPart = false) => {
  const isShowLoad = settings.isShowLoad === true;
  if (isShowLoad) {
    setTimeout(incLoadCount, 500);
  }

  let res;
  if (isMultiPart) {
    const formData = new FormData();
    formData.append('file', data);
    res = await fetch(`${FELIX_REST}${url}`, {
      method: 'POST',
      body: formData
    });
  } else {
    res = await fetch(`${FELIX_REST}${url}`, {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': 'ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7',
      },
      body: JSON.stringify(data),
      ...settings,
    });
  }

  const contentType = res.headers.get('Content-Type');
  // если это json, то не даем получать исходный результат, а то там бывают ошибки и надо их обрабатывать
  const isJson = contentType === 'application/json';

  if (!isJson && settings.rawResult) {
    return res;
  }

  let body = null;
  try {
    body = await res.json();
  } catch (e) {

  }

  if (isShowLoad) {
    decLoadCount();
  }

  if (!res.ok) {
    const status = res.status;
    const isCheck = !settings.disableCheckStatus || !settings.disableCheckStatus.includes(status);

    if (settings.ignoreError) {
      return null;
    }

    if (isCheck) {
      checkError(res, body, settings);
    }

    const error = {
      error: new Error(`Could not fetch ${url}, received ${status}`),
      body: body,
      isCheck: isCheck
    };

    throw error;
  }

  return body;
};


const post = (url, data = undefined, settings: SettingsParam = undefined) => {
  return request('POST', url, data, settings);
};

const postMultipart = (url, data = undefined, settings: SettingsParam = undefined) => {
  return request('POST', url, data, settings, true);
};

const put = (url, data = undefined, settings: SettingsParam = undefined) => {
  return request('PUT', url, data, settings);
};

export const appendQueryParams = (url: string, params: any = undefined) => {
  if (!isEmpty(params)) {
    Object.entries(params).forEach(([key, value]) => {
      if (value instanceof moment) {
        // @ts-ignore ts-migrate(2339) FIXME: Property 'format' does not exist on type '{}'.
        params[key] = value.format(FORMAT_DATE_SERVER);
      }

      if (isSelectDate(value)) {
        params[key] = selectDateToMoment(value as DateServer).format(FORMAT_DATE_SERVER);
      }

    });

    url += '?' + qs.stringify(params);
  }

  return url;
};

const get = (url, params = undefined, settings: SettingsParam = undefined) => {
  return request('GET', appendQueryParams(url, params), undefined, settings);
};

const getLink = (url, params = undefined) => {
  return `${FELIX_REST}/${appendQueryParams(url, params)}`;
};

const remove = (url, data = undefined) => {
  return request('DELETE', url, data);
};

const postDownload = (url, data = undefined) => {
  return service.post(url, data, {
    rawResult: true
  }).then(download);
};

const getDownload = (url, data = undefined) => {
  return service.get(url, data, {
    rawResult: true
  }).then(download);
};

const service = {
  getData: async () => {

    return new Promise((resolve) => {
      setTimeout((_) => resolve(mockBriefcaseData), 2000);
    });
  },

  getUser: () => get('/auth/me'),
  post,
  get,
  getLink,
  remove,
  put,
  postDownload,
  postMultipart,
  getDownload,
};

export default service;
