export const initialState = {
  command: [],
  error: undefined,
};

export const Command = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_COMMAND_PROJECT_SUCCESS':
      return { ...state, command: action.payload };
    default:
      return state;
  }
};
