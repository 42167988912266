import React from 'react';
import { Modal } from 'react-bootstrap';

const RouteModal = ({ children, title, onHide, size = 'md' }) => {
  return (
    <Modal
      show={true}
      // @ts-ignore
      size={size}
      onHide={onHide} 
      centered={false} 
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default RouteModal
