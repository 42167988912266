import React from 'react';
import cx from 'classnames'

const Checkbox = ({ 
  checked, 
  onChange, 
  children = undefined,
  className = undefined,
  title = undefined,
  tooltip = undefined,
  disabled = false,
  description = undefined
}) => {
  const onChangeLocal = (e) => onChange(e, e.target.checked);

  return (
    <React.Fragment>
      <div className={cx("checkbox", className)}>
        <label className={cx("checkbox__label", { 'checked': checked }, { 'disabled': disabled })} title={tooltip}>
          <input
            onChange={onChangeLocal}
            checked={checked}
            className="checkbox__input"
            type="checkbox"
            disabled={disabled}
          />
          {children || title}
          <div className="input-title__description marginLeft-0 no-padding">
            {description}
          </div>
        </label>
      </div>
    </React.Fragment>
  )
}

export default Checkbox;
