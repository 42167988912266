import React from 'react';
import {
  Legend as DXLegend
} from '@devexpress/dx-react-chart-bootstrap4';
import { connectProps } from '@devexpress/dx-react-core';
import Tooltip from '@/elements/Tooltip'

const rootComponent = ({ children }) => (
  <div className="dashboard-chart__container">
    {children}
  </div>
);

const itemComponent = ({ children }) => (
  <div className="dashboard-chart__legend-item">
    {children}
  </div>
);

const labelWithFindComponent = (props) => {
  const desc = props.series?.find(item => props.text === item.name)?.desc

  return labelComponent({
    text: props.text,
    desc
  });
}

const labelComponent = ({ text, desc }) => {

  return (
    <span>{text}<Tooltip text={desc} placement="top" maxWidth="200px" /></span>
  );
}

const markerComponent = ({ color }) => (
  <div className="dashboard-chart__legend-marker" style={{ background: color }}></div>
);

const Legend = (props) => {
  const labelComponentLocal = connectProps(props.labelComponent || labelWithFindComponent, () => props)

  return (
    <DXLegend
      position={props.position || "bottom"}
      rootComponent={rootComponent}
      itemComponent={itemComponent}
      labelComponent={labelComponentLocal}
      // @ts-ignore ts-migrate(2322) FIXME: Type '({ color }: { color: any; }) => Element' is ... Remove this comment to see the full error message
      markerComponent={markerComponent}
    />
  )
}

export const LegendBlock = ({ values = [] }) =>
  rootComponent({
    children: values.map(({ text, desc, color }) => (
      <React.Fragment key={text}>
        {itemComponent({
          children: (
            <>
              {markerComponent({ color })}
              {labelComponent({ text, desc })}
            </>
          )
        })}
      </React.Fragment>
    ))
  });

export default Legend;
